import React, { useEffect, useState } from "react";
import { Button, Spin, notification, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SectionHeader from "../../components/global/SectionHeader";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

export default function ProjectAssociation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectData, setProjectData] = useState({});
  const [backendMembers, setBackendMembers] = useState([]);
  const [frontendMembers, setFrontendMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderSubmitBack = () => {
    navigate("/allProject");
  };

  const fetchData = () => {
    setLoading(true);
    ApiClient.get(`project?id=${id}`)
      .then((res) => {
        if (res && res.data) {  
          setProjectData(res.data);
          setBackendMembers(res.data.backend_employees || []);
          setFrontendMembers(res.data.frontend_employees || []);
        } else {
          notification.error({ message: "No project team data found" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        notification.error({ message: "Failed to fetch project data" });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="">
        <div className="main-content">
          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p
                  onClick={renderSubmitBack}
                  className="pointer text-white  fs-3"
                >
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Project Detail" />
            </div>
          </div>

          <section className="section">
            <Spin spinning={loading}>
              <div className="shadow p-4">
                <h5 className="text-capitalize">
                  Project Name :- <b>{projectData.name}</b>
                </h5>

                <div className="mt-4">
                  <h5>Project Manager:-</h5>
                  {projectData.project_manager &&
                    projectData.project_manager.fullName}
                </div>
                <div className="mt-4">
                  <h5>BDE Members:-</h5>
                  {projectData.BDE && projectData.BDE.fullName}
                </div>
                <div className="mt-4">
                  <h5>Frontend Developers:-</h5>
                  {frontendMembers.map((member, index) => (
                    <div className="mt-1 d-inline-flex mx-3" key={index}>
                      <h6>
                        {index + 1}. {member.fullName} ({member.designation})
                      </h6>
                    </div>
                  ))}
                </div>

                <div className="mt-4">
                  <h5>Backend Developers:-</h5>
                  {backendMembers.map((member, index) => (
                    <div className="mt-1 d-inline-flex mx-3" key={index}>
                      <h6>
                        {index + 1}. {member.fullName} ({member.designation})
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
            </Spin>
          </section>
        </div>
      </div>
    </Layout>
  );
}
