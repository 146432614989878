import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import { Provider } from "react-redux";
import App from './App';
import Store from './redux/Store/store';
import { ConfigProvider } from 'antd';

ReactDOM.render(<Provider store={Store} >
 <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#ff8f27',
        borderRadius: 5,

        // Alias Token
        colorBgContainer: '#fff',
      },
    }}
  >
<App/>
  </ConfigProvider>
  
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
