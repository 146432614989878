import { Button, notification, Spin, Tooltip } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import methodModel from "../../../methods/methods";
import "./ChangePassword.css";
import Layout from "../../../components/global/layout";
import { FiEye } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa6";

const ChangePassword = () => {
  const history = useNavigate();
  const [form, setForm] = useState({
    confirmPassword: "",
    currentPassword: "",
    newPassword: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setloading] = useState(false);
  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "currentPassword", minLength: 8 },
    { key: "newPassword", minLength: 8 },
  ];
  const [eyes, setEyes] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };
  const backbutton = () => {
    history(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    setloading(true);
    ApiClient.post("changePassword", form).then((res) => {
      if (res.success) {
        setSubmitted(false);
        ToastsStore.success(res.message);
        localStorage.clear();
        notification.success({
          message: "Password Changed Successfully",
        });
        history("/");
      }
      if (res.error) {
        setloading(false);
        notification.error({
          message: "Current Password is Wrong",
        });
      }
    });
  };

  return (
    <div>
      <Layout>
        <div className="row ">
          <div className="col-12">
            <div className="profile_section">
              <div>
                <h4 className="main-heading"></h4>
              </div>
            </div>

            <Spin spinning={loading}>
              <form className="py-3 " onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-5 mx-auto">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Change Password</h4>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="cstmlabel">
                            Current Password<span className="star">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type={eyes.currentPassword ? "text" : "password"}
                              className="form-control"
                              value={form.currentPassword}
                              maxLength="20"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  currentPassword: e.target.value,
                                })
                              }
                              required
                            />
                            <span
                              className="password_eye"
                              onClick={() =>
                                setEyes({
                                  ...eyes,
                                  currentPassword: !eyes.currentPassword,
                                })
                              }
                            >
                              {eyes.currentPassword ? (
                                <FiEye />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </span>
                          </div>
                          {submitted && getError("currentPassword").invalid ? (
                            <div className="invalid-feedback d-block">
                              Min Length must be 8 characters long
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="cstmlabel">
                            New Password<span className="star">*</span>
                          </label>

                          <div className="position-relative">
                            <input
                              type={eyes.newPassword ? "text" : "password"}
                              className="form-control "
                              value={form.newPassword}
                              maxLength="20"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  newPassword: e.target.value,
                                })
                              }
                              required
                            />
                            <span
                              className="password_eye"
                              onClick={() =>
                                setEyes({
                                  ...eyes,
                                  newPassword: !eyes.newPassword,
                                })
                              }
                            >
                              {eyes.newPassword ? <FiEye /> : <FaRegEyeSlash />}
                            </span>
                          </div>
                          {submitted && getError("newPassword").invalid ? (
                            <div className="invalid-feedback d-block">
                              Min Length must be 8 characters long
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="cstmlabel">
                            Confirm Password<span className="star">*</span>
                          </label>

                          <div className="position-relative">
                            <input
                              type={eyes.confirmPassword ? "text" : "password"}
                              className="form-control "
                              value={form.confirmPassword}
                              maxLength="20"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  confirmPassword: e.target.value,
                                })
                              }
                              required
                            />
                            <span
                              className="password_eye"
                              onClick={() =>
                                setEyes({
                                  ...eyes,
                                  confirmPassword: !eyes.confirmPassword,
                                })
                              }
                            >
                              {eyes.confirmPassword ? (
                                <FiEye />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </span>
                          </div>
                          {submitted && getError("confirmPassword").invalid ? (
                            <>
                              {getError("confirmPassword").err.confirmMatch ? (
                                <div className="invalid-feedback d-block">
                                  Comfirm Password is not matched with New
                                  Password
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className=" text-end">
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Spin>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ChangePassword;
