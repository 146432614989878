import React, { useState, useEffect, forwardRef } from "react";
import { Spin, Button, Modal, Popconfirm, Tooltip } from "antd";
import { useDispatch } from "react-redux"; 
import SectionHeader from "../../components/global/SectionHeader";
import { PlusSquareOutlined, EditOutlined, EyeFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../environment";
import {
  DELETE_PARTICULARPOST,
  KNOWLEDGESHARINGPOST_PARTICULARPERSON,
} from "../../redux/Store/constants";
import EditKnowledgepost from "../../components/knowledgesharing/EditKnowledgepost";
import "../leaves/Leaveform.css";
import { notification } from "antd";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSidebar } from "../../components/provider/Provider";
import Layout from "../../components/global/layout";
import { LuEye } from "react-icons/lu";
const Knowledgesharingform = (props) => {
  const userid = localStorage.getItem("USERID");
  const token = localStorage.getItem("token");
  let history = useNavigate();
  const [array, setArray] = useState([]);
  const [id, setId] = useState();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editChapterData, setEditChapterData] = useState({});
  const [loading, setLoading] = useState(false);
  const { setCourseId } = useSidebar();
  const test = localStorage.getItem("USERID");
  const dispatch = useDispatch();
  const iconbtn = () => {
    navigate("/knowledgesharingform");
  };
  const navigate = useNavigate();
  useEffect(() => {
    Knowledgesharingtable();
  }, []);

  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("knowledgeid", id);
    history(`/knowledgeDetails?id=${id}`);
  };
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + "...";
    } else {
      return description;
    }
  };
  const Knowledgesharingtable = () => {
    setLoading(true);
    fetch(`${API_URL}posts?id=${userid}&count=1000&sortBy=asc `, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: KNOWLEDGESHARINGPOST_PARTICULARPERSON,
            payload: res,
          });
        }
        if (res.code === 401) {
          localStorage.clear();
          setTimeout(() => {
            notification.error({
              message: "Your Session Has Expired. Please logIn.",
            });
          }, 1000);
        }
        setLoading(false);
      });
  };
  const columns = [
    {
      title: "Tittle",
      dataIndex: "name",
      field: "name",
      render: (text, record) => (
        <>
          {text && text.name ? (
            <Tooltip title="View">
              <span
                className="text text-capitalize"
                onClick={() => chapterIdHandle(text.id)}
              >
                {text.name}
              </span>
            </Tooltip>
          ) : (
            "--"
          )}
        </>
      ),
    },

    {
      title: "Technology",
      dataIndex: "description",
      field: "description",
      render: (text, record) => (
        <>
          {text && text.description ? (
            // <Link to={`/KnowledgeDetails?id=${text.id}`}>
            <span
              className="description_field"
              dangerouslySetInnerHTML={{
                __html: truncateDescription(text.description),
              }}
            ></span>
          ) : (
            //  </Link>
            "--"
          )}
        </>
      ),
    },
    {
      title: "Posted By",
      dataIndex: "name",
      field: "name",
      render: (text, record) => (
        <>
          {text && text.name ? (
            // <Tooltip title="View">
            <span
              className="text text-capitalize"
              onClick={() => chapterIdHandle(text.id)}
            >
              {text.addedBy.map((itm) => {
                return itm?.fullName;
              })}
            </span>
          ) : (
            //</Tooltip>
            "--"
          )}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      field: "id",
      render: (text, record) => (
        <>

<div className="d-flex align-items-center gap-3">
          <div className="">

          <Tooltip title="View">
              <p   onClick={() => showEditModalHandle(text)}  onClick={() => chapterIdHandle(text.id)} className="">
              <LuEye />
              </p>
              
            </Tooltip>
           

            </div>    
          {text && text.postByUser == test ? (
            <div className="">
            <Tooltip title="Edit">
              <p  onClick={() => showEditModalHandle(text)}  >
              <EditOutlined />
              </p>
            </Tooltip>
           

              <Popconfirm
                title="Are you sure to delete this post ?"
                onConfirm={() => Deletepost(text.id)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              > 
              </Popconfirm>
            </div>
          ) : (
            ""
          )} 
          </div>    
      
        </>
        
      ),
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  const Deletepost = (id) => {
    const payload = {
      id: id,
    };
    fetch(`${API_URL}post/delete`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        notification.success({
          message: "Post has been Deleted",
        });
        Knowledgesharingtable();

        if (res) {
          dispatch({
            type: DELETE_PARTICULARPOST,
            payload: res,
          });
        } else {
          alert("Same name");
        }
      });
  };
  const handleCancel = () => {
    setIsEditModalVisible(false);
  };

  const showEditModalHandle = (data) => {
    setIsEditModalVisible(true);
    setEditChapterData(data);
    setId(data._id);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
    <div>


    <div className="d-flex align-items-center justify-content-between headings" >
            <SectionHeader title="Knowledge Sharing" />
              <Tooltip title="Add Knowledge">
                <p onClick={iconbtn} className="pointer text-white  fs-3" >
                <PlusSquareOutlined />
              </p>
              </Tooltip>
            </div>
     
      <div className="main-content">
        <div className="section">
        

          <Spin spinning={loading}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
              }}
              columns={columns}
              data={newlyAddedChapter}
              icons={tableIcons}
            />
          </Spin>
        </div>
      </div>

      <Modal
        footer={null}
        title="Edit Knowledge Sharing Post"
        visible={isEditModalVisible}
        onCancel={handleCancel}
      >
        <EditKnowledgepost
          chapterId={id}
          setIsEditModalVisible={setIsEditModalVisible}
          showData={Knowledgesharingtable}
          editChapterData={editChapterData}
        />
      </Modal>
    </div>
    </Layout>
  );
};

export default Knowledgesharingform;
