import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SectionHeader from "../../components/global/SectionHeader";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import { getActivityLogsdetails } from "../../api/user/userapi";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const Projectactivitylogs = (props) => {
  const [loading, setloading] = useState(false);
  const [changes, setChanges] = useState([]);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const naviagte = useNavigate();

  const handleteam = () => {
    naviagte(`/projectassociation/list/${id}`);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getProject();
  }, []);

  const [enddate, setenddate] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const getProject = async (i) => {
    setloading(true);
    try {
      const resp = await getActivityLogsdetails(id);
      setloading(false);
      if (resp && resp.status === 200) {
        const list = resp.data.data;
        const changes = [];

        list.forEach((item) => {
          const previousState = item.previousState;
          const currentState = item.currentState;
          const updatedKeys = Object.keys(currentState).filter((key) => {
            const currentValue = currentState[key];
            const previousValue = previousState[key];
            return (
              currentValue != previousValue &&
              currentValue != null &&
              previousValue != null &&
              currentValue != "" &&
              previousValue != ""
            );
          });

          updatedKeys.forEach((key) => {
            const currentValue = currentState[key];
            const previousValue = previousState[key];

            if (Array.isArray(currentValue) && Array.isArray(previousValue)) {
              const addedItems = currentValue.filter(
                (item) => !previousValue.includes(item)
              );
              const removedItems = previousValue.filter(
                (item) => !currentValue.includes(item)
              );
              if (addedItems.length > 0 || removedItems.length > 0) {
                changes.push({
                  key,
                  previousValue: previousValue,
                  currentValue: currentValue,
                  addedItems,
                  removedItems,
                });
              }
            } else if (currentValue !== previousValue) {
              changes.push({
                key,
                previousValue,
                currentValue,
              });
            }
          });
        });

        setChanges(changes);
      } else {
        setloading(false);
      }
    } catch (e) {
      console.log(e, "This is the error data +++");
      setloading(false);
    }
  };
  const renderSubmitBack = () => {
    navigate("/allProject");
  };
  return (
    <Layout>
      <div className="text-left">
        <div className="headings">
          <div className="d-flex align-items-center gap-3">
            <Tooltip title="Back Button">
              <p
                onClick={renderSubmitBack}
                className="pointer text-white  fs-3"
              >
                <BsArrowLeftSquare />
              </p>
            </Tooltip>
            <SectionHeader title="Project Logs  Detail" />
          </div>
        </div>

        <div className="main-content">
          <section className="section ">
            <Spin spinning={loading}>
            <div className="row">
              <div className="col-12 col-md-6">
                <h4>Previous:</h4>
                {changes.map((change, index) => {
                  return (
                    <div key={index} className="mt-3">
                      <label className="text-semibold">{change.key}:</label>
                      <p className="lbreak">
                        {Array.isArray(change.previousValue)
                          ? change.previousValue.join(", ")
                          : typeof change.previousValue == "object"
                          ? JSON.stringify(change.previousValue)
                          : change.previousValue
                          ? change.previousValue
                          : "---"}
                      </p>
                      <hr />
                    </div>
                  );
                })}
              </div>
              <div className="col-12 col-md-6">
                <h4>Current:</h4>
                {changes.map((change, index) => (
                  <div key={index} className="mt-3">
                    <label className="text-semibold">{change.key}:</label>
                    <p className="lbreak">
                      {Array.isArray(change.currentValue)
                        ? change.currentValue.join(", ")
                        : typeof change.currentValue == "object"
                        ? JSON.stringify(change.currentValue)
                        : change.currentValue
                        ? change.currentValue
                        : "---"}
                    </p>
                    <hr></hr>
                  </div>
                ))}
              </div>
              </div>
            </Spin>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Projectactivitylogs;
