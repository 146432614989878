import React from 'react';

const SectionHeader = ({ title }) => {
 
  return (
    <div className="">
      <h1 className='heading-main'>{title}</h1>
    </div>
  );
};

export default SectionHeader;