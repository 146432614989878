import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField } from "../../Textfield";
import { API_URL } from "../../../environment";
import { FORGET_PASSWORD } from "../../../redux/Store/constants";
import { Spin, notification } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import "../forgot/forgot.css";

const ForgetPassword = (p) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState();
  const [loading, setloading] = useState(false);
  const Forgetpassword = (e) => {
    e?.preventDefault();
    const data = {
      email,
    };
    setloading(true);
    fetch(`${API_URL}forgotpassword`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setloading(false);
          navigate("/resetpassword");  
          notification.success({
            message: "Verification Code Has Been Sent On Email.",
          });
          dispatch({
            type: FORGET_PASSWORD,
            payload: res,
          });
        } else {
          setloading(false);
          notification.error({
            message: "No Such User Exist",
          });
        }
      });
  };
  const Previous = () => {
    navigate("/");
  };
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required(""),
  });
  return (
    <>
      <div className="wrapperauth">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 mx-auto ">
              <div className="mb-5">
                <img src="/img/logo1.png" className="logolog" alt="logo" />
              </div>
              <div className="forms_data">
                <form
                  onSubmit={(e) => {
                    Forgetpassword(e);
                  }}
                  className="w-100"
                >
                  <div className="forms_logins mb-4">
                    <h1 className="text-xl m-0">Forgot password</h1>
                    {/* <p className="fs-12">Reset your password to continue accessing your dashboard.</p> */}
                  </div>

                  <div className="main_inners">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                        className="form-control"
                      />
                    </div>

                    <div className=" submit_btncls mt-3">
                      <button
                        className="btn btn-primary w-100 shadow-btn h-45"
                        type="submit"
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <>
                          Submit
                          <div
                            className="loader"
                            style={{ display: "none" }}
                          ></div>
                          </>
                        )}
                      </button>
                    </div>

                    <div className="text-center mt-2">
                      <p className="text-black m-0 pointer" onClick={Previous}>
                        Back to Sign in
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    // <Formik
    //   initialValues={{
    //     email: "",
    //   }}
    //   validationSchema={validate}
    //   onSubmit={() => {}}
    // >
    //   <form
    //     onSubmit={Forgetpassword}
    //     action="javascript:simpleCart.checkout()"
    //     className="logincss-100"
    //   >
    //     <div className="container">
    //       <div className="forget_page">
    //         <div className="row">
    //           <div className="col-md-12 mx-auto">
    //             <div className="row mx-auto w-100">
    //               <div className="col-md-6 p-0">
    //                 <div className="jc_logins">
    //                   <div className="sign_in">
    //                     <div className="logo_css">
    //                       <img
    //                         src="/img/Forgot password-bro.png"
    //                         className="login_img"
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-md-6 p-0">
    //                 <div className="forgot_jc shadow ">
    //                   <Spin spinning={loading}>
    //                     <fieldset>
    //                       <img
    //                         src="/img/logo1.png"
    //                         className="logolog"
    //                         alt="logo"
    //                       />
    //                       <h3 class="login_heading">Forgot Password</h3>
    //                       <TextField
    //                         required
    //                         value={email}
    //                         label="Email"
    //                         onChange={(e) => setEmail(e.target.value)}
    //                         name="email"
    //                         type="email"
    //                       />
    //                       <div className="forgot_pass">
    //                         <button
    //                           className="btn logincss_class mt-2"
    //                           onClick={Previous}
    //                           type="button"
    //                         >
    //                           Login
    //                         </button>
    //                         <button
    //                           className="btn submitcss mt-2"
    //                           type="submit"
    //                         >
    //                           Submit
    //                         </button>
    //                       </div>
    //                     </fieldset>
    //                   </Spin>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </form>
    // </Formik>
  );
};

export default ForgetPassword;
