import { Popconfirm, Tooltip } from "antd"; 
import MaterialTable from "material-table";
import { forwardRef, useEffect, useState } from "react";
import SectionHeader from "../../components/global/SectionHeader";
import { API_URL } from "../../environment";
import {
  DeleteOutlined,
  EditOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Spin, notification } from "antd";
import ApiClient from "../../methods/api/apiClient";
import Layout from "../../components/global/layout";
import {
  getUsers,
  tasklist,
  deletetask,
  updatatask,
  gettaskdetail,
} from "../../api/user/userapi";

const Projecttask = () => {
  const [taskName, settaskName] = useState();
  const [assignDateAndTime, setassignDateAndTime] = useState();
  const [submitDateAndTime, setsubmitDateAndTime] = useState();
  const [employee, setemployee] = useState();
  const [description, setdescription] = useState();
  const [loading, setLoading] = useState(false);
  const [data, SetData] = useState([]);
  const [arrayTeam, setArrayTeam] = useState();
  const [memberarray, setmemberarray] = useState();
  const [Id, setId] = useState();
  const history = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  var today = new Date().toISOString().slice(0, 16);

  useEffect(() => {
    gettasklist();
    userlist();
  }, []);
  const userlist = () => {
    setLoading(true);
    ApiClient.get(`project?id=${id}`).then((res) => {
      if (res.success) {
        setArrayTeam(res.data.members);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const gettasklist = () => {
    setLoading(true);
    tasklist(`all/task?projectId=${id}`).then((res) => {
      if (res.success) {
        SetData(res.data);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  // Add task
  const addtask = () => {
    settaskName("");
    setassignDateAndTime("");
    setsubmitDateAndTime("");
    setemployee("");
    setmemberarray("");
    setdescription("");
    setId("");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    let url = ``;
    let token = localStorage.getItem("dataToken");
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let userid = localStorage.getItem("USERID");
    let payload = {
      taskName: taskName,
      assignDateAndTime: assignDateAndTime,
      submitDateAndTime: submitDateAndTime,
      project: id,
      employee: memberarray,
      addedBy: userid,
      description: description,
    };
    let method = "";
    if (Id) {
      url = API_URL + `update/task?id=${Id}`;
      method = "put";
    } else {
      url = API_URL + `task`;
      method = "post";
    }
    updatatask(url, payload, method).then((res) => {
      if (res.success) {
        gettasklist();
        // alert(hello9999)
        // document.getElementById("edittask").onclick()
        alert(hello12);
        {
          Id
            ? notification.success({
                message: "Task Updated Successfully.",
              })
            : notification.success({
                message: "Task Added Successfully.",
              });
        }
      }
    });
    document.getElementById("closemodel").click();
  };

  useEffect(() => {
    userDetails();
  }, []);
  const userDetails = async () => {
    try {
      setLoading(true);
      const resp = await getUsers();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newarraydata = arrayData.filter((item) => {
          return item.role != "HR" && item.role != "subadmin";
        });
      }
    } catch (e) {
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
    setLoading(false);
  };

  // Task edit
  const taskedit = (data) => {
    setLoading(true);
    let url = `task?id=${data && data._id}`;
    let userid = localStorage.getItem("USERID");
    gettaskdetail(url).then((res) => {
      if (res.success) {
        settaskName(res.data.taskName);
        setassignDateAndTime(res.data.assignDateAndTime);
        setsubmitDateAndTime(res.data.submitDateAndTime);
        setdescription(res.data.description);
        setId(res.data.id);
        setemployee(res.data.employee.id);
        setmemberarray(res.data?.employee.id);
        setLoading(false);
        gettasklist();
      }
      setLoading(false);
    });
  };

  // Task Delete
  const taskdelete = (id) => {
    setLoading(true);
    let url = `remove/task?id=${id}`;
    deletetask(url).then((res) => {
      if (res.success) {
        gettasklist();
        notification.success({
          message: "Task Deleted Successfully.",
        });
        setLoading(false);
      }
      setLoading(false);
    });
  };

  // Date & Time format
  const monthArray = [
    { value: "Jan", id: 0 },
    { value: "Feb", id: 1 },
    { value: "Mar", id: 2 },
    { value: "Apr", id: 3 },
    { value: "May", id: 4 },
    { value: "Jun", id: 5 },
    { value: "Jul", id: 6 },
    { value: "Aug", id: 7 },
    { value: "Sep", id: 8 },
    { value: "Oct", id: 9 },
    { value: "Nov", id: 10 },
    { value: "Dec", id: 11 },
  ];
  const monthfind = (id) => {
    let value = 0;
    let ext = monthArray.find((itm) => itm.id === id);
    if (ext) value = ext.value;
    return value;
  };
  const date = (p) => {
    let d = new Date(p);
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`;
  };
  const time = (p) => {
    if (!p) return "";
    let v = p.split("T");
    if (v.length === 2) {
      return new Date(p).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else {
      return new Date(`2020-07-19 ${p}`).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() - 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "taskName",
      field: "taskName",
      render: (data) => <>{data && data?.taskName}</>,
    },
    {
      title: "Assign Date & Time",
      dataIndex: "assignDateAndTime",
      field: "assignDateAndTime",
      render: (data) => (
        <>
          {date(data && data.assignDateAndTime)},{" "}
          {time(data && data.assignDateAndTime)}
        </>
      ),
    },
    {
      title: "Submit Date & Time",
      dataIndex: "submitDateAndTime",
      field: "submitDateAndTime",
      render: (data) => (
        <>
          {date(data && data.submitDateAndTime)},{" "}
          {time(data && data.submitDateAndTime)}
        </>
      ),
    },
    {
      title: "Project",
      dataIndex: "project",
      field: "project",
      render: (data) => <>{data && data?.project?.name}</>,
    },
    // {
    //     title: "Employee",
    //     dataIndex: "employee",
    //     field: "employee",
    //     render:data => (
    //         <>
    //             {data&&data.employee}
    //         </>
    //     )
    // },
    {
      title: "Description",
      dataIndex: "description",
      field: "employee",
      render: (data) => (
        <p dangerouslySetInnerHTML={{ __html: data && data?.description }}></p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      field: "action",
      render: (data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {" "}
          <span
            className="edit_icon"
            style={{
              verticalAlign: "text-bottom",
            }}
          >
            <Tooltip title="Edit">
              <Button
                type="primary"
                className=""
                onClick={(e) => taskedit(data)}
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                {<EditOutlined />}
              </Button>
            </Tooltip>
          </span>
          <span>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure you want to Delete?"
                onConfirm={() => taskdelete(data && data._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" className="button">
                  <DeleteOutlined
                  // onClick={() => removeChapterHandle(record._id)}
                  // onCancel={cancelDeleteHandle}
                  />
                </Button>
              </Popconfirm>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  // const newlyAddedChapter =
  //     array && array.length
  //         ? array.map((items) => {

  //             return {
  //                 ...items,
  //             };
  //         })
  // : [];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const backbutton = () => {
    history("/allProject");
  };

  return (
    <Layout>
      <div>
        <div className="main-wrapper" id="mainPannel">
          <div className="navbar-bg" />
        
        </div>
        <Spin spinning={loading}>
          <div className="main-content">
            <section className="section">
              <SectionHeader title="Task" />
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={data}
                icons={tableIcons}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="Back Button">
                  <Button
                    type="primary"
                    onClick={(e) => backbutton()}
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    size={"large"}
                    className="btn btn-primary profile mr-5"
                  />
                </Tooltip>
                <Tooltip title="Add Task">
                  <Button
                    type="primary"
                    onClick={(e) => addtask()}
                    shape="circle"
                    icon={<PlusSquareOutlined />}
                    size={"large"}
                    className="btn btn-primary profile"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </Tooltip>
              </div>
            </section>
            <div
              className="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    {!Id ? (
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Add Task
                      </h5>
                    ) : (
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Edit Task
                      </h5>
                    )}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form onSubmit={handlesubmit}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            text="text"
                            className="p-2 w-100"
                            placeholder="Enter task"
                            value={taskName}
                            onChange={(e) => settaskName(e.target.value)}
                            required
                          ></input>
                        </div>
                        <div className="col-md-12">
                          <input
                            type="datetime-local"
                            min={today}
                            id="assignDateAndTime"
                            name="assignDateAndTime"
                            className="mt-3 w-50"
                            value={assignDateAndTime}
                            onChange={(e) =>
                              setassignDateAndTime(e.target.value)
                            }
                            required
                          ></input>
                          <input
                            type="datetime-local"
                            min={assignDateAndTime}
                            id="submitDateAndTime"
                            name="submitDateAndTime"
                            className="mt-3 w-50"
                            value={submitDateAndTime}
                            onChange={(e) =>
                              setsubmitDateAndTime(e.target.value)
                            }
                            required
                          ></input>
                        </div>
                        <div className="col-md-12">
                          <select
                            placeholder="Select Employee"
                            value={memberarray}
                            onChange={(e) => setmemberarray(e.target?.value)}
                            className="w-100 mt-3 border border-dark"
                            required
                          >
                            <option value="">Select Employee</option>
                            {arrayTeam &&
                              arrayTeam.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.fullName}
                                  {`(${item.designation})`}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-12">
                          <textarea
                            rows={5}
                            type="text"
                            name="description"
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            className="p-2 w-100 mt-3"
                            placeholder="Stories"
                            required
                          ></textarea>
                          {/* <input type="text" name='description' value={description} onChange={e=>setdescription(e.target.value)} className='p-2 w-100 mt-3' placeholder='Description' required></input> */}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        id="closemodel"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Layout>
  );
};
export default Projecttask;
