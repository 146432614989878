import React, { useEffect, useState } from "react";
import { Button, Image, Spin, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader";
import { getComments } from "../../api/api";
import methodModel from "../../methods/methods";
import { getPostDetails } from "../../api/api";
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const KnowledgeDetails = (props) => {
  const id = methodModel.getPrams("id");
  const knowledge_share_id = id;
  const [names, setName] = useState();
  const [desc, setDescription] = useState();
  const [imgs, setImage] = useState();
  const [comments, setComments] = useState([]);
  const [loading, setloading] = useState(false);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    getProject();
    getCommentsData();
  }, []);

  const getProject = async (i) => {
    setloading(true);
    const Id = localStorage.getItem("knowledgeid");
    try {
      const resp = await getPostDetails(Id);
      if (resp && resp.status === 200) {
        const list = resp;

        const name = list.data.data.name;
        const description = list.data.data.description;
        const img = list.data.data.image;
        setName(name);
        setDescription(description);
        setImage(img);
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const getCommentsData = async (i) => {
    setloading(true);

    try {
      const resp = await getComments(knowledge_share_id);
      if (resp && resp.status === 200) {
        const datalist = resp?.data?.data;
        setComments(datalist);
      } else {
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const navigate = useNavigate();
  const renderSubmitBack = () => {
    navigate("/knowledgesharinglist");
  };
  return (
    <Layout>
    <div className="text-left">

    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Knowledge Detail" />
            </div>
          </div>
     
      <div className="main-content">

        
        <section className="section">
        
          <Spin spinning={loading}>
            <div className="">
              <div className="">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Topic-Name:</label>
                      </div>
                      <div className="label_text">
                        <span> {names}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Description:</label>
                      </div>
                      <div className="label_text">
                        <span class="prettify">
                          {" "}
                          {htmlToReactParser.parse(desc)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col docs my-2">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Image:</label>
                      </div>
                      <div className="upload_images mb-5">
                        {imgs == "" || imgs == null ? null : (
                          <span className="imagcl">
                            {" "}
                            {<Image width={200} className="h-auto" src={imgs} />}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-12 mt-2">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Comments:</label>
                      </div>
                      <div className="label_text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: comments
                              ?.map((e) => {
                                return e?.comment;
                              })
                              .join("<br>"),
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
    </Layout>
  );
};

export default KnowledgeDetails;
