import React, { useEffect, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import SectionHeader from "../../components/global/SectionHeader";
import { Spin, Tooltip, notification } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import EditProject from "./EditProject";
import "./project.css";
import { useSidebar } from "../../components/provider/Provider";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Layout from "../../components/global/layout";
// import { useNavigate } from "react-router-dom/cjs/react-router-dom.min";
import { BrowserRouter, Route, useNavigate } from "react-router-dom";
import {
  getProjects,
  updateProjectStatus,
  updateStatus,
} from "../../api/user/userapi";
import { RxActivityLog } from "react-icons/rx";
import { LuEye } from "react-icons/lu";

const AllProjects = (props) => {
  const [array, setArray] = useState([]);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editChapterData, setEditChapterData] = useState({});
  const { setCourseId } = useSidebar();
  const [filterValue, setFilterValue] = useState("");
  // const [sortOption, setSortOption] = useState("atoz");

  const handleFilter = (value) => {
    setFilterValue(value);
    Details(value);
  };

  useEffect(() => {
    Details();
  }, []);
  const Details = async (status) => {
    try {
      setLoading(true);
      const resp = await getProjects(status);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list?.data?.data;
        setArray(arrayData);
      }
    } catch (e) {
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
    setLoading(false);
  };

  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("courseid", id);
  };

  const showEditModalHandle = (data) => {
    history(`/createProject/${data._id}`);
  };

  const handleCancel = (e) => {
    setIsEditModalVisible(false);
  };

  const monthArray = [
    { value: "Jan", id: 0 },
    { value: "Feb", id: 1 },
    { value: "Mar", id: 2 },
    { value: "Apr", id: 3 },
    { value: "May", id: 4 },
    { value: "Jun", id: 5 },
    { value: "Jul", id: 6 },
    { value: "Aug", id: 7 },
    { value: "Sep", id: 8 },
    { value: "Oct", id: 9 },
    { value: "Nov", id: 10 },
    { value: "Dec", id: 11 },
  ];
  const monthfind = (id) => {
    let value = 0;
    let ext = monthArray.find((itm) => itm.id === id);
    if (ext) value = ext.value;
    return value;
  };
  const hanldeview = (text) => {
    history(`/projectDetails?id=${text._id}`);
  };
  const showActivityModalHandle = (text) => {
    history(`/activityLog?id=${text._id}`);
  };
  const date = (p) => {
    let d = new Date(p);
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`;
  };
  async function handleChange(value, event) {
    const payload = {
      model: "projects",
      projectStatus: event,
    };
    try {
      const resp = await updateProjectStatus(value, event, payload);
      if (resp && resp.status === 200) {
        notification.success({ message: `Project Status is Updated` });
        Details();
      } else {
        notification.error({ message: "Some error Occurred" });
      }
    } catch (e) {
      notification.error({ message: "Some error Occurred" });
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      field: "name",
      render: (text, record) => (
        <>
          {text && text.name ? (
            <Link to={`/projectDetails?id=${text._id}`}>
              <Tooltip title="View">
                <span
                  className="text text-capitalize text-warning"
                  style={{ cursor: "pointer" }}
                  onClick={() => chapterIdHandle(text._id)}
                >
                  {parse(text.name)}
                </span>
              </Tooltip>
            </Link>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "Start-Date",
      dataIndex: "startDate",
      field: "startDate",
      render: (text, record) => (
        <>{text && text.startDate ? date(text && text.startDate) : "--"}</>
      ),
    },
    {
      title: "End-Date",
      dataIndex: "endDate",
      field: "endDate",
      render: (text, record) => (
        <>{text && text.endDate ? date(text && text.endDate) : "--"}</>
      ),
    },
    {
      title: "Client-Name",
      dataIndex: "clientName",
      field: "clientName",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      field: "actions",
      render: (text, record) => (
        <div className="d-flex align-items-center  gap-4">
          
        
          <Tooltip title="Activity Logs">
              <p  onClick={() => showActivityModalHandle(text)}
                className=""
              >
                <RxActivityLog />
              </p>
            </Tooltip>
       
            <Tooltip title="View">
              <p  onClick={() => hanldeview(text)} className="">
              <LuEye />
              </p>
              
            </Tooltip>

            <Tooltip title="Edit">
              <p onClick={() => showEditModalHandle(text)}  >
              <EditOutlined />
              </p>
            </Tooltip>
          
       
           
         
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "projectStatus",
      field: "projectStatus",
      render: (text, record) => (
        <>
          {text && text.projectStatus ? (
            <>
              <select
                className="form-control table-borderless p-2"
                onChange={(e) => handleChange(text.id, e.target.value)}
                value={text.projectStatus}
              >
                <option value="onhold">On Hold</option>
                <option value="rejected">Rejected</option>
                <option value="completed">Completed</option>
                <option value="inProgress">InProgress</option>
                <option value="notStarted">Not Started</option>
                {/* <option value="goingon">On Going</option> */}
              </select>
            </>
          ) : (
            "--"
          )}
        </>
      ),
    },
  ];

  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          localStorage.setItem("projectId", items.id);
          return {
            ...items,
          };
        })
      : [];

  const renderSubmit = () => {
    history("/createProject");
  };
  // const handleSortChange = (value) => {
  //   setSortOption(value);
  //   const sortedData = sortProjects(array, value);
  //   // Update the table data with the sorted array
  //   setArray(sortedData);
  // };
  // const sortProjects = (data, option) => {
  //   switch (option) {
  //     case 'atoz':
  //       return data.sort((a, b) => a.name.localeCompare(b.name));
  //     case 'ztoa':
  //       return data.sort((a, b) => b.name.localeCompare(a.name));
  //     case 'date':
  //       return data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  //     default:
  //       return data;
  //   }
  // };
  const token = localStorage.getItem("dataToken");
  async function DownloadFileFromS3(fileToDownload) {
    const result = await Storage.get(fileToDownload.key, { download: true });
    let mimeType = result.ContentType;
    let fileName = fileToDownload.key;
    let blob = new Blob([result.Body], { type: mimeType });
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // Task button
  const tasklist = () => {
    history("/projecttask");
  };
  return (
    <Layout>
      <div>
        <div className="main-content">
          <div className="d-flex align-items-center justify-content-between headings">
            <SectionHeader title="All Projects" />
            <Tooltip title="Add Projects">
              <p onClick={renderSubmit} className="pointer text-white  fs-3">
                <PlusSquareOutlined />
              </p>
            </Tooltip>
          </div>

          <div className="section">
            {/* <div className="mb-1 filter-dropdown">
            <select
              className="form-control p-2"
              onChange={(e) => handleFilter(e.target.value)}
              value={filterValue}
            >
              <option value="">All Projects</option>
              <option value="inProgress">InProgress</option>
              <option value="onhold">On Hold</option>
              <option value="rejected">Rejected</option>
              <option value="completed">Completed</option>
              <option value="notStarted">Not Started</option>
              <option value="goingon">On Going</option>
            </select>
          </div> */}

            {/* <div className="mb-1 filter-dropdown">
              <select
                className="form-control p-2"
                onChange={(e) => handleSortChange(e.target.value)}
                value={sortOption}
              >
                <option value="atoz">A to Z</option>
                <option value="ztoa">Z to A</option>
                <option value="date">Date</option>
              </select>
            </div> */}
            <Spin spinning={loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              />
            </Spin>
          </div>
        </div>
        <div>
          <Modal
            footer={null}
            title="Edit Project"
            visible={isEditModalVisible}
            onCancel={handleCancel}
          >
            <div className="crossicon_class">
              <button
                onClick={() => handleCancel()}
                className="btn "
                tabindex="-1"
                type="button"
                aria-label="Clear Search"
              >
                <span class="MuiIconButton-label">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    aria-label="clear"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  </svg>
                </span>
              </button>
            </div>
            <EditProject
              chapterId={id}
              setIsEditModalVisible={setIsEditModalVisible}
              showData={Details}
              editChapterData={editChapterData}
              onCancel={handleCancel}
            />
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default AllProjects;
