import React, { useEffect, useState, forwardRef } from "react";
import { Select, Button } from "antd";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { API_URL } from "../../environment"; 
import SectionHeader from "../../components/global/SectionHeader"; 
import { updatexlstatus, xlsingledata } from "../../api/api";
import { Navigate, useNavigate } from "react-router";
import Layout from "../../components/global/layout";

const Tabledataxls = (props) => {
  const { Option } = Select;

  const [employees, setEmployees] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getData();
  }, []);

  const tableID = localStorage.getItem("tableID");
  const getData = async (id) => {
    fetch(`${API_URL}getWeeklyplanjson?id=${tableID}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setEmployees(res.data);
      });
  };
  const navigate = useNavigate();

  const renderSubmitBack = () => {
    navigate("/projectassigntable");
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const newlyAddedChapter =
    employees && employees.length
      ? employees.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  const xlsingle = async () => {
    try {
      const resp = await xlsingledata(tableID);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data.tasks;
      }
    } catch (e) {}
  };
  var changeArrr = [];
  const handleChange = (event, value) => {
    changeArrr.push({ status: event, taskname: value });
    localStorage.setItem("newArray", JSON.stringify(changeArrr));
  };

  const columns = [
    {
      title: "Weekly-Tasks",
      dataIndex: "documents",
      field: "Week_18oct_to_22oct_2021",
      render: (text, record) => <>{text.Week_18oct_to_22oct_2021}</>,
    },
    {
      title: "Action",
      dataIndex: "Action",
      field: "Action",
      render: (text, record) => (
        <>
          {text && text.progress_status ? (
            <span>
              <Select
                style={{ width: "100%" }}
                placeholder="select one status"
                defaultValue={[text && text.progress_status]}
                onChange={(e) => handleChange(e, text.Week_18oct_to_22oct_2021)}
              >
                <Option value="InProgress" label="InProgress">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="InProgress">
                      InProgress
                    </span>
                  </div>
                </Option>
                <Option value="Success" label="Success">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="Success">
                      Success
                    </span>
                  </div>
                </Option>
                <Option value="Failed" label="Failed">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="Failed">
                      Failed
                    </span>
                  </div>
                </Option>
              </Select>
            </span>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const submittask = async () => {
    const getArray = localStorage.getItem("newArray");

    const arrayParsedata = JSON.parse(getArray);

    const payload = { tasks: arrayParsedata };

    try {
      const resp = await updatexlstatus(tableID, payload);
      if (resp && resp.status === 200) {
        xlsingle();
        navigate("/projectFile");
      }
    } catch (e) {}
  };

  return (
    <Layout>
      <div>
        <div className="main-wrapper">
          <div className="navbar-bg" />
       
        </div>

        <div className="main-content">
          <section className="section">
            <SectionHeader title="XL File" />
            <div>
              <Button
                type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </div>

            <div>
              <table id="employee">
                <thead>{/* <tr>{renderHeader()}</tr> */}</thead>
                <tbody>{/* {renderBody()} */}</tbody>
              </table>
            </div>

            <div>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                icons={tableIcons}
                // title="Project Data"
                data={newlyAddedChapter}
                columns={columns}
              />
            </div>
          </section>

          <button className="login-form-button" onClick={(e) => submittask(e)}>
            Submit
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Tabledataxls;
