import React, { useState, useEffect } from "react";
import moment from "moment";
import SectionHeader from "../../components/global/SectionHeader";
import "hammerjs";
import { useDispatch } from "react-redux";
import { PROJECT_INFORMATION, USER_DETAIL } from "../../redux/Store/constants";
import { notification, Tooltip } from "antd";
import "./Dashboard.css";
import { useNavigate } from "react-router";
import { API_URL } from "../../environment";
import Layout from "../../components/global/layout";

import { HiOutlineUsers } from "react-icons/hi2";
import { GoProjectRoadmap } from "react-icons/go";
import { LuUserCheck2, LuUserX2 } from 'react-icons/lu';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { BiSolidLike } from "react-icons/bi";
import { IoCheckmarkDone } from "react-icons/io5";
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";


const userid = localStorage.getItem("USERID");


const Dashboardpage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [sdata, setSdata] = useState([]);
  const [dueDate, setDueDate] = useState([]);

  useEffect(() => {
    getStageData();
    userDetails();
  }, []);
  const getStageData = (data) => {
    fetch(`${API_URL}getprojectstatuscount`, {
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          setSdata(res.data);
          dispatch({
            type: PROJECT_INFORMATION,
            payload: res,
          });
        }
        if (res.code === 401) {
          localStorage.clear();
          navigate("/");
          setTimeout(() => {
            notification.error({
              message: "Unauthorized",
              description: "Your Session Has Expired. Please logIn.",
            });
          }, 1000);
        }
      });
  };
  const userDetails = () => {
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setDueDate(res && res.data && res.data.appraisalDate);
        // setcheckInstatus(res.data.checkInstatus)
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
          //history("/dashboard")
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  };
  return (
    <Layout>


      <div className="main_dashboards">


        <div className="row py-3">
          <div className="col-sm-12">
            <h3 className="main-title-new">Welcome <span className='primary font800'>HR</span></h3>
            <ul className="breadcrumb mt-1">
              <li className="breadcrumb-item active">Dashboard</li>
            </ul>
          </div>
        </div>


        <div className='mt-2'>


          <div className='row'>
            <div className='col-12 col-md-2 col-lg-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='dashtitles'>
                      <h4 className=''>176</h4>
                      <p>Total Employees</p>
                    </div>
                    <div className='icons_dash'>
                      <HiOutlineUsers />

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-2 col-lg-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='dashtitles'>
                      <h4 className=''>176</h4>
                      <p>Total Projects</p>
                    </div>
                    <div className='icons_dash'>
                      <GoProjectRoadmap />


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-2 col-lg-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='dashtitles'>
                      <h4 className=''>176</h4>
                      <p>Presents</p>
                    </div>
                    <div className='icons_dash'>
                      <LuUserCheck2 />


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-2 col-lg-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='dashtitles'>
                      <h4 className=''>176</h4>
                      <p>Absents</p>
                    </div>
                    <div className='icons_dash'>
                      <LuUserX2 />


                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>




        <div className='mt-4'>
          <div className='row'>
            <div className='col-12 col-md-8 col-lg-9'>
              <div className='anouncements'>
                <div className='inputs'>
                  <p className='postadds' data-bs-toggle="modal" data-bs-target="#posting"><MdOutlineAddPhotoAlternate />      Add New Post</p>
                </div>
              </div>

              <div className='images_data mt-4'>

              <div className='postds_ones'>


                <div className="card">
                  <div className="card-header">
                    <div className='d-flex align-items-center w-100 justify-content-between'>
                      <div className="d-flex align-items-center gap-2">
                        <img src="img/avatar-21.jpg" className="smallimg" />
                        <div className="users_list">
                          <h4 className=''>John Doe</h4>
                          <p className='breads '>Senior HR</p>
                        </div>
                      </div>
                   <div className="d-flex gap-3">
                   <div className="datestime">
                        <p className="date">11/05/2005</p>
                        <p className="time">09:50pm</p>
                      </div>

                      <div className="arros_lines">
                        <div className="dropdown">
                         <Tooltip placement="top" title="Actions">
                         <p className="pointer text-md"  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <PiDotsThreeCircleVertical />
                          </p>
                         </Tooltip>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="#"><FiEdit3 /> Edit</a></li>
                            <li><a className="dropdown-item" href="#"><AiOutlineDelete />      Delete </a></li>
                          </ul>
                        </div>
                      
                      </div>
                   </div>

                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex gap-4">
                      <div className="dats_showis">
                        <img src="img/login.png" className="" />
                      </div>
                      <div className="textsarea_post">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>


                        <div className="likes_comments">
                          <p className="d-flex align-items-center gap-1 pointer"><BiSolidLike /> Like</p>
                          <p className="pointer" data-bs-toggle="modal" data-bs-target="#seenby" ><IoCheckmarkDone /> Seen </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              

             


              </div>

              </div>


            </div>
            <div className='col-12 col-md-4 col-lg-3'>
              <div className='left_sdiebars'>

                <div className='inner_setins mb-3'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex align-items-center w-100 justify-content-between'>
                        <h4 className='card-title'>Recent Activites</h4>
                        <p className='breads text-primary'>5 New</p>
                      </div>

                    </div>
                    <div className='card-body p-3'>
                      <ul className='ulpad texts_list'>
                        <li><span className="dots"></span> John Doe Added A new task</li>
                        <li><span className="dots"></span>John Doe Added A new Post</li>


                      </ul>
                    </div>
                  </div>
                </div>


                <div className='inner_setins mb-3'>
                  <div className='card'>
                    <div className='card-header '>
                      <div className='d-flex align-items-center w-100 justify-content-between'>
                        <h4 className='card-title'>Absent</h4>
                        <p className='circlebreads'>5</p>
                      </div>
                    </div>
                    <div className='card-body p-3'>
                      <ul className='ulpad texts_list'>
                        <li>John Doe</li>
                        <li>Alex</li>
                        <li>Mark</li>
                        <li>Hunr</li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className='inner_setins mb-3'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex align-items-center w-100 justify-content-between'>
                        <h4 className='card-title'>Birthdays</h4>
                        <p className='circlebreads'>5</p>
                      </div>

                    </div>
                    <div className='card-body p-3'>
                      <ul className='ulpad texts_list'>
                        <li>John Doe</li>
                        <li>Alex</li>
                        <li>Mark</li>
                        <li>Hunr</li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className='inner_setins mb-3'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex align-items-center w-100 justify-content-between'>
                        <h4 className='card-title'>Upcoming Anniversery</h4>
                        <p className='circlebreads'>5</p>
                      </div>

                    </div>
                    <div className='card-body p-3'>
                      <ul className='ulpad texts_list'>
                        <li>John Doe</li>
                        <li>Alex</li>
                        <li>Mark</li>
                        <li>Hunr</li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className='inner_setins mb-3'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex align-items-center w-100 justify-content-between'>
                        <h4 className='card-title'>Late Comings</h4>
                        <p className='circlebreads'>5</p>
                      </div>

                    </div>
                    <div className='card-body p-3'>
                      <ul className='ulpad texts_list'>
                        <li>John Doe</li>
                        <li>Alex</li>
                        <li>Mark</li>
                        <li>Hunr</li>

                      </ul>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>


        {/* posting modals */}

        <div className="modal fade" id="posting" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">New Post</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="">

                    <form>
                      <div className="mb-3">
                        <textarea className="form-control" id="post-content" placeholder='Enter text' rows="10"></textarea>
                      </div>
                      <div className="mb-3">
                        <input type="file" className="form-control" id="image-upload" accept="image/*" />
                      </div>

                      <div className="image-container mb-3">
                        <img src="" alt="" id="uploaded-image" className="img-fluid" />

                      </div>

                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>


        {/* seen by emplyees modals */}
        <div className="modal fade" id="seenby" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Seen By</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="seenby">
                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className=''>John Doe</h4>
                      <p className='breads mt-1'>Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className=''>John Doe</h4>
                      <p className='breads mt-1'>Senior HR</p>
                    </div>
                  </div>


                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className=''>John Doe</h4>
                      <p className='breads mt-1'>Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className=''>John Doe</h4>
                      <p className='breads mt-1'>Senior HR</p>
                    </div>
                  </div>


                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className=''>John Doe</h4>
                      <p className='breads mt-1'>Senior HR</p>
                    </div>
                  </div>





                </div>
              </div>

            </div>
          </div>
        </div>



        {/* <div className="chatcls  mb-3">
  {getpiechartcount ? (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={dataDonutChart}
      options={optionsDonutChart}
    />
  ) : (
    <div className="text-center">
      <img
        src="/img/no-data-icon-10-removebg-preview (2).png"
        className="nodataimg"
      />
    </div>
  )}
</div> */}
      </div>

      {/* <div className="">
        
        <div className="dashmain">
          <div className="main-content">
            <section className="section">
              <div className="blink">
                <span className="blink">
                  Next_Appraisal_Date {moment(dueDate).format("MM/DD/YYYY")}
                </span>
              </div>
              <SectionHeader title="Dashboard" />
            </section>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};
export default Dashboardpage;
