import React, { useState, useEffect } from "react";
import SectionHeader from "../../components/global/SectionHeader";
import "hammerjs";
import { Spin } from "antd";
import Chart from "react-google-charts";
import "./Dashboard.css";
import { useNavigate } from "react-router";
import ApiClient from "../../api/apiClient";
import loader from "../../components/loader";
import Layout from "../../components/global/layout";
import { HiOutlineUsers } from "react-icons/hi2";
import { GoProjectRoadmap } from "react-icons/go";
import { LuUserCheck2, LuUserX2 } from "react-icons/lu";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { BiSolidLike } from "react-icons/bi";
import { IoCheckmarkDone, IoSearchOutline } from "react-icons/io5";
import { API_URL } from "../../environment";
import { FiSearch } from "react-icons/fi";

const DashBoard = () => {
  const navigate = useNavigate();
  const [projecttotal, setprojecttotal] = useState();
  const [employeeEmails, setemployeeEmails] = useState();
  const [totalsuccessproject, settotalsuccessproject] = useState();
  const [totalfailedproject, settotalfailedproject] = useState();
  const [linechartsuccess, setlinechartsuccess] = useState([]);
  const [linechartfailed, setlinechartfailed] = useState([]);
  const [employeeData, setemployeeData] = useState({});
  const [loading, setloading] = useState(false);
  const token = localStorage.getItem("token");
  let getpiechartcount = totalsuccessproject + totalfailedproject;
  let getlinechartcount = linechartsuccess + linechartfailed;

  useEffect(() => {
    // getStageData();
    userDetails();
    charttotalproject();
    chartsuccessproject();
    chartfailedproject();
    linechartdata();
    employeEmails();
  }, []);
  // const getStageData = () => {
  //   fetch(`${API_URL}getprojectstatuscount`, {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {
  //         setSdata(res.data);
  //       }
  //       if (res.code === 401) {
  //         localStorage.clear();
  //         navigate("/");
  //         setTimeout(() => {
  //           notification.error({
  //             message: "Unauthorized",
  //             description: "Your Session Has Expired. Please logIn.",
  //           });
  //         }, 1000);
  //       }
  //     });
  // };

  const userDetails = () => {
    const userid = localStorage.getItem("USERID");
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setemployeeData(res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
        } else {
        }
      });
  };

  const data = [
    ["Day", "Success", "Failed"],
    ...(linechartsuccess &&
      linechartsuccess.map((item) => {
        return [
          `${item.day}/${item.month}/${item.year}`,
          item.totalEmployeeCounts,
          0,
        ];
      })),
    ...(linechartfailed &&
      linechartfailed.map((item) => {
        return [
          `${item.day}/${item.month}/${item.year}`,
          0,
          item.totalEmployeeCounts,
        ];
      })),
  ];
  const options = {
    chart: {
      title: `Total Projects: ${projecttotal}`,
      subtitle: "s",
    },
  };
  const dataDonutChart = [
    ["Task", "Hours per Day"],
    ["Success", totalsuccessproject],
    ["Failed", totalfailedproject],
  ];
  const optionsDonutChart = {
    title: `Total Projects: ${projecttotal}`,
    fontsize: 30,
    pieHole: 0.4,
    is3D: false,
  };
  const employeEmails = () => {
    setloading(true);
    ApiClient.get(`users`).then((res) => {
      if (res.success) {
        setloading(false);
        setemployeeEmails(res?.data?.map((item) => item));
      }
    });
  };
  const charttotalproject = () => {
    setloading(true);
    ApiClient.get(`all/projects`).then((res) => {
      if (res.success) {
        setloading(false);
        setprojecttotal(res.TotalProjects);
      }
    });
  };
  const chartsuccessproject = () => {
    setloading(true);
    ApiClient.get(`projects/status?status=Success`).then((res) => {
      if (res.success) {
        setloading(false);
        settotalsuccessproject(res.TotalProjects);
      }
    });
  };
  const chartfailedproject = () => {
    setloading(true);
    ApiClient.get(`projects/status?status=failed`).then((res) => {
      if (res.success) {
        setloading(false);
        settotalfailedproject(res.TotalProjects);
      }
    });
  };
  const linechartdata = () => {
    setloading(true);
    ApiClient.get(`analatics?type=daily`)
      .then((res) => {
        if (res.success) {
          setlinechartsuccess(res.totalCompletedProjectCount.response);
          setlinechartfailed(res.totalRejectedProjectsCount.response);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        } else {
        }
      });
    setloading(false);
  };

  return (
    <Layout>
      <div className="main_dashboards">
        <div className="row py-3">
          <div className="col-sm-12">
            <h3 className="main-title-new">
              Welcome{" "}
              <span className="primary font800">{employeeData?.fullName}</span>
            </h3>
            {/* <ul className="breadcrumb mt-1">
              <li className="breadcrumb-item active">Dashboard</li>
            </ul> */}
          </div>
        </div>

        <div className="mt-2">
          <div className="row">
            <div className="col-12 col-md-2 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="dashtitles">
                      <h4 className="">--</h4>
                      <p>Total Projects</p>
                    </div>
                    <div className="icons_dash">
                      <HiOutlineUsers />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-2 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="dashtitles">
                      <h4 className="">--</h4>
                      <p>Completed</p>
                    </div>
                    <div className="icons_dash">
                      <GoProjectRoadmap />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="dashtitles">
                      <h4 className="">--</h4>
                      <p>Working</p>
                    </div>
                    <div className="icons_dash">
                      <LuUserCheck2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="dashtitles">
                      <h4 className="">
                        --
                      </h4>
                      <p>Leave Pending</p>
                    </div>
                    <div className="icons_dash">
                      <LuUserX2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-9">
              <div className="images_data ">
                <div className="postds_ones">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex align-items-center w-100 justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <img src="img/tv.jpeg" className="smallimg" />
                          <div className="users_list">
                            <h4 className="">Tanvi Sharma</h4>
                            <p className="breads ">Senior HR</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="datestime">
                            <p className="date">26/08/2024</p>
                            <p className="time">03:50pm</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex gap-4">
                        <div className="dats_showis">
                          <img src="img/_Happy Krishna Janmashtami.png" className="" />
                        </div>
                        <div className="textsarea_post">
                          <p>
                            🌟 Happy Krishna Janmashtami! 🌟 <br/>JC Software
                            Solution wishes you a joyous and blessed Krishna
                            Janmashtami! <br/>May Lord Krishna's divine blessings
                            fill your life with peace, prosperity, and
                            happiness. 🙏✨<br/> Let's celebrate the birth of Lord
                            Krishna with devotion, love, and joy! 🎉🎊<br/>
                            #Janmashtami #KrishnaJanmashtami #JaiShriKrishna
                            #JCSoftwareSolution
                          </p>

                          <div className="likes_comments">
                            {/* <p className="d-flex align-items-center gap-1 pointer">
                              <BiSolidLike /> Like
                            </p> */}
                            <p
                              className="pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#seenby"
                            >
                              {/* <IoCheckmarkDone /> Seen{" "} */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="left_sdiebars">
                <div className="inner_setins mb-3">
                  <div className="card">
                    <div className="card-header ">
                      <div className="d-flex align-items-center w-100 justify-content-between">
                        <h4 className="card-title">Absent</h4>
                        <p className="circlebreads">-</p>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <ul className="ulpad texts_list">
                        <li>No Data </li>
                        {/* <li>Alex</li>
                        <li>Mark</li>
                        <li>Hunr</li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="inner_setins mb-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex align-items-center w-100 justify-content-between">
                        <h4 className="card-title">Birthdays</h4>
                        <p className="circlebreads">-</p>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <ul className="ulpad texts_list">
                        <li>No Data</li>
                        {/* <li></li>
                        <li></li>
                        <li></li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className='inner_setins mb-3'>
                            <div className='card'>
                              <div className='card-header'>
                              <div className='d-flex align-items-center w-100 justify-content-between'>
                                  <h4 className='card-title'>Upcoming Anniversery</h4>
                                  <p className='circlebreads'>5</p>
                                  </div>
                                 
                              </div>
                              <div className='card-body p-3'>
                                  <ul className='ulpad texts_list'>
                                    <li>John Doe</li>
                                    <li>Alex</li>
                                    <li>Mark</li>
                                    <li>Hunr</li>

                                  </ul>
                              </div>
                            </div>
                        </div> */}

                {/* <div className='inner_setins mb-3'>
                            <div className='card'>
                              <div className='card-header'>
                              <div className='d-flex align-items-center w-100 justify-content-between'>
                                  <h4 className='card-title'>Late Comings</h4>
                                  <p className='circlebreads'>5</p>
                                  </div>
                                 
                              </div>
                              <div className='card-body p-3'>
                                  <ul className='ulpad texts_list'>
                                    <li>John Doe</li>
                                    <li>Alex</li>
                                    <li>Mark</li>
                                    <li>Hunr</li>

                                  </ul>
                              </div>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>

     





        <div
          className="modal fade"
          id="posting"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New Post
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="">
                    <form>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="post-content"
                          placeholder="Enter text"
                          rows="10"
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="image-upload"
                          accept="image/*"
                        />
                      </div>

                      <div className="image-container mb-3">
                        <img
                          src=""
                          alt=""
                          id="uploaded-image"
                          className="img-fluid"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* seen by emplyees modals */}
        <div
          className="modal fade"
          id="seenby"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Seen By
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="seenby">
                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className="">John Doe</h4>
                      <p className="breads mt-1">Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className="">John Doe</h4>
                      <p className="breads mt-1">Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className="">John Doe</h4>
                      <p className="breads mt-1">Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className="">John Doe</h4>
                      <p className="breads mt-1">Senior HR</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2 borderb">
                    <img src="img/avatar-21.jpg" className="smallimg" />
                    <div className="users_list">
                      <h4 className="">John Doe</h4>
                      <p className="breads mt-1">Senior HR</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="chatcls  mb-3">
          {getpiechartcount ? (
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={dataDonutChart}
              options={optionsDonutChart}
            />
          ) : (
            <div className="text-center">
              <img
                src="/img/no-data-icon-10-removebg-preview (2).png"
                className="nodataimg"
              />
            </div>
          )}
        </div> */}
      </div>
    </Layout>
  );
};
export default DashBoard;
