import React, { useEffect, useState, forwardRef } from "react"; 
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import SectionHeader from "../../components/global/SectionHeader";
import { API_URL } from "../../environment";
import { useDispatch } from "react-redux";
import { SHOW_FILE } from "../../redux/Store/constants";
import Layout from "../../components/global/layout";
const Updatetask = () => {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState();
  const [array, setArray] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Task-Name",
      dataIndex: "taskname",
      field: "taskname",
    },
    {
      title: "Status",
      dataIndex: "status",
      field: "status",
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          //  localStorage.setItem('projectId', items.id);
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    Knowledgesharingtable();
  }, []);

  const xlIduser = localStorage.getItem("updatedxldata");

  const token = localStorage.getItem("token");
  const Knowledgesharingtable = (id) => {
    setLoading(true);

    fetch(`${API_URL}getsingleWeeklyplan?id=${xlIduser}`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data.tasks);

        setDocs(res.data);

        if (res && res.data && res.data.access_token) {
          dispatch({
            type: SHOW_FILE,
            payload: res,
          });
          //history("/dashboard")
        }
        if (res.code === 401) {
          localStorage.clear();
        }
        setLoading(false);
      });
  };

  return (
    <Layout>
      <div>
        <div className="main-wrapper">
          <div className="navbar-bg" />
          
        </div>

        <div className="main-content">
          <section className="section">
            <SectionHeader title="XL File" />
            {/* <div>
                            <Button type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />



                        </div> */}

            <div>
              <table id="employee">
                <thead></thead>
                {/* <tbody>
                                    {renderBody()}
                                </tbody> */}
              </table>
            </div>

            <div>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                icons={tableIcons}
                // title="Project Data"
                data={newlyAddedChapter}
                columns={columns}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Updatetask;
