import React, { useState } from "react";
import SectionHeader from "../../components/global/SectionHeader";
import { Form, Button, Tooltip, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../knowledgeShare/Knowledgesharing.css";
import { UploadOutlined } from "@ant-design/icons";
import { Input, notification } from "antd";
import { API_URL } from "../../environment";
import "../leaves/Leaveform.css";
import {
  KNOWLEDGESHARINGPOST_PARTICULARPERSON,
  KNOWLEDGE_SHARINGPOST,
} from "../../redux/Store/constants";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";
const token = localStorage.getItem("token");
const userid = localStorage.getItem("USERID");

const Knowledgesharingtable = () => {
  const [Text, setText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImagesUrl] = useState("");
  let history = useNavigate();
  const dispatch = useDispatch();
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const Knowledgesharingtable = () => {
    setLoading(true);
    fetch(`${API_URL}posts?id=${userid}&count=1000&sortBy=desc `, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: KNOWLEDGESHARINGPOST_PARTICULARPERSON,
            payload: res,
          });
        } else {
          setLoading(false);
        }
      });
  };
  const onFinish = async (values) => {
    setLoading(true);
    const payload = {
      name: values.name,
      description: values.description,
      image: imgUrl,
    };

    fetch(`${API_URL}add/post`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        Knowledgesharingtable();
        setLoading(false);
        if (res.success === true) {
          dispatch({
            type: KNOWLEDGE_SHARINGPOST,
            payload: res,
          });
          history("/knowledgesharinglist");

          notification.success({
            message: "Knowledge Sharing has been Posted",
          });
          setLoading(false);
        } else {
          setLoading(false);
          notification.error({
            message: "Post Already Exist.",
          });
        }
      });

    //   setLoading(false);
  };

  const handleInputChange = async (event) => {
    let file = event.target.files[0];
    if (file != null) {
      const fileToUpload = file;

      setLoading(true);
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", fileToUpload);

      let res = await fetch(
        `${API_URL}project/uploadDocument`,

        {
          method: "post",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let responseJson = await res.json();

      if (responseJson.success === true) {
        setLoading(false);

        setImagesUrl(API_URL + responseJson.data[0].imagePath);
        notification.success({
          message: "File Upload Successfully",
        });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      notification.error({ message: "Please Select the Image first" });
    }
  };
  const iconbtn = () => {
    history("/knowledgesharinglist");
  };
  return (
    <Layout>
      <div>
        <div className="main-content">
          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p onClick={iconbtn} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Knowledge Sharing Form" />
            </div>
          </div>

          <div className="">
            <Spin spinning={loading}>
              <div className="section">
                <div className="leave">
                  <Form
                    name="normal_login"
                    onFinish={onFinish}
                    className="login-form"
                    validateMessages={validateMessages}
                  >
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <div className="form-group">
                          <label>
                            Topic Name<span className="star">*</span>
                          </label>
                          <Form.Item
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Topic Name",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Topic Name" />
                          </Form.Item>
                        </div>
                        <div className="">
                          <label>Select File</label>

                          <Input
                            multiple="multiple"
                            accept="image/*"
                            type="file"
                            onChange={handleInputChange}
                            icon={<UploadOutlined />}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-7">
                        <div className="form-group">
                          <label>
                            Description<span className="star">*</span>
                          </label>
                          <Form.Item
                            name="description"
                            valuePropName="data"
                            getValueFromEvent={(event, editor) => {
                              const data = editor.getData();
                              return data;
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please Enter the Description",
                              },
                            ]}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <Form.Item>
                        <div className="btn_submit mt-3">
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Knowledgesharingtable;
