import React, { useState, useEffect } from 'react';
import { useTimeModel, createTimeModel } from 'react-compound-timer';
import Timesheet1 from './Timesheet1';
import '../timesheet/Timesheet.css';
import { API_URL } from '../../environment';
import { useDispatch } from 'react-redux';
import { USER_DETAIL } from '../../redux/Store/constants';

const Timesheet = () => {
  const token = localStorage.getItem('token');
  const [totalTime, setTotalTime] = useState();
  const [loading, setLoading] = useState(false);
  const userid = localStorage.getItem('USERID');
  const dispatch = useDispatch();

  useEffect(() => {
    userDetails();
  }, []);

  const userDetails = async () => {
    try {
      const response = await fetch(`${API_URL}userDetail?id=${userid}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTotalTime(data.data && data.data.totalIntime);
      if (data && data.data && data.data.access_token) {
        dispatch({
          type: USER_DETAIL,
          payload: data,
        });
        // history("/dashboard")
      } else {
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const timeModel = useTimeModel({
    initialTime: 55000,
    onStart: () => console.log('Timer started'),
    onStop: () => console.log('Timer stopped'),
  });

  return (
    <>
      <div className="timesheet">
        <Timesheet1
          start={() => timeModel.start()}
          stop={() => timeModel.stop()}
        />
      </div>
    </>
  );
};

export default Timesheet;