import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader"; 
import { getsingleresign } from "../../api/api";
import { Navigate, useNavigate } from "react-router";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const ResignDetails = (props) => {
  const [loading, setloading] = useState(false);
  const [names, setName] = useState();
  const [desc, setDescription] = useState();
  const [frontTech, setFrontTechnology] = useState();
  const [backTech, setBackTech] = useState();
  const [clientNames, setClientName] = useState();
  const [LeaveStatus, setLeave] = useState();
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  useEffect(() => {
    getProject();
  }, []);

  const getProject = async (i) => {
    setloading(true);
    const Id = localStorage.getItem("resignationemp");
    try {
      const resp = await getsingleresign(Id);
      if (resp && resp.status === 200) {
        const list = resp;
        const name = list.data.data.employeeName;
        const description = list.data.data.description;
        const frontendtechnology = list.data.data.fromEmail;
        const backendtechnology = list.data.data.toEmail;
        const clientName = list.data.data.subject;

        const leave_status = list.data.data.resign_status;
        setName(name);
        setDescription(description);
        setFrontTechnology(frontendtechnology);
        setBackTech(backendtechnology);
        setClientName(clientName);
        setLeave(leave_status);
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const navigate = useNavigate();
  const renderSubmitBack = () => {
    navigate("/resignation");
  };
  return (
    <Layout>
    <div className="text-left">
    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Resignation Details" />
            </div>
          </div>
          <Spin spinning={loading}>
            <div className=" ">
              <div className="">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Employee-Name:</label>
                      </div>
                      <div className="label_text">
                        <span className="text-capitalize"> {names}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Subject:</label>
                      </div>
                      <div className="label_text">
                        <span> {clientNames}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">From-Email:</label>
                      </div>
                      <div className="label_text">
                        <span> {frontTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Resignation-Status:</label>
                      </div>
                      <div className="label_text">
                        <span
                          className={`text-${
                            LeaveStatus == "Perding" ? "warning" :LeaveStatus=="Accepted"?"success":"danger"
                          }`}
                        >
                          {" "}
                          {LeaveStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">To-Email:</label>
                      </div>
                      <div className="label_text">
                        <span> {backTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Reason:</label>
                      </div>
                      <div className="label_text">
                        <span>{htmlToReactParser.parse(desc)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
    </div>
    </Layout>
  );
};

export default ResignDetails;
