import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader";
import { useSidebar } from "../../components/provider/Provider"; 
import { Spin } from "antd/lib";
import { useNavigate } from "react-router";
import moment from "moment";
import { getProjectDetails } from "../../api/api";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const ProjectDetail = (props) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [names, setName] = useState();
  const [desc, setDescription] = useState();
  const [frontTech, setFrontTechnology] = useState();
  const [backTech, setBackTech] = useState();
  const [clientNames, setClientName] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [dates, setDate] = useState();
  const [apibranch, setapibranch] = useState();
  const [adminbranch, setadminbranch] = useState();
  const [frontendbranch, setfrontendbranch] = useState();
  const [reponame, setreponame] = useState();
  const [mergewithmaster, setmergewithmaster] = useState();
  const [frontendclonelink, setfrontendclonelink] = useState();
  const [frontendclonepassword, setfrontendclonepassword] = useState();
  const [adminclonelink, setadminclonelink] = useState();
  const [adminclonepassword, setadminclonepassword] = useState();
  const [apiclonelink, setapiclonelink] = useState();
  const [apiclonepassword, setapiclonepassword] = useState();
  const [endDate, setendDate] = useState();

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const { courseId } = useSidebar();

  const handleteamdetails = () => {
    navigate(`/project/team/${id}`);
  };
 // const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("xlIduser", id);

    getProject();
  }, []);

  const getProject = async (i) => {
    setloading(true);
    const Id = localStorage.getItem("xlIduser");
    try {
      const resp = await getProjectDetails(Id);
      if (resp && resp.status == 200) {
        const list = resp;
        setloading(false);
        setName(list.data.data.name);
        setDescription(list.data.data.description);
        setFrontTechnology(list.data.data.frontendtechnology);
        setBackTech(list.data.data.backendtechnology);
        setClientName(list.data.data.clientName);
        setStatus(list.data.data.progress_status);
        setType(list.data.data.project_type);
        setDate(list.data.data.startDate);
        setapibranch(list.data.data.updated_branch_api);
        setadminbranch(list.data.data.updated_branch_admin);
        setfrontendbranch(list.data.data.updated_branch_frontend);
        setreponame(list.data.data.repository_name);
        setmergewithmaster(list.data.data.is_updated_branch_merged_with_master);
        setfrontendclonelink(list.data.data.frontend_clone_link);
        setfrontendclonepassword(list.data.data.frontend_clone_password);
        setadminclonelink(list.data.data.admin_clone_link);
        setadminclonepassword(list.data.data.admin_clone_password);
        setapiclonelink(list.data.data.backend_clone_link);
        setapiclonepassword(list.data.data.backend_clone_password);
        setendDate(list.data.data.endDate);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const renderSubmitBack = () => {
    navigate(-1);
  };
  return (
    <Layout>
    <div className="main-content">

    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Project Detail" />
            </div>
          </div>
      
      <div className="">



        <div className="section">
          
          <Spin spinning={loading}>
            <div className="">
              <div className=" w-100">
                <h4 className="text-center mb-3 pr-5 mr-5 text-capitalize">
                  Project-Name:-<b className="text-dark">{names}</b>
                </h4>
                <div class="row">
                  {/* <div class="col-6">
                  <label className="text-bold">Project-Name:</label>
                  <span > {names}</span>
                </div> */}
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">
                          Frontend-Technology:
                        </label>
                      </div>
                      <div className="label_text">
                        <span> {frontTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Backend-Technology:</label>
                      </div>
                      <div className="label_text">
                        <span> {backTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Client-Name:</label>
                      </div>
                      <div className="label_text">
                        <span> {clientNames}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Progress-Status:</label>
                      </div>
                      <div className="label_text">
                        <span> {status}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Project-Type:</label>
                      </div>
                      <div className="label_text">
                        <span> {type}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="project_deatil">
                          <div className="label_heading">
                            <label className="text-bold">Start-Date:</label>
                          </div>
                          <div className="label_text">
                            <span> {moment(dates).format("DD MMM YYYY")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="project_deatil">
                          <div className="label_heading">
                            <label className="text-bold pl-2">End-Date:</label>
                          </div>
                          <div className="label_text">
                            <span>
                              {" "}
                              {moment(endDate).format("DD MMM YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">API Updated Branch:</label>
                      </div>
                      <div className="label_text">
                        <span> {apibranch}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">
                          Admin Updated Branch:
                        </label>
                      </div>
                      <div className="label_text">
                        <span> {adminbranch}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">
                          Frontend Updated Branch:
                        </label>
                      </div>
                      <div className="label_text">
                        <span> {frontendbranch}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Repository Name:</label>
                      </div>
                      <div className="label_text">
                        <span className="d-inline-flex text-break">
                          {" "}
                          {reponame}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">
                          Updated Branch Merge with Master:
                        </label>
                      </div>
                      <div className="label_text">
                        <span> {mergewithmaster == "Y" ? "Yes" : "No"}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">
                          Frontend Clone Link:
                        </label>
                      </div>
                      <div className="label_text">
                        <span> {frontendclonelink}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Frontend Password:</label>
                      </div>
                      <div className="label_text">
                        <span className=""> {frontendclonepassword}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6  ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Admin Clone Link:</label>
                      </div>
                      <div className="label_text">
                        <span className="d-inline-flex text-break">
                          {" "}
                          {adminclonelink}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Admin Password:</label>
                      </div>
                      <div className="label_text">
                        <span className="d-inline-flex text-break">
                          {" "}
                          {adminclonepassword}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">API Clone Link:</label>
                      </div>
                      <div className="label_text">
                        <span className="d-inline-flex text-break">
                          {" "}
                          {apiclonelink}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">API Password:</label>
                      </div>
                      <div className="label_text">
                        <span> {apiclonepassword}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Description:</label>
                      </div>
                      <div className="label_text">
                        <span class="prettify d-inline-flex text-break">
                          {" "}
                          {htmlToReactParser.parse(desc)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={handleteamdetails}
                  className="mb-3"
                  style={{ marginRight: "0px", right: "0px", float: "right" }}
                  type="primary"
                >
                  Team<i class="fa fa-forward ml-1" aria-hidden="true"></i>
                </Button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ProjectDetail;
