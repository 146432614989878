import React, { useState, useEffect, forwardRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Tooltip } from "antd"; 
import { useDispatch } from "react-redux";
import SectionHeader from "../../components/global/SectionHeader"; 
import { Spin, notification, Select, Button } from "antd";
import { API_URL } from "../../environment";
import { PROJECT_ASSIGN } from "../../redux/Store/constants"; 
import { Link } from "react-router-dom"; 
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn"; 
import { useSidebar } from "../../components/provider/Provider";
import { updateStatus } from "../../api/user/userapi";
import Layout from "../../components/global/layout";

const { Option } = Select;

const Reviewproject = () => {
  const { setCourseId } = useSidebar();
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("USERID");
  const [toggle, setToggle] = useState(false);
  const [array, setArray] = useState([]); 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(null);
  const [Progressstatusid, setProgressstatusid] = useState();
  const [Progressstatusevent, setProgressstatusevent] = useState();
  var JSZip = require("jszip");
  var zip = new JSZip();

  let photoZip = zip.folder(`${job.streetAddress}`);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    projectAssign();
  }, []);

  const files = [
    {
      name: "Photo 1",
      thumb:
        "https://images.unsplash.com/photo-1604263439201-171fb8c0fddc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=427&q=80 427w",
      file:
        "https://images.unsplash.com/photo-1604263439201-171fb8c0fddc?rnd=" +
        Math.random(),
      filename: "photo-1.jpg",
    },
    {
      name: "Photo 2",
      thumb:
        "https://images.unsplash.com/photo-1604164388977-1b6250ef26f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=401&q=80 401w",
      file:
        "https://images.unsplash.com/photo-1604164388977-1b6250ef26f3?rnd=" +
        Math.random(),
      filename: "photo-2.jpg",
    },
    {
      name: "Photo 3",
      thumb:
        "https://images.unsplash.com/photo-1604264849633-67b1ea2ce0a4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80 750w",
      file:
        "https://images.unsplash.com/photo-1604264849633-67b1ea2ce0a4?rnd=" +
        Math.random(),
      filename: "photo-3.jpg",
    },
  ];

  const projectAssign = () => {
    setLoading(true);

    fetch(`${API_URL}project-reviews?page=1&count=500&employeeId=${userid}`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data);
        // localStorage.setItem('Email', res.data[0].fromEmail);
        // setResignationemail(res&&res.data&&res.data[0].fromEmail&&res.data[0].fromEmail)
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: PROJECT_ASSIGN,
            payload: res,
          });
          //history("/dashboard")
        }
        if (res.code === 401) {
          localStorage.clear();

          setTimeout(() => {
            notification.error({
              message: "Unauthorized",
              description: "Your Session Has Expired. Please logIn.",
            });
          }, 1000);
        }
        setLoading(false);
      });
  };   

  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("xlIduser", id);
  }; 

  const SubmitReason = async (event) => {
    setToggle(false);
    const payload = {
      progress_status: Progressstatusevent,
      reason: event.reason,
    };
    try {
      const resp = await updateStatus(Progressstatusid, payload);
      if (resp && resp.status === 200) {
        setTimeout(() => {
          notification.success({
            message: "Status Updated Successfully.",
          });
        }, 1000);
        projectAssign();
      }
    } catch (e) {}
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
      field: "name",
      render: (text, record) => (
        <>
          {text && text?.projectId?.name ? (
            <Link to={`/projectDetail?id=${text.projectId._id}`} className="text">
              <Tooltip title="View">
                <span onClick={() => chapterIdHandle(text.id)}>
                  {text && text.projectId.name}
                </span>
              </Tooltip>
            </Link>
          ) : (
            "--"
          )}
        </>
      ),
    }, 
    {
      title: "Project ",
      dataIndedx: "projecttask",
      field: "projectask",
      render: (text, record) => (
        <>
          {text.projectStatus == "rejected" ? (
            <span className="text-danger">Rejected</span>
          ) : (
            // <Link to={`/reviewstatus?id=${text._id}`}><button className='p-2 my-2 bg-secondary text-light'>Review</button></Link>
            <Link to={`/reviewstatus?id=${text._id}`}>
              <button className="p-2 my-2 bg-secondary text-light">
                Review
              </button>
            </Link>
          )}
        </>
      ),
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
      <div>
       
        <div className="main-content">
          <div className="section">
            <div className="headings">
            <SectionHeader title="Review Projects" />
            </div>
            <Spin spinning={loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              />
            </Spin>
          </div>
        </div>
      </div>

      <Modal isOpen={toggle}>
        <ModalHeader>Reason for Progress Status</ModalHeader>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={(e) => SubmitReason(e)}
          autoComplete="off"
        >
          <Form.Item
            // label="Reason"
            name="reason"
            rules={[{ required: true, message: "Please fill the input!" }]}
          >
            <ModalBody>
              <textarea
                className="form-control"
                id="message-text"
                placeholder="Enter Message"
                required
                style={{
                  width: "448px",
                }}
              />
            </ModalBody>
          </Form.Item>

          <ModalFooter>
            <Button color="orange" htmlType="submit">
              OK
            </Button>{" "}
            <Button color="secondary" onClick={() => setToggle(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Reviewproject;
