import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader";
import moment from "moment";
import { Navigate, useNavigate } from "react-router";
import { getsingleleave } from "../../api/api";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";
const LeaveDetails = (props) => {
  const [loading, setloading] = useState(false);
  const [names, setName] = useState();
  const [desc, setDescription] = useState();
  const [frontTech, setFrontTechnology] = useState();
  const [backTech, setBackTech] = useState();
  const [clientNames, setClientName] = useState();
  const [status, setStatus] = useState();
  const [endTime, setendTime] = useState();
  const [startTime, setstartTime] = useState();
  const [type, setType] = useState();
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    let newmin = "";
    if (minute.length <= 1) {
      newmin = "0" + minute;
    }
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + newmin + (hour < 12 ? " AM" : " PM");
  }

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async (i) => {
    const Id = localStorage.getItem("appliedlives");
    setloading(true);
    try {
      const resp = await getsingleleave(Id);
      if (resp && resp.status === 200) {
        const list = resp;
        const name = list.data.data.employeeName;
        const description = list.data.data.description;
        const frontendtechnology = list.data.data.fromEmail;
        const backendtechnology = list.data.data.toEmail;
        const clientName = list.data.data.subject;
        const progress_status = list.data.data.leaveStartDate;
        const project_type = list.data.data.leaveEndDate;
        const leave_status = list.data.data.leave_status;
        let strt = formatTime(list.data.data.leaveStarttime);
        setstartTime(strt);
        let end = formatTime(list.data.data.leaveEndtime);
        setendTime(end);
        setName(name);
        setDescription(description);
        setFrontTechnology(frontendtechnology);
        setBackTech(backendtechnology);
        setClientName(clientName);
        setStatus(progress_status);
        setType(project_type);
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const navigate= useNavigate();
  const renderSubmitBack = () => {
    navigate("/leavelist");
  };
  return (
    <Layout>
    <div className="">

    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Leave Details" />
            </div>
          </div>

    
          <Spin spinning={loading}>
            <div className="">
              <div className="">
                <div class="row">
                  <div class="col-md-4">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Employee-Name:</label>
                      </div>
                      <div className="label_text">
                        <span> {names}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Subject:</label>
                      </div>
                      <div className="label_text">
                        <span> {clientNames}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Leave-Start-Date:</label>
                      </div>
                      <div className="label_text">
                        <span> {moment(status).format("DD MMM YYYY")}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Leave-End-Date:</label>
                      </div>
                      <div className="label_text">
                        <span> {moment(type).format("DD MMM YYYY")}</span>
                      </div>
                    </div>
                  </div>
                  {clientNames == "half day Leave" ||
                  clientNames == "short Leave" ? (
                    <>
                      <div class="col-md-4">
                        <div className="project_deatil">
                          <div className="label_heading">
                            <label className="text-bold">Start-Time:</label>
                          </div>
                          <div className="label_text">
                            <span>{startTime}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div className="project_deatil">
                          <div className="label_heading">
                            <label className="text-bold">End-Time:</label>
                          </div>
                          <div className="label_text">
                            <span>{endTime}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div class="col-md-4 ">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">From-Email:</label>
                      </div>
                      <div className="label_text">
                        <span> {frontTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">To-Email:</label>
                      </div>
                      <div className="label_text">
                        <span> {backTech}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Reason:</label>
                      </div>
                      <div className="label_text">
                        <span
                          class="prettify pl-1"
                          style={{ marginTop: "-3px" }}
                        >
                          {" "}
                          {htmlToReactParser.parse(desc)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
    </div>
    </Layout>
  );
};

export default LeaveDetails;
