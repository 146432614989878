import React, { useEffect, useState, forwardRef } from "react";
import { Spin } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader"; 
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { getAppraisal } from "../../api/api";
import { useNavigate } from "react-router";
import Layout from "../../components/global/layout";

const Appraisal = () => {
  const history = useNavigate();
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const userid = localStorage.getItem("USERID");
  let data = [];
  if (array) {
    data =
      array &&
      array.filter((item) => {
        if (item.employeeId == userid) {
          return item;
        }
      });
  }
  useEffect(() => {
    userDetails();
  }, []);
  const userDetails = async () => {
    try {
      setLoading(true);
      const resp = await getAppraisal();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray(arrayData);
      }
      if (resp.code === 401) {
        localStorage.clear();
        history("/");
      }
    } catch (e) {}
    setLoading(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      field: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      field: "date",
    },
    {
      title: "Previous Salary",
      dataIndex: "previousSalary",
      field: "previousAmt",
      render: (text, record) => <>{text && text?.previousAmt}</>,
    },
    {
      title: "Appraisal Amount",
      dataIndex: "getAppraisal",
      field: "appraisalAmt",
      render: (text, record) => <>{text && text?.appraisalAmt}</>,
    },
    {
      title: "Current Salary",
      dataIndex: "currentSalary",
      field: "currentAmt",
      render: (text, record) => <>{text && text?.currentAmt}</>,
    },
  ];
  const newlyAddedChapter =
    data && data.length
      ? data.map((items) => {
          localStorage.setItem("rolesid", items.id);
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <Layout>
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Appraisal" />
          <Spin spinning={loading}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
              }}
              columns={columns}
              data={newlyAddedChapter}
              icons={tableIcons}
            />
          </Spin>
        </section>
      </div>
    </div>
    </Layout>
  );
};

export default Appraisal;
