import React, {  useState } from "react";
import { Button,   Spin, Tooltip } from "antd"; 
import { notification } from "antd"; 
import { useNavigate } from "react-router-dom";  
import SectionHeader from "../../components/global/SectionHeader";  
import ApiClient from "../../api/apiClient";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";

export default function Addeditreview() {
  const query = new URLSearchParams(window.location.search);
  const paramValue = query.get("id");
  const userid = localStorage.getItem("USERID");
  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const initial = {
    expectedminutes:"",
    expectedhours:"",
    taskName: "",
    description: "",
    project: paramValue,
    employee: userid,
    addedBy: userid,
  };
  const [form, setform] = useState([initial]);

  const handlechange = (e, index) => {
    const field = [...form];
    setform([...form], (field[index][e.target.name] = e.target.value));
  };
  const backbutton = () => {
    history(-1);
  };
  const handlesubmit = (e) => {
  
    const data ={
      taskProject:form
    }
    e.preventDefault();
    ApiClient.post(`task_review`,data)
      .then((res) => {
        history(-1);
        notification.success({ message: "Task Added Successfully" });
      })
      .catch((err) => {
        notification.error({ message: "Some error Occurred.Please try again" });
      });
  };
  const addmore = () => {
    const newdata = initial;
    setform([...form, newdata]);
  };
  const remove = (i, e) => {
    e.preventDefault();
    const myformdata = [...form];
    myformdata.splice(i, 1);
    setform(myformdata);
  };
  return (
    <Layout>
    <div>
    <div className="main-content">

    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={backbutton} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Review Tasks" />
            </div>
          </div>


          <div className="mt-3">
                <Spin spinning={loading}>
                  <form className="mt-0 p-3" onSubmit={(e) => handlesubmit(e)}>
                    {form.map((item, index) => (
                      <div className=" p-0 mt-0 mb-4">
                        {index == 0 ? null : (
                          <div>
                            <h5 className="d-inline">
                              {index == 0 ? null : "Review Task" + " " + (index + 1)}
                            </h5>{" "}
                            <button
                              type="button"
                              style={{ float: "right" }}
                              onClick={(e) => remove(index, e)}
                              className="btn btn-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        )}
                        <label className="control-label">
                          Task Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          placeholder="Task Name"
                          className="form-control"
                          name="taskName"
                          value={item.taskName}
                          onChange={(e) => handlechange(e, index)}
                        />
                        <div className="row">
                          {/* <div className="col-6 mt-2 mb-2">
                            <label className="control-label">
                              Assign Date <span className="text-danger">*</span>
                            </label>
                            <input
                              required
                              placeholder="Assign Date"
                              type="date"
                              className="form-control"
                              name="assignDateAndTime"
                              value={item.assignDateAndTime}
                              onChange={(e) => handlechange(e, index)}
                            />
                          </div> */}
                          <div className="col-6 mt-2 mb-2">
                          <label className="control-label">
                              Expected hours{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <br />
                          <select
                            className="form-control"
                            name="expectedhours"
                            value={item.expectedhours}
                            onChange={(e) => handlechange(e, index)}
                            required
                          >
                            <option value="">Expected hours...</option>
                            <option value="00 hour">00 hour</option>
                              <option value="01 hour">01 hour</option>
                              <option value="02 hour">02 hour</option>
                              <option value="03 hour">03 hour</option>
                              <option value="04 hour">04 hour</option>
                              <option value="05 hour">05 hour</option>
                              <option value="06 hour">06 hour</option>
                              <option value="07 hour">07 hour</option>
                              <option value="08 hour">08 hour</option>
                              <option value="09 hour">09 hour</option>
                              <option value="10 hour">10 hour</option>
                              <option value="11 hour">11 hour</option>
                              <option value="12 hour">12 hour</option>
                              <option value="13 hour">13 hour</option>
                             
                          </select>
                          </div>
                          <div className="col-6 mt-2 mb-2">
                          <label className="control-label">
                              Expected minutes{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <br />
                          <select
                            className="form-control"
                            name="expectedminutes"
                            value={item.expectedminutes}
                            onChange={(e) => handlechange(e, index)}
                            required
                          >
                            <option value="">Expected minutes...</option>
                            <option value="00 minute">00 minute </option>
                              <option value="05 minute">05 minute </option>
                              <option value="10 minute">10 minute </option>
                              <option value="15 minute">15 minute </option>
                              <option value="20 minute">20 minute </option>
                              <option value="25 minute">25 minute </option>
                              <option value="30 minute">30 minute </option>
                              <option value="35 minute">35 minute </option>
                              <option value="40 minute">40 minute </option>
                              <option value="45 minute">45 minute </option>
                              <option value="50 minute">50 minute </option>
                              <option value="55 minute">55 minute </option>
                          </select>
                          </div>
                         
                        </div>
                        <label className="control-label">
                          Stories <span className="text-danger">*</span>
                        </label>
                        <textarea
                          placeholder="Stories"
                          required
                          className="form-control"
                          name="description"
                          value={item.description}
                          onChange={(e) => handlechange(e, index)}
                        />
                      </div>
                    ))}
                    <button
                      type="primary"
                      className="btn btn-primary mt-1 ms-0"
                      htmlType="submit"
                    >
                      Submit
                    </button>
                 

                    <button
                      className="btn btn-success mt-1"
                      onClick={(e) => addmore()}
                      style={{ float: "right" }}
                      type="button"
                    >
                      <FiPlus className="mr-2 " />
                      Add More
                    </button>
                  </form>
                  {/*Work here for more */}
                </Spin>
              </div>
          </div>
    </div>
    </Layout>
  );
}
