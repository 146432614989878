import React, { forwardRef, useState } from "react"; 
import SectionHeader from "../../components/global/SectionHeader";
import { Spin, Tooltip, Button } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useNavigate } from "react-router";
import Layout from "../../components/global/layout";

export default function ComplaintList() {
  const [loading, setloading] = useState(false);
  const [array, setarray] = useState([]);
  const history = useNavigate();

  const renderSubmit = () => {
    history("/addedit/complaints");
  };
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          localStorage.setItem("projectId", items.id);
          return {
            ...items,
          };
        })
      : [];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      field: "name",
    },
    {
      title: "Start-Date",
      dataIndex: "startDate",
      field: "startDate",
    },
    {
      title: "End-Date",
      dataIndex: "endDate",
      field: "endDate",
    },
    {
      title: "Client-Name",
      dataIndex: "clientName",
      field: "clientName",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      field: "actions",
    },
  ];
  return (
    <Layout>
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" /> 
      </div>

      <div className="main-content">
        <section className="section">
          <SectionHeader title="System Complaints" />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="System Complaints">
              <Button
                type="primary"
                onClick={renderSubmit}
                shape="circle"
                icon={<PlusSquareOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>
          <Spin spinning={loading}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
              }}
              columns={columns}
              data={newlyAddedChapter}
              icons={tableIcons}
            />
          </Spin>
        </section>
      </div>
    </div>
    </Layout>
  );
}
