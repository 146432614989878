import React, { useEffect, useState } from "react"; 
import SectionHeader from "../../components/global/SectionHeader";
import { Spin, Tooltip, notification, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { Formik } from "formik";
import ApiClient from "../../api/apiClient";
import Layout from "../../components/global/layout";

export default function AddEditComplaints() {
  const [loading, setloading] = useState(false);
  const [editdata, seteditdata] = useState([]);
  const { id } = useParams();
  const history = useNavigate();
  const renderSubmit = () => {
    history(-1);
  };
  // For getting the edit data thats we need for now
  const geteditdata = () => {
    ApiClient.get(``)
      .then((res) => {
        seteditdata(res.data);
      })
      .catch((err) => {
        notification.error({ message: "Some error Occurred.Please try again" });
      });
  };
  useEffect(() => {
    if (id) {
      geteditdata();
    }
  }, []);
  return (
    <Layout>
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
      </div>

      <div className="main-content">
        <section className="section">
          <SectionHeader title={`${!id ? "Add" : "Edit"} System Complaint`} />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Back Button">
              <Button
                type="primary"
                onClick={renderSubmit}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>
          <Spin spinning={loading}>
            <div className="shadow pl-3">
              <div className="p-4">
                <Formik
                  enableReinitialize
                  initialValues={{
                    devicename: "",
                    description: "",
                  }}
                  onSubmit={(values) => {
                    alert(JSON.stringify(values));
                  }}
                >
                  {({
                    values,
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <label>
                        Device Name<span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        className="form-control"
                        name="devicename"
                        placeholder="Device Name"
                        value={values.devicename}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      <label>
                        Description<span className="text-danger">*</span>
                      </label>
                      <textarea
                        required
                        className="form-control"
                        name="description"
                        placeholder="Description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      <Button
                        type="primary"
                        className="mt-3 ml-3"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
    </Layout>
  );
}
