import React, { useState, useEffect, forwardRef } from "react";
import { Spin, Tooltip } from "antd";
import { useDispatch } from "react-redux";

import SectionHeader from "../../components/global/SectionHeader";
import { API_URL } from "../../environment";
import { ALLLEAVES_DATA } from "../../redux/Store/constants";
import { useNavigate, Link } from "react-router-dom";
import "../leaves/Leaveform.css";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSidebar } from "../../components/provider/Provider";
import moment from "moment";
import Layout from "../../components/global/layout";
const Announcement = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);
  const { setCourseId } = useSidebar();

  useEffect(() => {
    userDetails();
  }, []);
  const userDetails = () => {
    setLoading(true);
    fetch(`${API_URL}announcementListing?page=1&count=10`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res && res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: ALLLEAVES_DATA,
            payload: res,
          });
        }
        if (res.code === 401) {
          localStorage.clear();
          history("/");
        }
        setLoading(false);
      });
  };
  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("appliedlives", id);
  };
  const columns = [
    {
      title: "Tittle",
      dataIndex: "subject",
      field: "subject",
      render: (text, record) => (
        <>
          {text && text.subject ? (
            <Link to={`/announcementDetails?id=${text._id}`} className="text">
              <Tooltip title="View">
                <span
                  className="text-capitalize"
                  onClick={() => chapterIdHandle(text.id)}
                >
                  {text.subject}
                </span>
              </Tooltip>
            </Link>
          ) : (
            "--"
          )}
        </>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      field: "description",
      render: (text, record) => {
        const cleanDescription =
          text && text.description
            ? text.description.replace(/<[^>]+>/g, "")
            : "";
        const truncatedDescription = cleanDescription
          .split(" ")
          .slice(0, 10)
          .join(" ");
        const displayDescription =
          cleanDescription.length > 10
            ? truncatedDescription + "..."
            : truncatedDescription;

        return (
          <>
            {cleanDescription ? (
              <span
                className="text-capitalize"
                onClick={() => chapterIdHandle(text.id)}
              >
                {displayDescription}
              </span>
            ) : (
              "--"
            )}
          </>
        );
      },
    },

    {
      title: " Date",
      dataIndex: "date",
      field: "date",
      render: (text, record) => (
        <>{moment(text && text.date).format("DD MMM YYYY")}</>
      ),
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          localStorage.setItem("rolesid", items.id);
          return {
            ...items,
          };
        })
      : [];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
    <div>
      
      <div className="main-content">
        <div className="headings">
        <SectionHeader title="Announcements List " />
        </div>
        <div className="section">
          
          <Spin spinning={loading}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
              }}
              columns={columns}
              data={newlyAddedChapter}
              icons={tableIcons}
            />
          </Spin>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Announcement;
