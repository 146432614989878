import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getResetPassword } from "../../../redux/Actions/ActionFunctions";
import { RESET_PASSWORD } from "../../../redux/Store/constants";
import { API_URL } from "../../../environment";
import { Input, notification } from "antd";
import { Spin, message } from "antd";
import { FiEye } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa6";
import Layout from "../../../components/global/layout";

const ResetPassword = (p) => {
  const navigate = useNavigate();

  if (localStorage.token) {
    navigate("/");
  }
  const dispatch = useDispatch();
  const [code, setcode] = useState();
  const [loading, setloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newPassword, setPassword] = useState();
  const [confirmPassword, setconfirmpassword] = useState();

  const ResetPass = (e) => {
    e?.preventDefault();
    navigate("/");
    if(newPassword!=confirmPassword){
      notification.error({
        message:"New Password & Confirm Password Not Match"
      })
      return
    }
    const data = {
      code,
      newPassword,
      confirmPassword,
    };
    dispatch(getResetPassword(data))
    setloading(true)
    fetch(`${API_URL}resetPassword`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        setloading(false)
        if (res.success) {
          message.success({
            content: 'Password Reset Successfully',
            style: {
              position:"absolute",
              right:"10px",
              top:0
            },
          });
          navigate('/')
          dispatch({
            type: RESET_PASSWORD,
            payload: res
          })

        } else {
          notification.error({
            message:"Wrong code"
          })
        }
      });
  };
  const [confirmpassword, setConfirmpassword] = useState(false);
  const [newpassword, setNewpassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const Previous = () => {
    navigate("/");
  };
  return (
    // <Layout>
      <div className="wrapperauth">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center ">
              <div className="">
                <img src="/img/logo1.png" className="logolog" alt="logo" />
                <p className="text-md mt-4">Let's be great together. </p>
              </div>
              <div className=""></div>
            </div>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center">
              <div className="forms_data">
                <form
                  onSubmit={(e) => {
                    ResetPass(e);
                  }}
                  className="w-100"
                >
                  <div className="forms_logins mb-4">
                    <h1 className="text-xl m-0">Forgot password</h1>
                    <p>
                      Reset your password to continue accessing your dashboard.
                    </p>
                  </div>

                  <div className="main_inners">
                    <div className="form-group">
                      <label>Code</label>
                      <input
                        value={code}
                        onChange={(e) => setcode(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="Code"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>New Password</label>
                      <div className="position-relative">
                        <input
                          // type="newpassword"
                          type={newpassword ? "text" : "password"}
                          name="newpassword"
                          className="form-control"
                          placeholder="Enter New Password"
                          minlength="8"
                          maxlength="20"
                          required="required"
                          value={newPassword}
                          onChange={(e) => setPassword(e.target.value)}
                          type={newpassword ? "text" : "password"}
                        />
                        <div
                          className="password_eye"
                          onClick={() => setNewpassword(!newpassword)}
                        >
                          {newpassword ? <FaRegEyeSlash /> : <FiEye />}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Confrim Password</label>
                      <div className="position-relative">
                        <input
                          type={confirmpassword ? "text" : "password"}
                          name="confirmpassword"
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          required="required"
                          minlength="8"
                          maxlength="20"
                          value={confirmPassword}
                          onChange={(e) => setconfirmpassword(e.target.value)}
                        />
                        <div
                          className="password_eye"
                          onClick={() => setConfirmpassword(!confirmpassword)}
                        >
                          {confirmpassword ? <FaRegEyeSlash /> : <FiEye />}
                        </div>
                      </div>
                    </div>

                    <div className=" submit_btncls mt-3">
                      <button
                        className="btn btn-primary w-100 shadow-btn h-45"
                        type="submit"
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <>
                          Submit
                          <div
                            className="loader"
                            style={{ display: "none" }}
                          ></div>
                          </>
                        )}
                      </button>
                    </div>

                    <div className="text-center">
                      <p className=" text-xs pointer mt-2" onClick={Previous}>
                        Back to Sign in
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </Layout>
  );
};

export default ResetPassword;
