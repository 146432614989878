import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SectionHeader from "../../components/global/SectionHeader";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import { getProjectdetails } from "../../api/user/userapi";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const ProjectDetails = (props) => {
  const [loading, setloading] = useState(false);
  const [names, setName] = useState();
  const [desc, setDescription] = useState();
  const [frontTech, setFrontTechnology] = useState();
  const [backTech, setBackTech] = useState();
  const [clientNames, setClientName] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [time, settime] = useState();
  const [dates, setDate] = useState();
  const [docs, setDocs] = useState();

  const [frontendclonelink, setfrontendclonelink] = useState();
  const [frontendclonepassword, setfrontendclonepassword] = useState();
  const [adminclonelink, setadminclonelink] = useState();
  const [adminclonepassword, setadminclonepassword] = useState();
  const [productionurl, setproductionurl] = useState();
  const [backendStagingWebsiteUrl, setbackendStagingWebsiteUrl] = useState();
  const [frontendStagingWebsiteUrl, setfrontendStagingWebsiteUrl] = useState();
  const [apiclonelink, setapiclonelink] = useState();
  const [apiclonepassword, setapiclonepassword] = useState();
  const [frontendownerid, setfrontendownerid] = useState();
  const [adminownerid, setadminownerid] = useState();
  const [apiownerid, setapiownerid] = useState();
  const [apibranch, setapibranch] = useState();
  const [adminbranch, setadminbranch] = useState();
  const [frontendbranch, setfrontendbranch] = useState();

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const naviagte = useNavigate();

  const handleteam = () => {
    naviagte(`/projectassociation/list/${id}`);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getProject();
  }, []);
  const [enddate, setenddate] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const getProject = async (i) => {
    setloading(true);
    const Id = localStorage.getItem("courseid");
    try {
      const resp = await getProjectdetails(id);
      setloading(false);
      if (resp && resp.status === 200) {
        const list = resp;
        setName(list.data.data.name);
        setDescription(list.data.data.description);
        setFrontTechnology(list.data.data.frontendtechnology);
        setBackTech(list.data.data.backendtechnology);
        setClientName(list.data.data.clientName);
        setStatus(list.data.data.progress_status);
        setType(list.data.data.project_type);
        setDate(list.data.data.startDate);
        setDocs(list.data.data.documents);
        settime(list.data.data.totaltime);
        setfrontendclonelink(list.data.data.frontend_clone_link);
        setfrontendclonepassword(list.data.data.frontend_clone_password);
        setadminclonelink(list.data.data.admin_clone_link);
        setadminclonepassword(list.data.data.admin_clone_password);
        setproductionurl(list.data.data.productionWebsiteUrl);
        setbackendStagingWebsiteUrl(list.data.data.backendStagingWebsiteUrl);
        setfrontendStagingWebsiteUrl(list.data.data.frontendStagingWebsiteUrl);
        setstagingurl(list.data.data.backendStagingWebsiteUrl);
        setapiclonelink(list.data.data.backend_clone_link);
        setapiclonepassword(list.data.data.backend_clone_password);
        setfrontendownerid(list.data.data.frontend_owner_workspace_id);
        setadminownerid(list.data.data.admin_owner_workspace_id);
        setapiownerid(list.data.data.api_owner_workspace_id);
        setadminbranch(list.data.data.updated_branch_admin);
        setfrontendbranch(list.data.data.updated_branch_frontend);
        setenddate(list.data.data.endDate);
        setapibranch(list.data.data.updated_branch_api);
      } else {
        setloading(false);
      }
      setloading(false);
    } catch (e) {
      setloading(false);
    }
  };
  const renderSubmitBack = () => {
    navigate("/allProject");
  };
  return (
    <Layout>
      <div className="">
        <div className="main-content">
          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p
                  onClick={renderSubmitBack}
                  className="pointer text-white  fs-3"
                >
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Project Detail" />
            </div>
          </div>

          <div className="section ">
            <Spin spinning={loading}>
              <div className="">
                <div className=" ">
                  <h3 className="text-center mb-3text-capitalize">
                    Project Name:-{names}
                  </h3>
                  <div class="row mt-3">
                    {/* <div class="col-md-4">
                  <label className="text-bold">Project-Name:</label>
                  <span > {names}</span>
                </div> */}
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Frontend-Technology:</label>
                      <span> {frontTech}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Backend-Technology:</label>
                      <span> {backTech}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Client-Name:</label>
                      <span> {clientNames}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Total Time:</label>
                      <span> {time}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Start-Date:</label>
                      <span> {moment(dates).format("DD MMM YYYY")}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">End-Date:</label>
                      <span> {moment(enddate).format("DD MMM YYYY")}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Project-Type:</label>
                      <span> {type}</span>
                    </div>
                    <div class="col-md-6 mt-3 d-inline-flex text-break">
                      <label className="text-bold ">
                        Frontend Owner Workspace Id:
                      </label>
                      <span className="">{frontendownerid}</span>
                    </div>
                    <div class="col-md-6 mt-3 d-inline-flex text-break">
                      <label className="text-bold">
                        Admin Owner Workspace Id:
                      </label>
                      <span className="">{adminownerid}</span>
                    </div>
                    <div class="col-md-6 mt-3 d-inline-flex text-break">
                      <label className="text-bold">
                        API Owner Workspace Id:
                      </label>
                      <span className="">{apiownerid}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">Admin Updated Branch:</label>
                      <span>{adminbranch}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">
                        Frontend Updated Branch:
                      </label>
                      <span>{frontendbranch}</span>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label className="text-bold">API Updated Branch:</label>
                      <span>{apibranch}</span>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="text-bold">Description:</label>
                      <span className="d-flex-inline">
                        {htmlToReactParser.parse(desc)}
                      </span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold">Frontend Clone Link:</label>
                      <span className="">{frontendclonelink}</span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break pl-3">
                      <label className="text-bold d-inline-flex text-break">
                        Frontend App Password:
                      </label>
                      <span className="d-inline-flex text-break ">
                        {frontendclonepassword}
                      </span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold">Admin Clone Link:</label>
                      <span className="">{adminclonelink}</span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break pl-3">
                      <label className="text-bold">Admin App Password:</label>
                      <span className="">{adminclonepassword}</span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold d-flex">
                        Production URL :
                      </label>
                      <span className=" d-inline-flex text-break">
                        {productionurl}
                      </span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold d-flex">Frontend Staging URL :</label>
                      <span className=" d-inline-flex text-break">
                        {frontendStagingWebsiteUrl}
                      </span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold d-flex">Backend Staging URL :</label>
                      <span className=" d-inline-flex text-break">
                        {backendStagingWebsiteUrl}
                      </span>
                    </div>
                    <div class="col-md-12 mt-3 d-inline-flex text-break">
                      <label className="text-bold d-flex">
                        API Clone Link:
                      </label>
                      <span className=" d-inline-flex text-break">
                        {apiclonelink}
                      </span>
                    </div>

                    <div class="col-md-12 mt-3 d-inline-flex">
                      <label className="text-bold d-inline-flex text-break ">
                        API App Password:
                      </label>
                      <span className="d-inline-flex text-break ">
                        {apiclonepassword}
                      </span>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    style={{ float: "right", marginTop: "-20px" }}
                    className="pr-2"
                    onClick={() => handleteam()}
                  >
                    Team<i className="fa fa-arrow-right"></i>
                  </Button>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectDetails;
