/* eloadinglint-disable no-lone-blocks */
import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
  forwardRef,
} from "react";
import { Table, Spin, notification } from "antd";
// import Timer from "react-compound-timer";
import { createTimeModel } from 'react-compound-timer';
import Popup from "../Popup";
import { useDispatch } from "react-redux";
import { API_URL } from "../../environment";
import { PUNCH_INTIME, SINGLEUSER_ATTENDENCE } from "../../redux/Store/constants";
import "./Timesheet.css";
import { USER_DETAIL } from "../../redux/Store/constants";
import moment from "moment";
import { useSidebar } from "../provider/Provider";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useNavigate } from "react-router";

// import './styles.css';

const Timesheet1 = ({ start, stop }) => {
  const navigate = useNavigate();
  const [UserStatus, setUserStatus] = useState(false);
  const Email = localStorage.getItem("Email");
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [value, setvalue] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [newdate, setnewDate] = useState();

  const [selecteddate, setselecteddate] = useState();
  const [Attendencedata, setAttendencedata] = useState([]);
  const [btntogglein, setBtntogglein] = useState(true);
  const [btntoggleout, setBtntoggleout] = useState(true);
  const [punchoutTime, setPunchoutTime] = useState();
  const [Todaydate, setTodaydate] = useState();
  const [checkinTime, setCheckinTime] = useState(null);
  const [timer, setTimer] = useState(0);
  const [Id, setId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const increment = useRef(null);
  const [Userdetail, setUserdetail] = useState();
  const [Imageprofile, setImageprofile] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [fullname, setfullname] = useState();
  const [email, setemail] = useState();
  const [employeeId, setemployeeId] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();
  const [dateOfRelieving, setDateOfRelieving] = useState();
  const [dateOfResignation, setDateOfResignation] = useState();
  const [designation, setDesignation] = useState();
  const [dob, setDOB] = useState();
  const [facebookURL, setFacebookURL] = useState();
  const [fatherName, setFatherName] = useState();
  const [instagramURL, setInstagramURL] = useState();
  const [linkedinURL, setLinkedinURL] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [motherName, setMotherName] = useState();
  const [primaryAddress, setPrimaryAddress] = useState();
  const [primaryAddress1, setPrimaryAddress1] = useState();
  const [primaryCity, setPrimaryCity] = useState();
  const [primaryCountry, setPrimaryCountry] = useState();
  const [primaryState, setPrimaryState] = useState();
  const [primaryZipcode, setPrimaryZipcode] = useState();
  const [role, setRole] = useState();
  const [secondaryAddress, setSecondaryAddress] = useState();
  const [secondaryAddress1, setSecondaryAddress1] = useState();
  const [secondaryEmail, setSecondaryEmail] = useState();
  const [gender, setGender] = useState();
  const [checkInstatus, setcheckInstatus] = useState();
  const [singleFile, setSingleFile] = useState(null);
  const [img, setImg] = useState();
  const [seconds, setSeconds] = useState(0);
  const [totalTime, setTotalTime] = useState();
  const [isActive1, setIsActive1] = useState(true);
  const userid = localStorage.getItem("USERID");
  let uniqueid = [];
  uniqueid =
    checkinTime &&
    checkinTime.map((fetchid, id) => {
      return fetchid.id;
    });
  const element = <i className="fa fa-clock" />;
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { setCourseId, setChaptersId, courseId } = useSidebar();
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  //tracker
  const Timetracker = localStorage.getItem("Trackertime");
  const [timers, setTimers] = useState(Number(Timetracker) || 0);
  // localStorage.setItem("Trackertime", timers);
  const [starts, setStarts] = useState(false);
  const firstStart = useRef(true);
  const tick = useRef();

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (starts) {
      tick.current = setInterval(() => {
        setTimers((timers) => timers + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }
    return () => clearInterval(tick.current);
  }, [starts]);

  const toggleStart = () => {
    setStarts(!starts);
  };

  const CheckInuser = localStorage.getItem("LastCheckInTime");

  const dispSecondsAsMins = (seconds) => {
    const totalMinutes = Math.floor(seconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds_ = seconds % 60;
    return (
      (hours == 0 ? "00" : hours.toString()) +
      ":" +
      (minutes == 0 ? "00" : minutes.toString()) +
      ":" +
      (seconds_ == 0 ? "00" : seconds_.toString())
    );
  };

  const showout = (data) => {
    setToggle(true);
    clearInterval(increment.current);
    let dt = new Date();
    const finaldate = convert(dt);
    // document.getElementById("trackerstop").click()
  };

  const checkintable = (data) => {
    const dt = new Date();
    const finaldate = convert(dt);
    fetch(`${API_URL}attendance/single/user?userID=${userid}`, {
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCheckinTime(res.data);
        setLoading(false);
        if (res) {
          dispatch({
            type: PUNCH_INTIME,
            payload: res,
          });
        } else {
          setLoading(false);
          const errorMsg =
            res.error && res.error.message
              ? res.error.message
              : "Something went wrong. Kindly try again later !!!";
          notification.error({ message: errorMsg });
        }
      });
  };

  const getLocalStorage = () => {
    const UserPunchIn = localStorage.getItem("PunchIn");
    setUserStatus(UserPunchIn);
  };
  useEffect(() => {
    getLocalStorage();
  });
  const showtin = (data, id) => {
    setLoading(true);
    setBtntoggleout(true);
    setBtntogglein(true);
    start();
    setIsActive(true);
    setIsPaused(true); 
    start();
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
    const device = localStorage.getItem("device");
    const newdata = JSON.stringify({ device: device });
    try {
      fetch(`${API_URL}attendance/checkin`, {
        method: "POST",
        body: newdata,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token && token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          checkintable();
          if (res.alreadyCheckedIn == true) {
            notification.error({
              message: "You are already Punched In from Somewhere",
            });
          } else {
            notification.success({
              message: "Punch In Successfully",
            });
            // message.success({
            //   content: 'Punch In Successfully',
            //   style: {
            //     marginTop: '20vh',
            //   },
            // });
            setId(res.data.id);
            localStorage.setItem("attId", res.data.id);
            localStorage.setItem("CheckInId", res.checkInId);
          }

          // self.location['reload']()

          if (res) {
            localStorage.setItem("PunchIn", true);
            getLocalStorage();
            dispatch({
              type: PUNCH_INTIME,
              payload: res,
            });
          } else {
            setTimeout(() => {
              notification.error({
                message: "Login",
                description: "User login failed.",
              });
            }, 1000);
          }
        });
    } catch (error) {
      setLoading(false);
      dispatch({
        type: PUNCH_INTIME,
        payload: {
          data: null,
          error,
        },
      });
    }
    // dispatch(punchintime())
    checkintable();
  };
  const formatTime = useCallback(() => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  }, []);
  const [id1, setId1] = useState();
  const columns = [
    {
      title: "Punch-In Date",
      dataIndex: "checkIn",
      field: "checkIn",
      render: (text, record) => (
        <>{moment(text.checkIn).format("YYYY-MM-DD")}</>
      ),
    },
    {
      title: "Punch in time",
      dataIndex: "checkIn",
      // dataIndex: `${moment("checkIn").utc().format('HH:mm:ss z')}`,
      field: "checkIn",
      render: (text, record) => (
        <>{moment(text.checkIn).format("hh:mm:ss:A")}</>
      ),
    },
    {
      title: "Punchout time",
      dataIndex: "checkOut",
      field: "checkOut",
      render: (text, record) => (
        <>
          {text.checkOut === ""
            ? "----"
            : moment(text.checkOut).format("hh:mm:ss:A")}
        </>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      field: "reason",
      render: (text, record) => <>{text.reason}</>,
    },
    {
      title: "Time",
      dataIndex: "totalTime",
      field: "totalTime",
    },
  ];

  const newlyAddedChapter =
    checkinTime && checkinTime.length
      ? checkinTime.map((items) => {
          setCourseId(items.id);
          var dates = new Date(items.checkIn);
          const newDate = new Date(dates.toString());
          const slecteddate = newDate.getHours() + ":" + newDate.getMinutes();
          return {
            ...items,
          };
        })
      : [];
  useEffect((data) => {
    let dt = new Date();
    const finaldate = convert(dt);
    // showout();
    fetch(`${API_URL}attendance/single/user?userID=${userid}&count=1000`, {
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setAttendencedata(res.data);
        if (res) {
          dispatch({
            type: SINGLEUSER_ATTENDENCE,
            payload: res,
          });
        }
        if (res.code == 401) {
          localStorage.clear();
          navigate("/");
          setTimeout(() => {
            notification.error({
              message: "Unauthorized",
              description: "Your Session Has Expired. Please logIn.",
            });
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    userDetails();
    checkintable();
  }, [totalTime]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const userDetails = () => {
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setcheckInstatus(res.data && res.data.checkInstatus);
        setUserdetail(res.data);
        setFirstName(res.data && res.data.firstName);
        setemail(res.data && res.data.email);
        setfullname(res.data && res.data.fullName);
        setlastName(res.data && res.data.lastName);
        setGender(res.data && res.data.gender);
        setemployeeId(res.data && res.data.employeeId);
        setContactNumber(res.data && res.data.contactNumber);
        setDateOfJoining(res.data && res.data.dateOfJoining);
        setDateOfRelieving(res.data && res.data.dateOfRelieving);
        setDateOfResignation(res.data && res.data.dateOfResignation);
        setDesignation(res.data && res.data.designation);
        setDOB(res.data && res.data.dob);
        setFacebookURL(res.data && res.data.facebookURL);
        setFatherName(res.data && res.data.fatherName);
        setInstagramURL(res.data && res.data.instagramURL);
        setLinkedinURL(res.data && res.data.linkedinURL);
        setMobileNo(res.data && res.data.mobileNo);
        setMotherName(res.data && res.data.motherName);
        setPrimaryAddress(res.data && res.data.primaryAddress);
        setPrimaryAddress1(res.data && res.data.primaryAddress1);
        setPrimaryCity(res.data && res.data.primaryCity);
        setPrimaryCountry(res.data && res.data.primaryCountry);
        setPrimaryState(res.data && res.data.primaryState);
        setPrimaryZipcode(res.data && res.data.primaryZipcode);
        setRole(res.data && res.data.role);
        setSecondaryAddress(res.data && res.data.secondaryAddress);
        setSecondaryAddress1(res.data && res.data.secondaryAddress1);
        setSecondaryEmail(res.data && res.data.secondaryEmail);
        setImg(res.data && res.data.image);
        setTotalTime(res.data && res.data.totalIntime);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          }); 
        } else { 
        }
      });
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>
      <div id="cardpunch" className="card punch-status mrb-15">
        <div id="carddd" className="card-body">
          <div className="app">
            <Spin spinning={loading}> 
              <div className="stopwatch-card"> 
                <div class="punch_section">
                  <div className="buttons">
                    {UserStatus == "false" ? (
                      <button
                        id="buttons"
                        type="button"
                        onClick={() => showtin()}
                        style={{
                          backgroundColor: "#4CAF50",
                          border: "none",
                          color: "white",
                          padding: "4px 11px",
                          textAlign: "center",
                          textDecoration: "none",
                          display: "inline-block",
                          fontSize: "16px",
                          margin: "1px 2px",
                          cursor: "pointer",
                        }}
                      >
                        Punch in
                      </button>
                    ) : (
                      <button
                        id="buttons"
                        type="button"
                        onClick={() => showout()}
                        style={{
                          backgroundColor: "#f44336",
                          border: "none",
                          color: "white",
                          padding: "4px 11px",
                          textAlign: "center",
                          textDecoration: "none",
                          display: "inline-block",
                          fontSize: "16px",
                          margin: "1px 2px",
                          cursor: "pointer",
                          borderRadius: "3px",
                        }}
                      >
                        Punchout
                      </button>
                    )}
                  </div>
                  <br></br>
                  {/* <div className={Timer}> */}
                    {UserStatus == "true" ? (
                      <h6 className="heading_punch">
                        Please PunchOut Before Leave
                      </h6>
                    ) : (
                      <h6>Please Punch In</h6>
                    )}
                  </div>
                {/* </div> */}

                <br></br>
                <br></br>
                {checkInstatus === "true" ? (
                  <MaterialTable
                    options={{
                      showTitle: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30, 40, 50],
                      emptyRowsWhenPaging: false,
                    }} 
                    columns={columns}
                    data={newlyAddedChapter}
                    icons={tableIcons}
                  />
                ) : ( 
                  <MaterialTable
                    options={{
                      showTitle: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30, 40, 50],
                      emptyRowsWhenPaging: false,
                    }} 
                    columns={columns}
                    data={newlyAddedChapter}
                    icons={tableIcons}
                  />
                )}
                <p>{punchoutTime}</p>
              </div> 
            </Spin>
          </div>
        </div>
      </div>
      <Popup
        setLoading={setLoading}
        setToggle={setToggle}
        getLocalStorage={getLocalStorage}
        userDetails={userDetails}
        toggle={toggle}
        id={Id}
        checkinTime={checkinTime}
        checkintable={checkintable}
      />
    </>
  );
};

export default memo(Timesheet1);
