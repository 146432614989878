import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./Profile.css";
import { USER_DETAIL } from "../../../redux/Store/constants";
import { Input, Spin, Tooltip } from "antd";
import { Formik } from "formik";
import { notification } from "antd";
import { Link } from "react-router-dom";
import * as Yup from "yup";
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../environment";
import { edtiProfile } from "../../../redux/Actions/ActionFunctions";
import SectionHeader from "../../../components/global/SectionHeader";
import Layout from "../../../components/global/layout";
import { FiEdit3 } from "react-icons/fi";

const Myprofile = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const dispatch = useDispatch();
  const userid = localStorage.getItem("USERID");
  const [disabled, setDisabled] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [fullname, setfullname] = useState();
  const [email, setemail] = useState();
  const [employeeId, setemployeeId] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();
  const [dateOfRelieving, setDateOfRelieving] = useState();
  const [dateOfResignation, setDateOfResignation] = useState();
  const [designation, setDesignation] = useState();
  const [dob, setDOB] = useState();
  const [fatherName, setFatherName] = useState();
  const [linkedinURL, setLinkedinURL] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [motherName, setMotherName] = useState();
  const [primaryAddress, setPrimaryAddress] = useState();
  const [primaryAddress1, setPrimaryAddress1] = useState();
  const [primaryCity, setPrimaryCity] = useState();
  const [primaryCountry, setPrimaryCountry] = useState();
  const [primaryState, setPrimaryState] = useState();
  const [primaryZipcode, setPrimaryZipcode] = useState();
  const [role, setRole] = useState();
  const [secondaryAddress, setSecondaryAddress] = useState();
  const [secondaryAddress1, setSecondaryAddress1] = useState();
  const [secondaryEmail, setSecondaryEmail] = useState();
  const [gender, setGender] = useState();
  const [images, setImages] = useState([]);
  const [img, setImg] = useState();
  const [loading, setloading] = useState(false);
  localStorage.setItem("Fullname", fullname);

  useEffect(() => {
    userDetails();
  }, [token]);

  const userDetails = () => {
    setloading(true);
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setloading(false);
        setFirstName(res && res.data && res.data.firstName);
        setemail(res && res.data && res.data.email);
        setfullname(res && res.data && res.data.fullName);
        setlastName(res && res.data && res.data.lastName);
        setGender(res && res.data && res.data.gender);
        setemployeeId(res && res.data && res.data.employeeId);
        setContactNumber(res && res.data && res.data.contactNumber);
        setDateOfJoining(res && res.data && res.data.dateOfJoining);
        setDateOfRelieving(res && res.data && res.data.dateOfRelieving);
        setDateOfResignation(res && res.data && res.data.dateOfResignation);
        setDesignation(res && res.data && res.data.designation);
        setDOB(res && res.data && res.data.dob);
        setFatherName(res && res.data && res.data.fatherName);
        setLinkedinURL(res && res.data && res.data.linkedinURL);
        setMobileNo(res && res.data && res.data.mobileNo);
        setMotherName(res && res.data && res.data.motherName);
        setPrimaryAddress(res && res.data && res.data.primaryAddress);
        setPrimaryAddress1(res && res.data && res.data.primaryAddress1);
        setPrimaryCity(res && res.data && res.data.primaryCity);
        setPrimaryCountry(res && res.data && res.data.primaryCountry);
        setPrimaryState(res && res.data && res.data.primaryState);
        setPrimaryZipcode(res && res.data && res.data.primaryZipcode);
        setRole(res && res.data && res.data.role);
        setSecondaryAddress(res && res.data && res.data.secondaryAddress);
        setSecondaryAddress1(res && res.data && res.data.secondaryAddress1);
        setSecondaryEmail(res && res.data && res.data.secondaryEmail);
        setImg(res && res.data && res.data.image);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
        } else {
        }
      });
  };
  const key = "updatable";
  const handleSubmit = () => {
    setDisabled(!disabled);
    notification.open({
      key,
      description: "Profile has been Updated",
    });
    const payload = {
      userid,
      firstName,
      lastName,
      fullname,
      email,
      gender,
      employeeId,
      contactNumber,
      dateOfJoining,
      dateOfRelieving,
      dateOfResignation,
      designation,
      dob,
      fatherName,
      linkedinURL,
      mobileNo,
      motherName,
      primaryAddress,
      primaryAddress1,
      primaryCity,
      primaryCountry,
      primaryState,
      primaryZipcode,
      role,
      secondaryAddress,
      secondaryAddress1,
      secondaryEmail,
      image: images,
    };
    dispatch(edtiProfile(payload));
  };

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    employeeId: Yup.number()
      .min(6, "employeeId must be at least 6 charaters")
      .max(12, "employeeId dont maximum 12 charaters")
      .required("employeeId is required"),
  });
  const handleInputChange = async (event) => {
    let file = event.target.files[0];
    if (file != null) {
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      let res = await fetch(
        `${API_URL}user/uploadProfileImage`,
        {
          method: "post",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let responseJson = await res.json();
      if (responseJson) {
        setImages(API_URL + responseJson.data.imagePath);
        setImg(API_URL + responseJson.data.imagePath);
      }
    } else {
      alert("Please Select File first");
    }
  };
  const standby = () => {
    document.getElementById("profileimage").src = "userimg.jpg";
  };

  return (
    <Layout>


      <div className="main-content">
        <div class="profile_section_name">
          <div className="mb-5">
            <h5 className="main-heading">My Profile</h5>
          </div>
          <div className="section">




            <div className="card">
              <Link to={"/editprofile"}>
                <Tooltip title="Edit Profile">
                  <button className="btn btn-primary profile rounded-circle">
                    <FiEdit3 />
                  </button>
                </Tooltip>
              </Link>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-3">
                        <div className="img">
                          {img ? (
                            <img
                              src={img}
                              alt="profile"
                              id="profileimage"
                              onError={standby}
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src="assets/img/avatar/avatar-4.png"
                              alt="profile"
                              className="img-fluid"
                            />
                          )}

                        </div>
                      </div>
                      <div className="col-12 col-md-8 col-lg-9 ">
                        <div className="dtls_profile ps-2">
                          <h4 className="names">{firstName} {lastName}</h4>
                          <p className="design">{designation}</p>
                          <p className="roles">{role}</p>
                          <div className="mt-3">
                            {/* <p className="empid">Employee ID :</p> */}
                            <p className="datejoin">Date of Join : <span>{dateOfJoining}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="details_pros">
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Email : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{email}</p>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Date of Birth : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{dob}</p>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Gender : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{gender == 'M' ? 'Male' : gender == 'F' ? 'Female' : gender == 'O' ? 'Other' : 'Other'}</p>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Mobile No : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{mobileNo} , {contactNumber}</p>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Father Name : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{fatherName}</p>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="laels_left">
                          <p>Mother Name : </p>
                        </div>
                        <div className="rights_texts">
                          <p>{motherName}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <div className="tabs_alls">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="address-tab" data-bs-toggle="tab" data-bs-target="#address" type="button" role="tab" aria-controls="address" aria-selected="true">Address</button>
                    {/* <button class="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">Projects</button> */}
                  </div>
                </nav>

              </div>
            </div>

            <div class="tab-content mt-4" id="nav-tabContent">
              <div class="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="card">
                    <div className="card-header">
                          <h4 className="card-title">Address</h4>
                        </div>
                      <div className="card-body">
                      
                        <div className="">
                          <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>Address : </p>
                            </div>
                            <div className="rights_texts">
                              <p>{primaryAddress1}</p>
                            </div>
                          </div>
                          <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>State : </p>
                            </div>
                            <div className="rights_texts">
                              <p>{primaryState}</p>
                            </div>
                          </div>
                          <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>Zipcode : </p>
                            </div>
                            <div className="rights_texts">
                              <p>{primaryZipcode}</p>
                            </div>
                          </div>
                         
                        
                         

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6">
                    <div className="card">
                    <div className="card-header">
                          <h4 className="card-title">Emergency Contact</h4>
                        </div>
                      <div className="card-body">
                       
                        <div className="">
                        <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>Name : </p>
                            </div>
                            <div className="rights_texts">
                              <p>John Doe</p>
                            </div>
                          </div>
                          <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>Relation : </p>
                            </div>
                            <div className="rights_texts">
                              <p>father</p>
                            </div>
                          </div>
                          <div className="d-flex mb-2">
                            <div className="laels_left">
                              <p>Mobile Number : </p>
                            </div>
                            <div className="rights_texts">
                              <p>123456789</p>
                            </div>
                          </div>
                         

                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>

              </div>
              <div class="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
                No Data
              </div>

            </div>




         
          </div>
        </div>
      </div>

    </Layout>
  );
};
export default Myprofile;
