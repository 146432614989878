import { EDIT_PROFILE, LOGIN_USER, LOGOUT_USER, PUNCH_INTIME } from "../Store/constants"

const initialState = {
    login: "",
    logout : "",
    edit : ""
}

const reducerFunctions = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case LOGIN_USER: {
            return {
                ...state, login: payload
            }
        }
        case LOGOUT_USER: {
            return {
                ...state, logout: payload
            }
        }
        case EDIT_PROFILE: {
            return {
                ...state, edit: payload
            }
        }
            case PUNCH_INTIME:{
                return{
                    ...state,pay:payload
                }
            }
        default: {
            return state
        }

    }
}

export default reducerFunctions