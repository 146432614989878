import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/global/SectionHeader"; 
import { Form, Button, Select, Tooltip } from "antd";
import 'antd/dist/reset.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { API_URL } from "../../environment";
import { BsArrowLeftSquare } from "react-icons/bs";

import {
  RESIGNATION_DATA,
  RESIGNATION_FORM,
  USERMAILING_LIST,
} from "../../redux/Store/constants";
import "../leaves/Leaveform.css";
import { Input, notification } from "antd";
import Layout from "../../components/global/layout";
import TextArea from "antd/es/input/TextArea";

const Resignation = () => {
  const dispatch = useDispatch(); 
  let history = useNavigate();
  const Email = localStorage.getItem("Email");
  const token = localStorage.getItem("token"); 
  const [resignationlist, setresignationlist] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);
  const [mailinglist, setMailinglist] = useState([]);
  const [Text, setText] = useState([]);
  const listValues = [];
  resignationlist.map((entry) => {
    listValues.push(entry);
  });
  useEffect(() => {
    mailingList();
  }, []);

  const onFinishFailed = (errorInfo) => {};
  const ResignationData = () => {
    setLoading(true);
    fetch(`${API_URL}allresign?search=${Email}&count=1000`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data); 
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: RESIGNATION_DATA,
            payload: res,
          }); 
        } else { 
        }
        setLoading(false);
      });
  };

  const key = "updatable";
  const onFinish = (values) => { 
    const newemail = values.toEmail;
    const payload = {
      subject: values.subject,
      description: values.description,
      toEmail: newemail.toString(),
    }; 
    fetch(`${API_URL}resign/add`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        history("/resignation");
        ResignationData();
        if (res.success) {
          history("/resignation");
          notification.success({
            message: "Resignation has been Applied.",
          });
          dispatch({
            type: RESIGNATION_FORM,
            payload: res,
          });
        } else {
          notification.error({
            message: "You have Already Applied for Resignation.",
          });
        }
      });
  };
  const mailingList = () => {
    fetch(`${API_URL}user/mailingList?isDeleted=false`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setMailinglist(res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USERMAILING_LIST,
            payload: res,
          });
        } else {
        }
      });
  };

  function handleChange(value) {}
  const { Option } = Select;
  const iconbtn = () => {
    history("/resignation");
  };

  return (
    <Layout>
     
          <div className="main-content">
            <div className="profile_section_name">
              <div className="headings">
              <div className="d-flex align-items-center gap-3">
                  <Tooltip title="Back Button">
                    <p  onClick={iconbtn}  className="pointer text-white  fs-3"  >
                    <BsArrowLeftSquare />

                      </p>
                  </Tooltip>
                  <SectionHeader title="Add Resignation" />
                </div>
               
                
              
              </div>

       


            </div>


            <div>
               
               <div className="container_div">
                 <Form
                   name="basic"
                   initialValues={{ remember: true }}
                   onFinish={onFinish}
                   onFinishFailed={onFinishFailed}
                   autoComplete="off"
                 >
                   <div class="row">
                    <div className="col-12 col-md-6">

                   
                     <div class="form-group ">
                       <label for="inputAddress">
                         To<span class="text-danger">*</span>
                       </label>
                       <Form.Item
                         name="toEmail"
                         rules={[
                           {
                             required: true,
                             message: "Please Select Email!",
                           },
                         ]}
                       >
                         {/* <div className="emailmultiple"  > */}
                         <Select
                           mode="multiple"
                           size="large"
                           style={{ width: "100%" }}
                           placeholder="Select Email"
                           onChange={handleChange}
                           optionLabelProp="label"
                         >
                           {/* {mailinglist.map((item) =>
                         <Option value={item.email} label="" >

                         </Option>
                       )} */}
                           {mailinglist &&
                             mailinglist.map((item) => (
                               <Option key={item.email}>{item.email}</Option>
                             ))}
                           {/* <Option value="sandykr9988@gmail.com" label="sandykr9988@gmail.com">
                                                                 <div className="demo-option-label-item">

                                                                     sandykr9988@gmail.com
                                                                 </div>
                                                             </Option>
                                                             <Option value="gurdeepsinghsingh1997@gmail.com" label="gurdeepsinghsingh1997@gmail.com">
                                                                 <div className="demo-option-label-item">

                                                                     gurdeepsinghsingh1997@gmail.com
                                                                 </div>
                                                             </Option>
                                                             <Option value="nishant@jcsoftwaresolution.com" label="nishant@jcsoftwaresolution.com">
                                                                 <div className="demo-option-label-item">

                                                                     nishant@jcsoftwaresolution.com
                                                                 </div>
                                                             </Option>
                                                             <Option value="shivamg@jcsoftwaresolution.com" label="shivamg@jcsoftwaresolution.com">
                                                                 <div className="demo-option-label-item">
                                                                     shivamg@jcsoftwaresolution.com
                                                                 </div>
                                                             </Option>
                                                             <Option value="shubhams@jcsoftwaresolution.com" label="shubhams@jcsoftwaresolution.com">
                                                                 <div className="demo-option-label-item">
                                                                     shivamg@jcsoftwaresolution.com
                                                                 </div>
                                                             </Option> */}
                         </Select>
                         {/* </div> */}
                       </Form.Item>
                     </div>   
                     </div>

                     <div className="col-12 col-md-6">
                     <div class="form-group ">
                       <label for="inputAddress">
                         Subject<span class="text-danger">*</span>
                       </label>
                       <Form.Item
                         name="subject"
                         rules={[
                           {
                             required: true,
                             message: "Please Enter Subject!",
                           },
                         ]}
                       >
                         <Input size="large" placeholder="subject" />
                       </Form.Item>
                     </div>
                     </div>

                     <div className="col-12 col-md-12 ">
                     <div class="form-group">
                       <label for="inputAddress">
                         Reason<span class="text-danger">*</span>
                       </label>

                       <Form.Item
                         name="description"
                         rules={[
                           {
                             required: true,
                             message: "Please Enter description!",
                           },
                         ]}
                       >
                         <TextArea size="large" placeholder="Add description" />
                       </Form.Item>
                     
                        
                          
                      
                     </div>
                     </div>
                 
                   </div>
               
                  
                   <div className="btn_submit">
                     <Form.Item>
                       <Button
                         type="primary"
                         htmlType="submit"
                         className="login-form-button "
                       >
                         Submit
                       </Button>
                     </Form.Item>
                   </div>
                 </Form>
               </div>
             </div>


          

          
          </div>

    </Layout>
  );
};

export default Resignation;
