import React, { useState, useEffect, forwardRef } from "react";
import { Spin, Button, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import SectionHeader from "../../components/global/SectionHeader";
import { API_URL } from "../../environment";
import { ALLLEAVES_DATA } from "../../redux/Store/constants";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import "./Leaveform.css";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSidebar } from "../../components/provider/Provider";
import moment from "moment";
import Layout from "../../components/global/layout";
import { LuUserCheck2, LuUserX2 } from "react-icons/lu";
import { GoProjectRoadmap } from "react-icons/go";
import { HiOutlineUsers } from "react-icons/hi2";
const Leavelist = (props) => {
  const Email = localStorage.getItem("Email");

  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);
  const { setCourseId } = useSidebar();
  const dispatch = useDispatch();
  const iconbtn = () => {
    navigate("/leavesform");
  };
  const navigate = useNavigate();
  useEffect(() => {
    userDetails();
  }, []);

  const userDetails = () => {
    setLoading(true);
    fetch(`${API_URL}allleaves?search=${Email}&count=1000`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res && res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: ALLLEAVES_DATA,
            payload: res,
          });
        }
        if (res.code === 401) {
          localStorage.clear();
          navigate("/");
        }
        setLoading(false);
      });
  };
  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("appliedlives", id);
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "subject",
      field: "subject",
      render: (text, record) => (
        <>
          {text && text.subject ? (
            <Link to={`/leaveDetails?id=${text._id}`} className="text">
              <Tooltip title="View">
                <span
                  className="text-capitalize"
                  onClick={() => chapterIdHandle(text.id)}
                >
                  {text.subject}
                </span>
              </Tooltip>
            </Link>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "Leave Start Date",
      dataIndex: "leaveStartDate",
      field: "leaveStartDate",
      render: (text, record) => (
        <>{moment(text && text.leaveStartDate).format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "Leave End Date",
      dataIndex: "leaveEndDate",
      field: "leaveEndDate",
      render: (text, record) => (
        <>{moment(text && text.leaveEndDate).format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "Leave-Status",
      dataIndex: "leave_status",
      field: "leave_status",
      render: (text, record) => (
        <>
          {text.leave_status == "Pending" ? (
            <div
              style={{
                color: "#fff",
                backgroundColor: "#F4C430",
                borderRadius: "5px",
                padding: "6px 10px",
                width: "100px",
                textAlign: "center",
                fontWeight: "600px",
              }}
            >
              {text.leave_status}
            </div>
          ) : text.leave_status == "Cancel" ? (
            <div
              style={{
                color: "white",
                background: "red",
                borderRadius: "5px",
                padding: "6px 10px",
                width: "100px",
                textAlign: "center",
                fontWeight: "600px",
              }}
            >
              {text.leave_status}
            </div>
          ) : text.leave_status == "Accepted" ? (
            <div
              style={{
                color: "white",
                background: "#B4C424",
                borderRadius: "5px",
                padding: "6px 10px",
                width: "100px",
                textAlign: "center",
                fontWeight: "600px",
              }}
            >
              {text.leave_status}
            </div>
          ) : text.leave_status == "Rejected" ? (
            <div
              style={{
                color: "white",
                background: "red",
                borderRadius: "5px",
                padding: "6px 10px",
                width: "100px",
                textAlign: "center",
                fontWeight: "600px",
              }}
            >
              {text.leave_status}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          localStorage.setItem("rolesid", items.id);
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
      <div>
        <div className="main-content">
          <div className="section">
            <div className="d-flex align-items-center justify-content-between headings">
              <SectionHeader title="Leave From" />
              <Tooltip title="Add Leave">
                <p onClick={iconbtn} className="pointer text-white  fs-3">
                  <PlusSquareOutlined />
                </p>
              </Tooltip>
            </div>

            <div className="mt-2">
              <div className="row">
                <div className="col-12 col-md-2 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="dashtitles">
                          <h4 className="">--</h4>
                          <p>Total Leave</p>
                        </div>
                        <div className="icons_dash">
                          <GoProjectRoadmap />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-2 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="dashtitles">
                          <h4 className="">--</h4>
                          <p>Short Leave</p>
                        </div>
                        <div className="icons_dash">
                          <GoProjectRoadmap />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-2 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="dashtitles">
                          <h4 className="">--</h4>
                          <p>Taken Leave</p>
                        </div>
                        <div className="icons_dash">
                          <GoProjectRoadmap />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-2 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="dashtitles">
                          <h4 className="">--</h4>
                          <p>Remaining Leave</p>
                        </div>
                        <div className="icons_dash">
                          <GoProjectRoadmap />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Spin spinning={loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              />
            </Spin>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Leavelist;
