import { Button, Spin, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router"; 
import SectionHeader from "../../components/global/SectionHeader";
import ApiClient from "../../api/apiClient";
import moment from "moment";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

export default function ViewTask() {
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const history = useNavigate();
  const backfun = () => {
    history(-1);
  };
  const getdetails = () => {
    setloading(true);
    ApiClient.get(`task?id=${id}`)
      .then((res) => {
        setdata(res.data);
        setloading(false);
      })
      .catch((err) => {
        notification.error({ message: "Some error occurred.Try again" });
        setloading(false);
      });
  };
  useEffect(() => {
    getdetails();
  }, []);
  return (
    <Layout>
    <div>

    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={backfun} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Task Details" />
            </div>
          </div>
    
      <div className="main-content">
        <section className="section">
       

       
          <Spin spinning={loading}>
            <div className="shadow pl-3">
              <div className="p-3">
                <div className="row">
                  <div className="col-md-12">
                    <label>Task Name :</label>
                    <span>{data?.taskName}</span>
                  </div>
                  <div className="col-md-4">
                    <label>Start Date : </label>
                    <span>
                      {moment(data?.assignDateAndTime).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label>Expected Time : </label>
                    <span>
                      {data?.expectedhours} {data?.expectedminutes}
                    </span>
                  </div>
                  {data?.status == "done" ? (
                    <div className="col-md-4">
                      <label>Actual Time : </label>
                      <span>
                        {data?.Actual_hours} {data?.Actual_minutes}
                      </span>
                    </div>
                  ) : null}
                  <div className="col-md-4">
                    <label>Status : </label>
                    <span
                      className={`text-${
                        data?.status == "inProgress"
                          ? "warning"
                          : data?.status == "done"
                          ? "success"
                          : "danger"
                      } text-capitalize`}
                    >
                      {data?.status}
                    </span>
                  </div>

                  <div className="col-md-12">
                    <label>Stories : </label>
                    <span>{data?.description}</span>
                  </div>
                  {data?.status == "Pending" ? (
                    <div className="col-md-12">
                      <label>Reason : </label>
                      <span>{data?.reason}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
    </Layout>
  );
}
