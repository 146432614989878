export const LOGIN_USER = "LOGIN_USER"

export const LOGOUT_USER = "LOGOUT_USER"

export const FORGET_PASSWORD = "FORGET_PASSWORD"

export const RESET_PASSWORD = "RESET_PASSWORD"

export const USER_DETAIL = "USER_DETAIL"

export const EDIT_PROFILE = "EDIT_PROFILE"

export const DAILY_TASK = "DAILY_TASK"

export const PROFILE_IMAGE = "PROFILE_IMAGE"

export const LEAVE_APPLY = "LEAVE_APPLY"

export const PUNCH_INTIME = "PUNCH_INTIME"

export const PUNCHOUT_TIME = "PUNCHOUT_TIME"

export const ALLLEAVES_DATA = "ALLLEAVES_DATA"

export const IMAGE_UPLOAD = "IMAGE_UPLOAD"

export const TOTAL_TIME = "TOTAL_TIME"

export const RESIGNATION_FORM = "RESIGNATION_FORM"

export const RESIGNATION_DATA = "RESIGNATION_DATA"

export const RESIGNATION_CANCEL = "RESIGNATION_CANCEL"

export const KNOWLEDGE_SHARINGPOST = "KNOWLEDGE_SHARINGPOST"

export const KNOWLEDGESHARINGPOST_PARTICULARPERSON = "KNOWLEDGESHARINGPOST_PARTICULARPERSON"

export const SHOW_FILE = "SHOW_FILE"


export const DELETE_PARTICULARPOST = "DELETE_PARTICULARPOST"

export const EDIT_POSTPARTICULAR = "EDIT_POSTPARTICULAR"

export const SINGLEUSER_ATTENDENCE = "SINGLEUSER_ATTENDENCE"

export const PROJECT_ASSIGN = "PROJECT_ASSIGN"

export const USERMAILING_LIST = "USERMAILING_LIST"

export const PROJECT_INFORMATION = "PROJECT_INFORMATION"

export const UPLODE_IMAGE = "UPLODE_IMAGE"

export const DAINAMIC_DATA = "DAINAMIC_DATA"
