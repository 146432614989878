import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import "./login.css";
import { API_URL } from "../../../environment";
import ApiClient from "../../../api/apiClient";
import { FiEye } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa6";

const Login = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [widths, setWidths] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    Screenwidth();
    check();
  }, []);
  const Screenwidth = () => {
    if ($(window).width() < 1024) {
      setWidths("mobile");
    } else {
      setWidths("desktop");
    }
  };

  const check = () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (token) {
      if (role == "subadmin") {
        navigate("/hrdashboard");
      } else {
        navigate("/dashboard");
      }
    }
  };
  const Submit = async (e) => {
    e?.preventDefault();
    setLoader(true);
    const email = form.email;
    if (email.includes("@jcsoftwaresolution.com")) {
      const payload = {
        email: form?.email,
        password: form?.password,
        device: widths,
      };
      setLoading(true);
      ApiClient.post("user/signin", payload).then(
        (res) => {
          if (res.success) {
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("USERID", res.data.id);
            localStorage.setItem("Name", res.data.fullName);
            localStorage.setItem("Email", res.data.email);
            localStorage.setItem("role", res.data.role);
            sessionStorage.setItem("Window", "Open");
            UserPunchIn(res.data.access_token);
            dispatch({
              type: "SET_TOKEN",
              payload: { token: res.data.access_token },
            });
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    } else {
      notification.error({
        message: "Please enter a valid Email.",
      });
    }
  };
  const UserPunchIn = (data) => {
    const newdata = JSON.stringify({ device: widths });
    fetch(`${API_URL}attendance/checkin`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${data}`,
      },
      body: newdata,
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("device", widths);
        localStorage.setItem("LastCheckInTime", res.lastCheckInTime);
        history("/attendance");

        if (res.alreadyCheckedIn == true) {
          notification.success({
            message: "You are already Punchedin ",
          });
          localStorage.setItem("attId", res.checkInId);
          localStorage.setItem("PunchIn", true);
          localStorage.setItem("checkintime", res.data.checkIntime);
        } else {
          notification.success({
            message: "You Are Punched In",
          });
          notification.success({
            message: "Login Successfully.",
          });
          localStorage.setItem("PunchIn", true);
          localStorage.setItem("attId", res.data.id);
        }

        // } else {
      })
      .catch((err) => console.log("You are back"));
  };
  return (
    <>
      <div className="wrapperauth">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 mx-auto ">
              <div className="mb-5">
                <img src="/img/logo1.png" className="logolog" alt="logo" />
              </div>

              <div className="forms_data">
                <form onSubmit={Submit} className="w-100">
                  <div className="forms_logins mb-4">
                    <h1 className="text-xl mb-0">Sign in</h1>
                    <p>Free access to our dashboard.</p>
                  </div>

                  <div className="main_inners">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={form?.email}
                        onChange={(e) => {
                          setForm({ ...form, email: e?.target?.value });
                        }}
                        type="text"
                        placeholder="Enter Email"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Password</label>
                      <div className="position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter password"
                          className="form-control"
                          value={form?.password}
                          onChange={(e) => {
                            setForm({ ...form, password: e?.target?.value });
                          }}
                        />
                        <div
                          className="password_eye"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FiEye /> : <FaRegEyeSlash />}
                        </div>
                      </div>
                    </div>

                    <div className=" text-end">
                      <Link to={"/forgot"} className="forgot text-black">
                        Forgot Password?
                      </Link>
                    </div>

                    <div className=" submit_btncls mt-3 ">
                      <button
                        className="btn btn-primary w-100 shadow-btn h-45"
                        type="submit"
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <>
                          Submit
                          <div
                            className="loader"
                            style={{ display: "none" }}
                          ></div>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
