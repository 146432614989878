import React, { useEffect, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import SectionHeader from "../../components/global/SectionHeader";
import { SHOW_FILE } from "../../redux/Store/constants";
import { API_URL } from "../../environment";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSidebar } from "../../components/provider/Provider";
import { Navigate, useNavigate } from "react-router";
import Layout from "../../components/global/layout";

const ProjectFile = (props) => {
  const xlIduser = localStorage.getItem("xlIduser");
  const { setCourseId } = useSidebar();
  const dispatch = useDispatch();
  const [docs, setDocs] = useState();
  const [testdocuments, settestdocuments] = useState();
  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);
  const [urls, setUrl] = useState();
  const [blobFile, setblobFile] = useState();
  const token = localStorage.getItem("token");
  const EXTENSIONS = ["xlsx", "xls", "csv"];
  const [employees, setEmployees] = useState([]);
  const getData = async (id) => {
    localStorage.setItem("tableID", id);
  };
  docs &&
    docs.map((items) => {
      setCourseId(items.documents);
    });
  useEffect(() => {
    Knowledgesharingtable();
  }, []);

  const Knowledgesharingtable = (id) => {
    setLoading(true);
    fetch(`${API_URL}getWeeklyplanbyprojectID?id=${xlIduser}`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data);
        // setTopicname(res.data && res.data.name)
        setDocs(res.data);

        if (res && res.data && res.data.access_token) {
          dispatch({
            type: SHOW_FILE,
            payload: res,
          });
          //history("/dashboard")
        }
        if (res.code === 401) {
          localStorage.clear();
        }
        setLoading(false);
      });
  };    
  const navigate= useNavigate();

  const chapterIdHandle = async (id) => {
    localStorage.setItem("updatedxldata", id);
  };

  const columns = [
    {
      title: "Documents",
      dataIndex: "documents",
      field: "documents",
      render: (text, record) => (
        settestdocuments(text.documents),
        (
          <>
            {
              <>
                <Link to={`/tabledataxls?id=${text.id}`}>
                  <span onClick={(e) => getData(text.id)}>
                    {text.documents}
                  </span>
                </Link>
              </>
            }
          </>
        )
      ),
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      field: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      field: "endDate",
    },

    {
      title: "Updated Task",
      dataIndex: "endDate",
      field: "endDate",
      render: (text, record) => (
        <>
          {
            <Link to={`/updatedtask?id=${text.id}`}>
              <span className="text" onClick={() => chapterIdHandle(text.id)}>
                <i
                  className="fas fa-file-excel"
                  style={{
                    fontSize: "xx-large",
                  }}
                ></i>
              </span>
            </Link>
          }
        </>
      ),
    },
  ];

  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const renderSubmitBack = () => {
    navigate("/projectassigntable");
  };

  const renderBody = () => {
    return (
      employees &&
      employees.map((items) => {
        return (
          <tr>
            <td>{items.Week_18oct_to_22oct_2021}</td>
          </tr>
        );
      })
    );
  };

  // const submittask = () => {
  //     const payload = { tasks: arrayParsedata }

  //     fetch(`${API_URL}getWeeklyplanjson?id=${tableID}`, {
  //         method: 'GET',

  //         headers: {
  //             'Content-type': 'application/json',
  //             Authorization: `Bearer ${token}`,
  //             body: JSON.stringify(payload),
  //         },
  //     })
  //         .then(res => res.json())
  //         .then(res => {

  //             setEmployees(res.data)
  //         });

  // }

  return (
    <Layout>
      <div>
        <div className="main-wrapper">
          <div className="navbar-bg" />
         
        </div>

        <div className="main-content">
          <section className="section">
            <SectionHeader title="XL File" />
            <div>
              <Button
                type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </div>

            <div>
              <h1 id="title">React Table</h1>
              <table id="employee">
                <thead></thead>
                <tbody>{renderBody()}</tbody>
              </table>
            </div>

            <div>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                icons={tableIcons}
                // title="Project Data"
                data={newlyAddedChapter}
                columns={columns}
              />
            </div>
          </section>
        </div>

        {/* <button

                    className="login-form-button"
                    onClick={(e) => submittask(e)}

                >
                    Submit
                </button> */}
      </div>
    </Layout>
  );
};

export default ProjectFile;
