import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader";
import { Navigate, useNavigate } from "react-router";
import { getholiday } from "../../api/api";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const HolidaysDetail = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [loading, setloading] = useState(false);
  const [description, setDescription] = useState();
  const [event, setEvent] = useState();
  const [Endd, setEndd] = useState();
  const [Startd, setStartd] = useState(); 
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  useEffect(() => {
    getProject(id);
  }, [id]);

  const getProject = async () => {
    setloading(true);
    try {
      const resp = await getholiday(id);
      if (resp && resp.status === 200) { 
        const list = resp;
        const description = list.data.data.description;
        const Eventdata = list.data.data.event;
        const Endd = list.data.data.end_date;
        const Startd = list.data.data.start_date;

        setDescription(description);
        setEvent(Eventdata);
        setStartd(Startd);
        setEndd(Endd);
        setStatus(progress_status); 
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const navigate = useNavigate();
  const renderSubmitBack = () => {
    navigate("/holidaylist");
  };
  return (
    <Layout>
    <div className="">
     
      <div className="main-content">

      <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p  onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Holidays Details" />
            </div>
          </div>
        <div className="section">
        
        
          <Spin spinning={loading}>
            <div className="">
              <div className="row">
                <div class="col-md-6">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">Subject:</label>
                    </div>
                    <div className="label_text">
                      <span> {event}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">Start date:</label>
                    </div>
                    <div className="label_text">
                      <span> {Startd}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">End date:</label>
                    </div>
                    <div className="label_text">
                      <span> {Endd}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">Reason:</label>
                    </div>
                    <div className="label_text">
                      <span class="prettify pl-1" >
                        {" "}
                        {htmlToReactParser.parse(description)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default HolidaysDetail;
