import { Button, Form, Popconfirm, Spin, Tooltip } from 'antd';
import React, { useState, useEffect, useRef } from 'react'; 
import { PlusSquareOutlined } from "@ant-design/icons"; 
import SectionHeader from '../../components/global/SectionHeader'; 
import { notification } from 'antd';
import { from } from 'form-data';
import { useNavigate } from 'react-router';
import ApiClient from '../../api/apiClient';
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from 'react-icons/bs';


const Reviewstatus = () => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("id");
    const showmodal = useRef();
    const closemodal = useRef();
    const [statustask, setstatustask] = useState(null);
    const [alltask, settask] = useState()
    const [status, setstatus] = useState()
    const [form, setform] = useState([]) 
    const [userid, setuserId] = useState()
    const [taskid, settaskid] = useState() 
    const history = useNavigate()
    const [loading, setloading] = useState(false)

    const handleview = (id) => {
        history(`/view/task/${id}`)
    } 
    
    useEffect(() => {
    }, [status])

    const updateTasks = (id, e) => {
        setstatustask(e.target.value);
    }

    function myFormFunction() {
     
        history(`/addreview?id=${paramValue}`)
    }
    useEffect(() => {
        getAllTask()
    }, [])

    // Task add
    const getAllTask = () => {
        let userId = ''
        if (localStorage.getItem('USERID')) {
            userId = localStorage.getItem('USERID')
            setuserId(userId)
        }
        setloading(true)
        //  ApiClient.get('task_reviews?&search', { page: 1,count: 1000 }).then(res => { 
      // ApiClient.get('all/task?&start_date=2023-08-24&end_date=2023-08-24&employeeId=645a1dcff0ac171a1801de83&count=100&projectId=649581550b67f99a5afd4bfb&search', { page: 1,count: 1000 }).then(res => {  
      ApiClient.get(`all/task?&start_date=${today}&end_date=${today}&employeeId=${userId}&count=100&projectId=${paramValue}&search`, { page: 1,count: 1000 }).then(res => {     
      settask(res?.data)
            setloading(false) 
        })
    }
    const handlesubmit = (e) => {
        e.preventDefault();
        let payload = {
            taskName: form.taskName,
            description: form.description,
            assignDateAndTime: form.assignDateAndTime,
            submitDateAndTime: form.submitDateAndTime,
            expectedminutes: form.expectedminutes,
            expectedhours: form.expectedhours,
            project: paramValue,
            employee: userid,
            addedBy: userid,
            status: statustask,
            reason: form.reason,
            Actual_time: form.Actual_time,
            Actual_hours: form.Actual_hours,
            Actual_minutes: form.Actual_minutes,
            expected_time: form.expected_time
        }
        if (!taskid) { delete payload.status } else { }
        setloading(true)
        {
            taskid && taskid ?
                ApiClient.put(`update/task?id=${taskid}`, payload).then(res => {
                    setloading(false)
                    
                    if (res.success) {
                        getAllTask()
                        
                        setform({ ...from, taskName: '', description: '', assignDateAndTime: '', submitDateAndTime: '', expectedminutes: '', expectedhours: '', Actual_minutes: '', Actual_hours: '' })
                        notification.success({
                            message: 'Task Updated Successfully.',
                        });

                    }
                    document.getElementById("edittask2").click() 
                    
                }).catch(err => setloading(false))
                :
                ApiClient.post('task', payload).then(res => {
                    if (res.success) {
                        setloading(false)
                        getAllTask()
                        setform({ ...from, taskName: '', description: '', assignDateAndTime: '', submitDateAndTime: '', expectedminutes: '', expectedhours: '', Actual_minutes: '', Actual_hours: '' })
                        notification.success({
                            message: 'Task Added Successfully.',
                        });
                    }
                }).catch(err => setloading(false))
        }
        // document.getElementById("closemodal").click()
    }

    const edittask = (id) => {
        document.getElementById("myForm").reset();
        setform(null);
        settaskid(id);
        ApiClient.get('task', { id: id }).then(res => {
            if (res.success) {
                setstatustask(res.data.status)
                setform({ ...res.data })
            }
        }).catch(err => {
            notification.error({
                message: "Please try again"
            })
        })
    }
    const deletetasks = (id) => {
        setloading(true)
        ApiClient.delete('remove/task', { id: id }).then(res => {
            setloading(false)
            if (res.success) {
                getAllTask()
                notification.success({
                    message: 'Task Deleted Successfully',
                });
            }
        })
    }

    const closingmodal = () => {
        closemodal.current.click()
    }
    const backfun = () => {
        history(-1)
    }

    const today = new Date().toISOString().slice(0, 16);

    return (
        <Layout>

            {/* For View Task Modal */}
            <button type="button" ref={showmodal} class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal2">
                Launch demo modal
            </button>
            <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">View Task</h5>
                            <button type="button" class="close" data-dismiss="modal" onClick={closingmodal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={closingmodal} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*  End of Modal */}
            <div>
            <div className="">
            <div className="d-flex align-items-center justify-content-between headings">
           <div className='d-flex gap-3 align-items-center'>
             <p  onClick={backfun} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              <SectionHeader title="Project Review" />
           </div>
              <Tooltip title="Add Project">
                <p  onClick={myFormFunction} className="pointer text-white  fs-3">
                <PlusSquareOutlined />
                </p>
              </Tooltip>
            </div>
          </div>

                <div className="">
               
                      

                   

                        <Spin spinning={loading}>
                            <form>
                                <table className='table my-3'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Review Tasks</th>
                                            {/* <th>Status</th> */}
                                            {/* <th>Expected Time</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            alltask && alltask.map((itm, i) => {
                                                return <tr key={i}>

                                                    <td className='col-3 text-truncate' onClick={() => handleview(itm.id)} ref={closemodal}><Tooltip title="View">{itm.taskName} </Tooltip></td>
                                                    {/* <td className={`text-capitalize text-${itm.status=="inProgress"?"warning":itm.status=="done"?"success":"danger"}`}>
                                                        {itm.status} */}
                                                    {/* <div className="form-group col-md-4">
                                                            <select id="inputState" className="form-control" value={itm.status} onChange={e => updateTasks(itm._id, e)}>
                                                                <option value="done">Done</option>
                                                                <option value="inProgress">InProgress</option>
                                                                <option value="pending">Pending</option>
                                                            </select>
                                                        </div> */}
                                                    {/* </td> */}
                                                    {/* <td className={`text-capitalize text-${itm.status == "inProgress" ? "warning" : itm.status == "done" ? "success" : "danger"}`}>
                                                        <p className={`text-capitalize btn btn-${itm.status == "inProgress" ? "warning" : itm.status == "done" ? "success" : "danger"}`}>  {itm.status} </p>
                                                    </td> */}
                                                    {/* <td>{itm.expected_time}</td> */}
                                                    <td >
                                                        {itm.status == "done" ? <p className='btn btn-success' >Done</p> : <>
                                                            <button type="button" className=' bg-secondary m-1 rounded' onClick={e => edittask(itm.id)} data-bs-toggle="modal" data-bs-target="#exampleModal"><i className='fa fa-edit'></i></button>
                                                            <Popconfirm
                                                                title="Are you sure to Delete this ?"
                                                                onConfirm={e => deletetasks(itm.id)}
                                                                onCancel={() => console.log("Cancelled")}
                                                                okText="Yes"
                                                                cancelText="No">
                                                                <button type="button" className=' bg-danger m-1 rounded'><i className='fa fa-trash'></i></button>
                                                            </Popconfirm> 
                                                            </>}
                                                        {/* <Button className='btn' onClick={(e)=>showmodalfunction(e)}><i className='fa fa-view'></i></Button> */}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </form>
                        </Spin>
               

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{taskid && taskid
                                        ?
                                        "Edit Review "
                                        :
                                        "Add Task"
                                    }</h5>
                                    <button type="button" id="edittask2" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <form onSubmit={handlesubmit} id='myForm'>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <input text="text" value={form?.taskName} onChange={e => setform({ ...form, taskName: e.target.value })} className=' form-control  mt-3' placeholder='Enter Task' required></input>
                                            </div>
                                            <div class="col-md-6">
                                                <input disabled type="text" min={today} value={form?.assignDateAndTime} onChange={e => setform({ ...form, assignDateAndTime: e.target.value })} onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = "date"} id="assignDateAndTime" name="assignDateAndTime" className='form-control mt-3' placeholder="Start Date" required></input>
                                            </div>
                                            {/* <div class="col-md-6">
                                                <input type="text"
                                                    value={form?.expected_time}
                                                    onChange={e => setform({ ...form, expected_time: e.target.value })}
                                                    onFocus={(e) => e.target.type = 'time-locale'}
                                                    onBlur={(e) => e.target.type = "text"}
                                                    placeholder="Expected Time"
                                                    id="expected_time"
                                                    name="expected_time"
                                                    className='form-control mt-3'
                                                    required></input>
                                            </div> */}

                                            {
                                                taskid ?
                                                    <div className="col-6">
                                                        <select id="inputState" className="form-control mt-3" value={statustask} onChange={e => updateTasks(taskid, e)}>
                                                            <option value="done">Done</option>
                                                            <option value="inProgress">InProgress</option>
                                                            <option value="pending">Pending</option>
                                                        </select>
                                                    </div> : null
                                            }

                                            <div className="col-6 mt-2 mb-2">
                                                {/* <label className="control-label">
                                                    Expected hours{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <br /> */}
                                                <select
                                                    className="form-control mt-3"
                                                    name="expectedhours"
                                                    value={form?.expectedhours}
                                                    // onChange={(e) => handlechange(e, index)}
                                                    onChange={e => setform({ ...form, expectedhours: e.target.value })}
                                                    required
                                                >
                                                    <option value="">Expected hours...</option>
                                                    <option value="00 hour">00 hour</option>
                                                    <option value="01 hour">01 hour</option>
                                                    <option value="02 hour">02 hour</option>
                                                    <option value="03 hour">03 hour</option>
                                                    <option value="04 hour">04 hour</option>
                                                    <option value="05 hour">05 hour</option>
                                                    <option value="06 hour">06 hour</option>
                                                    <option value="07 hour">07 hour</option>
                                                    <option value="08 hour">08 hour</option>
                                                    <option value="09 hour">09 hour</option>
                                                    <option value="10 hour">10 hour</option>
                                                    <option value="11 hour">11 hour</option>
                                                    <option value="12 hour">12 hour</option>
                                                    <option value="13 hour">13 hour</option>


                                                </select>
                                            </div>
                                            <div className="col-6 mt-2 mb-2">
                                                {/* <label className="control-label">
                                                    Expected minutes{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <br /> */}
                                                <select
                                                    className="form-control  mt-3"
                                                    value={form?.expectedminutes}
                                                    onChange={e => setform({ ...form, expectedminutes: e.target.value })}
                                                    required
                                                >
                                                    <option value="">Expected minutes...</option>
                                                    <option value="00 minute">00 minute </option>
                                                    <option value="05 minute">05 minute </option>
                                                    <option value="10 minute">10 minute </option>
                                                    <option value="15 minute">15 minute </option>
                                                    <option value="20 minute">20 minute </option>
                                                    <option value="25 minute">25 minute </option>
                                                    <option value="30 minute">30 minute </option>
                                                    <option value="35 minute">35 minute </option>
                                                    <option value="40 minute">40 minute </option>
                                                    <option value="45 minute">45 minute </option>
                                                    <option value="50 minute">50 minute </option>
                                                    <option value="55 minute">55 minute </option>
                                                </select>

                                            </div>
                                            {/* DropDown */}

                                            {/* Actual Time */}
                                            {
                                                taskid ? statustask == "pending" || statustask == "inProgress" ? null :
                                                    <div class="row">
                                                        {/* <div class="col-md-6">
                                                        <input type="time" value={form?.Actual_time}
                                                            onChange={e => setform({ ...form, Actual_time: e.target.value })}
                                                            onBlur={(e) => e.target.type = "text"}
                                                            placeholder="Actual Time" id="Actual_time"
                                                            name="Actual_time" className='form-control mt-3'
                                                            required></input>
                                                    </div> */}
                                                        <div className="col-6 mt-2 mb-2">

                                                            <select
                                                                className="form-control mt-3"
                                                                name="expectedhours"
                                                                value={form?.Actual_hours}

                                                                onChange={e => setform({ ...form, Actual_hours: e.target.value })}
                                                                required
                                                            >
                                                                <option value="">Actual hours...</option>
                                                                <option value="00 hour">00 hour</option>
                                                                <option value="01 hour">01 hour</option>
                                                                <option value="02 hour">02 hour</option>
                                                                <option value="03 hour">03 hour</option>
                                                                <option value="04 hour">04 hour</option>
                                                                <option value="05 hour">05 hour</option>
                                                                <option value="06 hour">06 hour</option>
                                                                <option value="07 hour">07 hour</option>
                                                                <option value="08 hour">08 hour</option>
                                                                <option value="09 hour">09 hour</option>
                                                                <option value="10 hour">10 hour</option>
                                                                <option value="11 hour">11 hour</option>
                                                                <option value="12 hour">12 hour</option>
                                                                <option value="13 hour">13 hour</option>


                                                            </select>
                                                        </div>
                                                        <div className="col-6 mt-2 mb-2">

                                                            <select
                                                                className="form-control  mt-3"
                                                                value={form?.Actual_minutes}
                                                                onChange={e => setform({ ...form, Actual_minutes: e.target.value })}
                                                                required
                                                            >
                                                                <option value="">Actual minutes...</option>
                                                                <option value="00 minute">00 minute </option>
                                                                <option value="05 minute">05 minute </option>
                                                                <option value="10 minute">10 minute </option>
                                                                <option value="15 minute">15 minute </option>
                                                                <option value="20 minute">20 minute </option>
                                                                <option value="25 minute">25 minute </option>
                                                                <option value="30 minute">30 minute </option>
                                                                <option value="35 minute">35 minute </option>
                                                                <option value="40 minute">40 minute </option>
                                                                <option value="45 minute">45 minute </option>
                                                                <option value="50 minute">50 minute </option>
                                                                <option value="55 minute">55 minute </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                            <div class="col-md-12">
                                                <textarea id='textareamy'
                                                    value={form?.description}
                                                    onChange={e => setform({ ...form, description: e.target.value })}
                                                    name='description'
                                                    className='form-control  mt-3'
                                                    placeholder='Stories'
                                                    required></textarea>
                                            </div>
                                            {
                                                statustask == "pending" ? taskid ? <div class="col-md-12">
                                                    <textarea id='reason' value={form?.reason} onChange={e => setform({ ...form, reason: e.target.value })} name='reason' className='form-control  mt-3' placeholder='Reason' required={statustask == "pending"}></textarea>
                                                </div> : null : ""
                                            }
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        {/* <button type="button" class="btn btn-secondary" id="closemodal" data-bs-dismiss="modal">Close</button> */}
                                        <button type="submit" class="btn btn-primary">{taskid && taskid ?
                                            "Update"
                                            :
                                            "Add"
                                        }</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Reviewstatus;