import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../environment";
import { USER_DETAIL } from "../../../redux/Store/constants";
import { Button, Input, Menu, Spin, Tooltip } from "antd";
import { Formik } from "formik";
import { notification } from "antd";
import * as Yup from "yup";
import { logoutuser } from "../../../redux/Actions/ActionFunctions";
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';
import "../profile/Profile.css";
import { useNavigate } from "react-router-dom";
import SectionHeader from "../../../components/global/SectionHeader";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../api/apiClient";
import { BsArrowLeftSquare } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";


const EditProfile = () => {
  const openref = useRef();
  let history = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const dispatch = useDispatch();
  const userid = localStorage.getItem("USERID");
  const [Userdetail, setUserdetail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [fullname, setfullname] = useState();
  const [email, setemail] = useState();
  const [employeeId, setemployeeId] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [dateOfJoining, setDateOfJoining] = useState();
  const [dateOfRelieving, setDateOfRelieving] = useState();
  const [dateOfResignation, setDateOfResignation] = useState();
  const [designation, setDesignation] = useState();
  const [dob, setDOB] = useState();
  const [fatherName, setFatherName] = useState();
  const [linkedinURL, setLinkedinURL] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [motherName, setMotherName] = useState();
  const [primaryAddress, setPrimaryAddress] = useState();
  const [primaryAddress1, setPrimaryAddress1] = useState();
  const [primaryCity, setPrimaryCity] = useState();
  const [primaryCountry, setPrimaryCountry] = useState();
  const [primaryState, setPrimaryState] = useState();
  const [primaryZipcode, setPrimaryZipcode] = useState();
  const [role, setRole] = useState();
  const [secondaryAddress, setSecondaryAddress] = useState();
  const [secondaryAddress1, setSecondaryAddress1] = useState();
  const [secondaryEmail, setSecondaryEmail] = useState();
  const [gender, setGender] = useState();
  const [img, setImg] = useState();
  const [loading, setloading] = useState(false);
  localStorage.setItem("Fullname", fullname);
  useEffect(() => {
    userDetails();
  }, []);

  const userDetails = () => {
    setloading(true);
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setloading(false);
        setUserdetail(res.data);
        setFirstName(res && res.data && res.data.firstName);
        setemail(res && res.data && res.data.email);
        setfullname(res && res.data && res.data.fullName);
        setlastName(res && res.data && res.data.lastName);
        setGender(res && res.data && res.data.gender);
        setemployeeId(res && res.data && res.data.employeeId);
        setContactNumber(res && res.data && res.data.contactNumber);
        setDateOfJoining(res && res.data && res.data.dateOfJoining);
        setDateOfRelieving(res && res.data && res.data.dateOfRelieving);
        setDateOfResignation(res && res.data && res.data.dateOfResignation);
        setDesignation(res && res.data && res.data.designation);
        setDOB(res && res.data && res.data.dob);
        setFatherName(res && res.data && res.data.fatherName);
        setLinkedinURL(res && res.data && res.data.linkedinURL);
        setMobileNo(res && res.data && res.data.mobileNo);
        setMotherName(res && res.data && res.data.motherName);
        setPrimaryAddress(res && res.data && res.data.primaryAddress);
        setPrimaryAddress1(res && res.data && res.data.primaryAddress1);
        setPrimaryCity(res && res.data && res.data.primaryCity);
        setPrimaryCountry(res && res.data && res.data.primaryCountry);
        setPrimaryState(res && res.data && res.data.primaryState);
        setPrimaryZipcode(res && res.data && res.data.primaryZipcode);
        setRole(res && res.data && res.data.role);
        setSecondaryAddress(res && res.data && res.data.secondaryAddress);
        setSecondaryAddress1(res && res.data && res.data.secondaryAddress1);
        setSecondaryEmail(res && res.data && res.data.secondaryEmail);
        setImg(res && res.data && res.data.image);

        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
        } else {
        }
      });
  };

  const handleSubmit = () => {
    const payload = {
      userid,
      firstName,
      lastName,
      fullname,
      email,
      gender,
      employeeId,
      contactNumber,
      dateOfJoining,
      dateOfRelieving,
      dateOfResignation,
      designation,
      dob,
      fatherName,
      linkedinURL,
      mobileNo,
      motherName,
      primaryAddress,
      primaryAddress1,
      primaryCity,
      primaryCountry,
      primaryState,
      primaryZipcode,
      role,
      secondaryAddress,
      secondaryAddress1,
      secondaryEmail,
      image: img,
    };

    ApiClient.put(`editProfile?id=${userid}`, payload).then((res) => {
      if (res.success) {
        history("/myprofile");
        notification.success({
          message: "Profile Updated Successfully",
        });
      }
    });
  };
  const stand = () => {
    document.getElementById("foo").src = "userimg.jpg";
  };
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    employeeId: Yup.number()
      .min(6, "employeeId must be at least 6 charaters")
      .max(12, "employeeId dont maximum 12 charaters")
      .required("employeeId is required"),
  });
  const handleInputChange = async (event) => {
    setloading(true);
    let file = event.target.files[0];
    if (file != null) {
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      let res = await fetch(
        `${API_URL}user/uploadProfileImage`,

        {
          method: "post",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let responseJson = await res.json();
      setloading(false);
      if (responseJson) {
        notification.success({
          message: "Uploaded Successfully",
        });
        setImg(API_URL + responseJson.data.imagePath);
      }
    } else {
      setloading(false);
      notification.success({
        message: "Please Upload a image First",
      });
    }
  };
  const backbutton = () => {
    history(-1);
  };
  return (
    <Layout>
   
          <div className="main-content">

          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={backbutton} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Edit Profile" />
            </div>
          </div>


            <div className="profile_section_name">
              <div className="section">
             
                <div>
                  <Spin spinning={loading}>
                    <div className="form">
                      <div className="mainupload">
                        {img && img.length ? (
                          <img
                            src={img}
                            id="foo"
                            alt="profile"
                            onError={stand}
                            className="profile_img"
                          />
                        ) : (
                          <img
                             src="assets/img/avatar/avatar-4.png"
                            alt="profile"
                            className="profile_img"
                          />
                        )}
                        <div className="logo_profile">
                          <div>
                            <label className="text-white d-none">
                              Select File :
                            </label>
                            <input size="large"
                              ref={openref}
                              accept="image/*"
                              type="file"
                              className="uploadbtn"
                              name="upload_file"
                              onChange={handleInputChange}
                            />

<div className="editicons" onClick={() => setImg("")}>
<IoCloseSharp   />
</div>


                        
                           
                          </div>
                        </div>
                        <div className="w-100 mt-4">
                        <button onClick={() => openref.current.click()} className="btn btn-primary  d-flex align-items-center gap-2 mx-auto">
                           <FiEdit3 />
                            <p>Edit Profile</p>
                           </button>
                        </div>
                           
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        employeeId: "",
                      }}
                      validationSchema={validate}
                      onSubmit={(values) => {}}
                    >
                      <div className="mt-4 edirprofile">
                        <form
                          className="form"
                          action="javascript:void(0)"
                          onSubmit={handleSubmit}
                        >
                          <div className="row ">
                            <div className="col-12 col-md-6 ">
                              <div className="form-group">

                             
                              <label for="fname">
                                First Name
                                <span className="text-danger"> *</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="fname"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName && firstName}
                              />
                               </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="lname">
                                Last Name<span className="text-danger"> *</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="lname"
                                required
                                onChange={(e) => setlastName(e.target.value)}
                                value={lastName && lastName}
                              />
                            </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Date Of Joining
                                <span className="text-danger"> *</span>
                              </label>
                              <Input size="large"
                                disabled
                                type="Date"
                                name="DateOfJoining"
                                required
                                onChange={(e) =>
                                  setDateOfJoining(e.target.value)
                                }
                                value={dateOfJoining && dateOfJoining}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">Landline Number</label>
                              <Input size="large"
                                type="text"
                                name="ContactNumber"
                                minLength="10"
                                maxlength="10"
                                onChange={(e) =>
                                  setContactNumber(e.target.value)
                                }
                                value={contactNumber && contactNumber}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="email">E-mail</label>
                              <Input size="large"
                                disabled
                                type="email"
                                name="email"
                                onChange={(e) => setemail(e.target.value)}
                                value={email && email}
                              />
                            </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">Designation</label>
                              <Input size="large"
                                type="text"
                                name="Designation"
                                maxlength="16"
                                onChange={(e) => setDesignation(e.target.value)}
                                value={designation && designation}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                D.O.B<span className="text-danger">*</span>
                              </label>
                            
                              <Input size="large"
                                type="Date"
                                name="DOB"
                                required
                                onChange={(e) => setDOB(e.target.value)}
                                value={dob && dob}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Father Name
                                <span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="FatherName"
                                required
                                onChange={(e) => setFatherName(e.target.value)}
                                value={fatherName && fatherName}
                              />
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-6 ">
                          <label for="fullname">URL</label>
                          <Input size="large" type="text" name="LinkedinURL" 
                            onChange={(e) => setLinkedinURL(e.target.value)}
                            value={linkedinURL && linkedinURL}
                            />
                        </div> */}
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Mobile No<span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="MobileNo"
                                required
                                maxlength="10"
                                minLength="10"
                                onChange={(e) => setMobileNo(e.target.value)}
                                value={mobileNo && mobileNo}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Mother Name
                                <span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="MotherName"
                                required
                                onChange={(e) => setMotherName(e.target.value)}
                                value={motherName && motherName}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">Secondary Address</label>
                              <Input size="large"
                                type="text"
                                name="PrimaryAddress"
                                onChange={(e) =>
                                  setSecondaryAddress1(e.target.value)
                                }
                                value={secondaryAddress1 && secondaryAddress1}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Primary Address
                                <span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="PrimaryAddress1"
                                required
                                onChange={(e) =>
                                  setPrimaryAddress1(e.target.value)
                                }
                                value={primaryAddress1 && primaryAddress1}
                              />
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-6 ">
                          <label for="fullname">Primary City<span className='text-danger'> *</span></label>
                          <Input size="large" type="text" name="PrimaryCity" required onChange={(e) => setPrimaryCity(e.target.value)} value={primaryCity && primaryCity}
                            />
                        </div>
                        <div className="col-12 col-md-6 ">
                          <label for="fullname">Primary Country<span className='text-danger'> *</span></label>
                          <Input size="large" type="text" name="PrimaryCountry" required onChange={(e) => setPrimaryCountry(e.target.value)} value={primaryCountry && primaryCountry}
                           />
                        </div> */}
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Primary State
                                <span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="PrimaryState"
                                required
                                onChange={(e) =>
                                  setPrimaryState(e.target.value)
                                }
                                value={primaryState && primaryState}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname">
                                Primary Zipcode
                                <span className="text-danger">*</span>
                              </label>
                              <Input size="large"
                                type="text"
                                name="PrimaryZipcode"
                                required
                                minLength="3"
                                maxlength="6"
                                onChange={(e) =>
                                  setPrimaryZipcode(e.target.value)
                                }
                                value={primaryZipcode && primaryZipcode}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label for="fullname" className="">
                                Role:
                              </label>
                              <Input size="large"
                                disabled
                                type="text"
                                name="Role"
                                maxlength="16"
                                onChange={(e) => setRole(e.target.value)}
                                value={role && role}
                              />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label
                                span={6}
                                offset={6}
                                for="Gender"
                                className=""
                              >
                                Gender
                              </label>
                              <select
                                onChange={(e) => setGender(e.target.value)}
                                className="form-control col-md-12"
                                placeholder={gender && gender}
                                value={gender && gender}
                              >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                              </select>
                           </div>
                            </div>
                            <div className="col-md-12 mt-3 ">
                              <div className="">

                              </div>
                              <button className="btn btn-primary btn-lg" type="submit">
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Formik>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        
    </Layout>
  );
};
export default EditProfile;
