import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, notification, Spin, Tooltip } from "antd";
import MaterialTable from "material-table";
import { forwardRef, useEffect, useState } from "react";
import SectionHeader from "../../components/global/SectionHeader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ApiClient from "../../api/apiClient";
import moment from "moment";
import Layout from "../../components/global/layout";

const Targetmonth = () => {
  const [Loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [ammount, setammount] = useState("");
  const [date, setdate] = useState("");
  const [status, setstatus] = useState();
  const [data, setdata] = useState();

  const columns = [
    {
      title: "Proejct Name",
      dataIndex: "name",
      field: "name",
      render: (data) => <>{data && data.project_name}</>,
    },

    {
      title: "Ammount",
      dataIndex: "amount",
      field: "amount",
      render: (data) => <>{data && data.amount}</>,
    },
    {
      title: "Date",
      dataIndex: "date",
      field: "date",
      render: (data) => <>{moment(data && data?.date).format("DD MMM YYYY")}</>,
    },
    // {
    //     title: "Status",
    //     dataIndex: "status",
    //     field: "status",
    //     render: (data) => (
    //         <>
    //             <select class="form-select" value={data&&data.projectStatus} onChange={e=>update(data&&data.id,e)} aria-label="Default select example">
    //                 <option value="awarded">Awarded</option>
    //                 <option value="rejected">Rejected</option>
    //                 <option value="ended">Ended</option>
    //             </select>
    //         </>
    //     ),
    // },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const getdata = () => {
    setLoading(true);
    ApiClient.get(`targets?sortBy=asc&count=100`).then((res) => {
      if (res.success) {
        setLoading(false);
        setdata(res.data);
      }
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  const submitdata = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      project_name: name,
      amount: ammount,
      date: date,
    };
    ApiClient.post(`target`, payload).then((res) => {
      setLoading(false);
      if (res.success) {
        notification.success({
          message: "Project Added Successfully.",
        });
        setname("");
        setammount("");
        setdate("");
        getdata();
        document.getElementById("closemodel").click();
      }
    });
  };

  const update = (id, e) => {
    setLoading(true);
    let payload = {
      projectStatus: e.target.value,
    };
    ApiClient.put(`project/update/?id=${id}`, payload).then((res) => {
      setLoading(false);
      if (res.success) {
        notification.success({
          message: "Status Changed Successfully.",
        });
        getdata();
      }
    });
  };
  return (
    <Layout>
      <div className="">
        <div className="main-content">
          <div className="d-flex align-items-center justify-content-between headings">
            <SectionHeader title="Month Target" />
            <Tooltip title="Add Target">
              <p
                data-bs-toggle="modal"
                data-bs-target="#targetadd"
                className="pointer text-white  fs-3"
              >
                <PlusSquareOutlined />
              </p>
            </Tooltip>
          </div>

          <div className="section">
            <Spin spinning={Loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={data}
                icons={tableIcons}
              />
            </Spin>
          </div>
        </div>
      </div>

      {/* modal add target */}
      <div
        class="modal fade"
        id="targetadd"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add Project To Month
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={submitdata}>
                <div className="">
                  <div className="form-group">
                    <label>Project Name</label>
                    <input
                      text="text"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className="form-control"
                      placeholder="Enter Project Name"
                      required
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Amount</label>
                    <input
                      type="number"
                      value={ammount}
                      onChange={(e) => setammount(e.target.value)}
                      className="form-control"
                      placeholder="Enter  Amount"
                      required
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Date</label>
                    <input
                      type="date"
                      value={date}
                      onChange={(e) => setdate(e.target.value)}
                      className="form-control "
                      required
                    ></input>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closemodel"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                onClick={submitdata}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </Layout>
  );
};
export default Targetmonth;