import React ,{memo} from "react";  
import Timesheet from "../../components/timesheet/Timesheet";  
import SectionHeader from "../../components/global/SectionHeader"; 
import Layout from "../../components/global/layout";

const Attendence = () => {    
  return (  
    <Layout>
      <div>
       
          
          <div className="main-content">

            <div className="section">
            
              <div className="headings">
              <SectionHeader title="Attendance" /> 
              </div>
              <div>
                <Timesheet />
              </div>
            </div>
          </div>
        </div>
     
    </Layout>
  );
};

export default memo(Attendence);
