import { useEffect } from "react";
import Login from "./pages/auth/login/Login";
import Logout from "./pages/Logout";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ForgetPassword from "./pages/auth/forgot/Forget";
// import "antd/dist/antd.css";
// import "../node_modules/antd/dist/antd.css";
import 'antd/dist/reset.css';

import TabsProvider from "./components/provider/Provider";
import Attendence from "./pages/attendance/Attendence"; 
import Resignation from "./pages/resignation/Resignation";
import Resignationform from "./pages/resignation/Resignationform";
import Knowledgesharingtable from "./pages/knowledgeShare/Knowledgesharingtable";
import Knowledgesharingform from "./pages/knowledgeShare/Knowledgesharingform";
import ResetPassword from "./pages/auth/resetpassword/ResetPassword";
import Leavelist from "./pages/leaves/Leaveform";
import Projectassigntable from "./pages/projects/projectassign";
import Appraisal from "./pages/appraisal/Appraisal";
import Tabledataxls from "./pages/tabledataxl/Tabledataxls";
import ProjectDetail from "./pages/projects/ProjectDetail";
import KnowledgeDetails from "./pages/knowledgeShare/knowledgeDetails";
import LeaveDetails from "./pages/leaves/LeaveDetails";
import ResignDetails from "./pages/resignation/ResignDetails";
import Updatetask from "./pages/task/Updatetask";
import Taskstatus from "./pages/task/Taskstatus";
import EditProfile from "./pages/auth/editprofile/EditProfile";
import Downloadtask from "./pages/task/Downloadtask";
import ProjectChart from "./pages/project/ProjectChart";
import Projectactivitylogs from "./pages/project/Projectactivitylogs"
import AllProjects from "./pages/project/AllProjects";
import Projecttask from "./pages/project/Projecttask";
import ProjectAssociation from "./pages/project/ProjectAssociation";
import ProjectDetails from "./pages/project/ProjectDetails";
import ProjectTeam from "./pages/teams/ProjectTeam";
import ComplaintList from "./pages/systemComplaint/ComplaintList";
import AddEditComplaints from "./pages/systemComplaint/AddEditComplaints";
import ViewTask from "./pages/task/ViewTask";
import ReviewProjectList from "./pages/projectreview/ReviewProjectList";
import Reviewstatus from "./pages/projectreview/Reviewstatus";
import Addeditreview from "./pages/projectreview/Addeditreview";
import Holidaylist from "./pages/holydays/Holiday";
import Announcement from "./pages/announcements/Announcements";
import AnnouncementsDetail from "./pages/announcements/AnnouncementsDetails";
import HolidaysDetail from "./pages/holydays/HolidaysDetails";
import Targetmonth from "./pages/targetmonth/Targetmonth";
import ChangePassword from "./pages/auth/changepassword/ChangePassword";
import Myprofile from "./pages/auth/profile/Profile";
import Dashboardpage from "./pages/dashboard/HrDashboard";
import EmployeeDashboard from "./pages/dashboard/EmployeeDashboard";
import ProjectFile from "./pages/projects/ProjectFile";
import LeavesData from './pages/leaves/LeavesData';

const App = () => {
  useEffect(() => {
    setInterval(() => {
      const newdate = new Date().getHours();
      const newminute = new Date().getMinutes();
      const newtext = `Please update your one hour status Report`;
      const hinditext = `कृपया करके आपकी एक घंटा अवस्था प्रतिवेदन जमा करे`;
      Notification.requestPermission();
      const newvalue = new SpeechSynthesisUtterance(newtext);
      const newhinditext = new SpeechSynthesisUtterance(hinditext);
      if (
        (newdate == 11 ||
          newdate == 12 ||
          newdate == 13 ||
          newdate == 15 ||
          newdate == 16 ||
          newdate == 17 ||
          newdate == 18 ||
          newdate == 19 ||
          newdate == 20 ||
          newdate == 21 ||
          newdate == 22) &&
        newminute == "00"
      ) {
        new Notification("Please Update your One hour Status Report");
        newvalue.lang = "en-US";
        window.speechSynthesis.speak(newvalue);
      } else {
      }
    }, 60000);
  }, []);

  return (
    <div>
      <Router>
        <TabsProvider>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/allProject" element={<AllProjects />} />
            <Route exact path="/monthtarget" element={<Targetmonth />} />
            <Route exact path="/holidaylist" element={<Holidaylist />} />
            <Route exact path="/announcementlist" element={<Announcement />} />
            <Route exact path="/projectDetails" element={<ProjectDetails />} />
            <Route exact path={`/createProject`} element={<ProjectChart />} />
            <Route
              exact
              path={`/createProject/:id`}
              element={<ProjectChart />}
            />
            <Route
              exact
              path={`/activityLog`}
              element={<Projectactivitylogs />}
            />
            <Route
              exact
              path="/projectassociation/list/:id"
              element={<ProjectAssociation/>}
            />
            <Route
              exact
              path="/reviewprojectlist"
              element={<ReviewProjectList/>}
            />
            <Route exact path="/reviewstatus" element={<Reviewstatus />} />
            <Route exact path="/addreview" element={<Addeditreview />} />
            <Route exact path="/projecttask" element={<Projecttask />} />
            <Route exact path="/projecttask/:id" element={<Projecttask />} />
            <Route exact path="/dashboard" element={<EmployeeDashboard />} />
            <Route exact path="/hrdashboard" element={<Dashboardpage />} />
            <Route exact path="/changepassword" element={<ChangePassword />} />
            <Route exact path="/attendance" element={<Attendence />} />
            <Route
              exact
              path="/projectassigntable"
              element={<Projectassigntable/>}
            />
            <Route
              exact
              path="/knowledgesharinglist"
              element={<Knowledgesharingtable/>}
            />
            <Route
              exact
              path="/knowledgesharingform"
              element={<Knowledgesharingform/>}
            />
  
            <Route exact path="/leavesform" element={<LeavesData/>} />
            <Route exact path="/leavelist" element={<Leavelist/>} />
            <Route exact path="/resignationform" element={<Resignation/>} />
            <Route exact path="/resignation" element={<Resignationform/>} />
            <Route exact path="/forgot" element={<ForgetPassword/>} />
            <Route exact path="/logout" element={<Logout/>} />
            <Route exact path="/resetpassword" element={<ResetPassword/>} />
            <Route exact path="/myprofile" element={<Myprofile/>} />
            <Route exact path="/appraisal" element={<Appraisal/>} />
            <Route exact path="/projectFile" element={<ProjectFile/>} />
            <Route exact path="/tabledataxls" element={<Tabledataxls/>} />
            <Route exact path="/projectDetail" element={<ProjectDetail/>} />
            <Route exact path="/knowledgeDetails" element={<KnowledgeDetails/>} />
            <Route exact path="/leaveDetails" element={<LeaveDetails/>} />
            <Route
              exact
              path="/announcementDetails"
              element={<AnnouncementsDetail/>}
            />
            <Route exact path="/holidaysDetail" element={<HolidaysDetail/>} />
            <Route exact path="/resignDetails" element={<ResignDetails/>} />
            <Route exact path="/updatedtask" element={<Updatetask/>} />
            <Route exact path="/taskstatus" element={<Taskstatus/>} />
            <Route exact path="/taskstatus/:id" element={<Taskstatus/>} />
            {/* <Route exact path="/projecturl" element={<ProjectURL/>} /> */}
            <Route exact path="/editprofile" element={<EditProfile/>} />
            <Route exact path="/downloadtask/:id" element={<Downloadtask/>} />
            <Route exact path="/project/team/:id?" element={<ProjectTeam/>} />
            <Route exact path={"/complaints"} element={<ComplaintList/>} />
            <Route
              exact
              path={"/addedit/complaints/:id?"}
              element={<AddEditComplaints/>}
            />
            <Route exact path={`/view/task/:id?`} element={<ViewTask/>} />
          </Routes>
        </TabsProvider>
      </Router>
    </div>
  );
};
export default App;
