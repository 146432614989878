import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification } from "antd"; 
import { updateProject } from "../../api/user/userapi";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};

function EditProject(props) {
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [Text, setText] = useState([]); 
  const [courseValue, setCourseValue] = useState({
    backendtechnology: "",
    frontendtechnology: "",
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    clientName: "",
    totaltime: "",
  });
  const [form] = Form.useForm();

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        backendtechnology: editChapterData.backendtechnology,
        frontendtechnology: editChapterData.frontendtechnology,
        name: editChapterData.name,
        description: editChapterData.description,
        startDate: editChapterData.startDate,
        endDate: editChapterData.endDate,
        clientName: editChapterData.clientName,
        totaltime: editChapterData.totaltime,
      });
    }
  }, [editChapterData]);

  const editChapterHandle = async () => {
    setIsEdit(true);
    const payload = {
      clientName: courseValue.clientName,
      totaltime: courseValue.totaltime,
      backendtechnology: courseValue.backendtechnology,
      frontendtechnology: courseValue.frontendtechnology,
      name: courseValue.name,
      description: courseValue.description,
      startDate: courseValue.startDate,
      endDate: courseValue.endDate,
    };
    try {
      const resp = await updateProject(chapterId, payload);
      if (resp && resp.status === 200) {
        showData();
        setTimeout(() => {
          notification.success({
            message: "Project Updated Successfully.",
          });
        }, 1000);
        setIsEditModalVisible(false);
      }
    } catch (e) {}
  };

  const closemodal = () => {
    props.onCancel();
  };

  return (
    <>
      <div className="">
        <Form onFinish={editChapterHandle} {...formItemLayout} form={form}>
          <Form.Item label="Name">
            <div class="forminput">
              <Input
                placeholder="Enter Name"
                name="name"
                value={courseValue.name}
                onChange={inputHandle}
                required
                className=""
              />
            </div>
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Enter Description"
              name="description" 
              value={courseValue.description}
              onChange={inputHandle}
              required
            />
            <CKEditor
              data={courseValue.description}
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
                setText(data);
              }}
            />
          </Form.Item>

          <Form.Item label="Backend Technology">
            <Input
              placeholder="Enter backendtechnology"
              name="backendtechnology"
              value={courseValue.backendtechnology}
              onChange={inputHandle}
              required
            />
          </Form.Item>

          <Form.Item label="Frontend Technology">
            <Input
              placeholder="Enter frontendtechnology"
              name="frontendtechnology"
              value={courseValue.frontendtechnology}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item label="Client Name">
            <Input
              placeholder="Enter clientName"
              name="clientName"
              value={courseValue.clientName}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item label="Start-Date">
            <Input
              type="date"
              placeholder="Enter Start-Date"
              name="startDate"
              value={courseValue.startDate}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item label="End-Date">
            <Input
              type="date"
              placeholder="Enter EndDate"
              name="endDate"
              value={courseValue.endDate}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item label="Total Time">
            <Input
              placeholder="Enter totaltime"
              name="totaltime"
              value={courseValue.totaltime}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 8,
                offset: 10,
              },
            }}
          >
            <div className="forgote d-flex justify-content-between">
              <button
                className="bg-danger"
                type="button"
                onClick={(e) => closemodal()}
              >
                Close
              </button>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Update Project
              </Button>
            </div>
          </Form.Item>
        </Form>

        {/* <div className="text-center">
          <button type="button" className="bg-danger" onClick={e=>closemodal()}>Close</button>
        </div> */}
      </div>
    </>
  );
}

export default EditProject;
