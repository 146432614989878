import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API_URL } from "../environment"; 
import { notification } from "antd";
import { Form } from "antd";
import { useNavigate } from "react-router";
const Popup = ({
  setLoading,
  setToggle,
  toggle,
  checkinTime,
  checkintable, 
  userDetails,
  getLocalStorage, 
}) => { 

  const history = useNavigate();
  const alldata =
    checkinTime &&
    checkinTime.map((item, i) => {
      return item.id;
    });
  const attId = localStorage.getItem("attId");
  const [btntogglein, setBtntogglein] = useState(true);
  const [btntoggleout, setBtntoggleout] = useState(true);  
  const token = localStorage.getItem("token");
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  // Checking the name of the device according to the width of the screen
  const check = () => {
    if ($(window).width() < 1024) {
      return "mobile";
    } else {
      return "desktop";
    }
  };
  const ok = (e, data) => {
    let dt = new Date();
    setLoading(true);
    const finaldate = convert(dt);
    setToggle(false);
    setBtntogglein(true);
    setBtntoggleout(false);
    const devicename = check();
    const data1 = JSON.stringify({ device: devicename });

    fetch(
      `${API_URL}attendance/checkout?attendanceId=${attId}&reason=${e.reason}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token && token}`,
        },
        body: data1,
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          localStorage.setItem("PunchIn", false);
          checkintable();
          getLocalStorage();
          userDetails();
          setLoading(false);
          localStorage.clear();
          history("/");
          notification.success({ message: "PunchOut Successfully" });
          notification.success({ message: "LogOut Successfully" });
        } else {
          localStorage.clear();
          history("/");
          notification.error({ message: res.message });
        }
      })
      .catch((err) => {
        notification.error({ message: "Some error Occurred" });
        setLoading(false);
      });

    // fetch(`${API_URL}attendance/single/user?attendancedate=${finaldate}&userID=${userid}&count=10000`, {
    //   method: 'GET',
    //   body: JSON.stringify(data),
    //   headers: {
    //     'Content-type': 'application/json',
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    //   .then(res => res.json())
    //   .then(res => {
    //    localStorage.removeItem("PunchIn")
    //    getLocalStorage();
    //       notification.success({
    //         message:'PunchOut Successfully'
    //       })
    //       dispatch({
    //         type: SINGLEUSER_ATTENDENCE,
    //         payload: res
    //       })
    //   }).catch(err=>notification.error({message:"Kindly wait for Sometime and try again"}))
  };

  return (
    <>
      <Modal isOpen={toggle}>
        <ModalHeader>Reason for PunchOut</ModalHeader>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={ok}
          autoComplete="off"
        >
          <svg
            viewBox="64 64 896 896"
            onClick={() => setToggle(false)}
            className="crossicon"
            focusable="false"
            data-icon="close"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          </svg>

          <Form.Item
            // label="Reason"
            name="reason"
            rules={[{ required: true, message: "Please fill the input!" }]}
          >
            {/* <Input className="reason" /> */}
            {/* <TextArea rows={4} id="message-text"
           placeholder="Enter Message"
           required
          /> */}
            <ModalBody>
              <textarea
                className="form-control"
                id="message-text"
                placeholder="Enter Message"
                required
                style={{
                  width: "448px",
                }}
              />
            </ModalBody>
          </Form.Item>
          <ModalFooter>
            <Button color="secondary" onClick={() => setToggle(false)}>
              Cancel
            </Button>
            <Button color="primary" htmlType="submit">
              OK
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Popup;
