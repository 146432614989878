import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd"; 
import SectionHeader from "../../components/global/SectionHeader";
import { getannouncement } from "../../api/api";
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";
const AnnouncementsDetail = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [loading, setloading] = useState(false); 
  const [desc, setDescription] = useState(); 
  const [clientNames, setClientName] = useState();  
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  useEffect(() => {
    getProject(id);
  }, [id]);
  const getProject = async () => {
    setloading(true);
    try {
      const resp = await getannouncement(id);
      if (resp && resp.status === 200) { 
        const list = resp; 
        const description = list.data.data.description;  
        const clientName = list.data.data.subject;  
        setDescription(description) 
        setClientName(clientName);  
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  const navigate = useNavigate();
  const renderSubmitBack = () => {
    navigate("/announcementlist");
  };
  return (
    <Layout>
    <div className="text-left">


          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Announcements Details" />
            </div>
          </div>


      
          <Spin spinning={loading}>
            <div className="">
              <div className="row">
                <div class="col-12 col-md-4">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">Subject:</label>
                    </div>
                    <div className="label_text">
                      <span> {clientNames}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-8">
                  <div className="project_deatil">
                    <div className="label_heading">
                      <label className="text-bold">Reason:</label>
                    </div>
                    <div className="label_text">
                      <span class="prettify pl-1" style={{ marginTop: "-3px" }}>
                        {" "}
                        {htmlToReactParser.parse(desc)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
    </div>
    </Layout>
  );
};

export default AnnouncementsDetail;
