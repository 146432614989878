import { React, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { notification } from "antd";
import { API_URL } from "../../environment";

const Header = () => {
  const tick = useRef();
  let history = useNavigate();
  const [username, setusername] = useState("");
  const [profileimage, setprofileimage] = useState(null);
  const Timetracker = localStorage.getItem("Trackertime");
  const [timers, setTimers] = useState(Number(Timetracker) || 0);
  const [starts, setStarts] = useState(true);
  const token = localStorage.getItem("token");

  const [isChecked, setIsChecked] = useState(() => {
    const storedValue = localStorage.getItem("darkMode");
    return storedValue === "true";
  });
  useEffect(() => {
    if (!token) {
      history("/");
    }
  });
  useEffect(() => {
    getuserdetails(token);
  }, []);
  const getuserdetails = (token) => {
    const userid = localStorage.getItem("USERID");
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) =>
        res.json().then((res) => {
          if (res.code == 500) {
            localStorage.clear();
            history("/");
            notification.error({ message: "Please Login again" });
          } else {
            setprofileimage(res?.data?.image);
            setusername(res.data?.fullName);
          }
        })
      )
      .catch((err) => localStorage.clear());
  };
  useEffect(() => {
    if (localStorage.getItem("darkMode") === "true") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, []);
  useEffect(() => {
    if (starts) {
      tick.current = setInterval(() => {
        setTimers((timers) => timers + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }
    return () => clearInterval(tick.current);
  }, [starts]);

  const toggleStart = () => {
    setStarts(false);
  };
  const togglestop = () => {
    setStarts(true);
  };

  const Stand = (e) => {
    setimageerror(true);
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
    localStorage.setItem("darkMode", !isChecked);
    if (!isChecked) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }; 
  return (
    <nav className="d-flex align-items-center gap-4">
      <div>
        <div className="startDiv">
          <button id="trackerstart" className="d-none" onClick={togglestop}>
            START
          </button>
          <button id="trackerstop" className="d-none" onClick={toggleStart}>
            STOP
          </button>
        </div>
      </div>

      <div className="">
        <label className="switch">
          <span className="sun">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="#ffd43b">
                <circle r="5" cy="12" cx="12"></circle>
                <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
              </g>
            </svg>
          </span>
          <span className="moon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
            </svg>
          </span>
          <input
            type="checkbox"
            className="input"
            checked={isChecked}
            onChange={handleToggle}
          />
          <span className="slider"></span>
        </label>
      </div>

      <div className="radius_div">
        <div className="d-flex gap-3">
          <div className="users_list text-end">
            <h4 class="">{username}</h4>
            <p class="breads mt-1">Online</p>
          </div>
          <div
            onClick={() => {
              history("/myprofile");
            }}
            style={{cursor:'pointer'}}
          >
            {profileimage && profileimage ? (
              <img width={33} height={33} src={`${profileimage}`} />
            ) : (
              <img
                width={33}
                height={33}
                src="../assets/img/avatar/avatar-4.png"
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
