import React, { useState, useEffect, forwardRef } from "react";
import { Spin, Button, Popconfirm, Tooltip, Tag } from "antd";
import { useDispatch } from "react-redux"; 
import SectionHeader from "../../components/global/SectionHeader"; 
import { PlusSquareOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { API_URL } from "../../environment";
import { RESIGNATION_CANCEL, RESIGNATION_DATA } from "../../redux/Store/constants";
import { notification } from "antd";
import "../leaves/Leaveform.css";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSidebar } from "../../components/provider/Provider";
import { Navigate, useNavigate } from "react-router";
import Layout from "../../components/global/layout";

const Resignationform = (props) => {
  const Email = localStorage.getItem("Email");
  const token = localStorage.getItem("token"); 
  const [loading, setLoading] = useState(false);
  const [enable, setDisable] = useState(false);
  const [array, setArray] = useState([]);
  const { setCourseId } = useSidebar(); 
  const dispatch = useDispatch();
  const iconbtn = () => {
    navigate("/resignationform");
  };
  useEffect(() => {
    ResignationData();
  }, []);
  const ResignationData = () => {
    setLoading(true);
    fetch(`${API_URL}allresign?search=${Email}&count=1000`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setArray(res.data);
        // localStorage.setItem('Email', res.data[0].fromEmail);
        // setResignationemail(res&&res.data&&res.data[0].fromEmail&&res.data[0].fromEmail)
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: RESIGNATION_DATA,
            payload: res,
          });
          //history("/dashboard")
        }
        if (res.code === 401) {
          localStorage.clear();
          setTimeout(() => {
            notification.error({
              message: "Unauthorized",
              description: "Your Session Has Expired. Please logIn.",
            });
          }, 1000);
        }
        setLoading(false);
      });
  };
  const key = "updatable";
  const chapterIdHandle = (id) => {
    setCourseId(id);
    localStorage.setItem("resignationemp", id);
  };

  const columns = [ 
    {
      title: "Subject",
      dataIndex: "subject",
      field: "subject",
      render: (text, record) => (
        <>
          <Link to={`/resignDetails?id=${text._id}`} className="text">
            <Tooltip title="View">
              <span onClick={() => chapterIdHandle(text.id)}>
                {text.subject}
              </span>
            </Tooltip>
          </Link>
        </>
      ),
    },
    {
      title: "To-Email",
      dataIndex: "toEmail",
      field: "toEmail",
    }, 
    {
      title: "Status",
      dataIndex: "resign_status",
      field: "resign_status",
    },
    {
      title: `Action`,
      dataIndex: "id",
      field: "id",
      render: (text, record) => (
        <>
          {text.resign_status === "Pending" ? (
            <Popconfirm
              title="Are you sure want to Cancel this Resignation"
              onConfirm={() =>
                cancelResign(text.id, text.fromEmail, text.toEmail)
              }
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tag className="pointer" color="red">Cancel</Tag>
           
            </Popconfirm>
          ) : (
            <Tag className="pointer" color="red">Cancel</Tag>
          )}
        </>
      ),
    },
  ];
  const navigate = useNavigate();
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  function cancel(e) {
    // message.error('Data is not deleted');
  }

  const cancelResign = (id, fromEmail, toEmail) => {
    setDisable(true);
    const payload = {
      id: id,
      toemail: toEmail,
      fromemail: fromEmail,
    };
    // dispatch(resignationcancelapi(payload))
    fetch(`${API_URL}resign/status/cancel`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        notification.success({
          message: "Resignation has been Cancelled",
        });
        //  window.location.reload()
        ResignationData();

        if (res) {
          dispatch({
            type: RESIGNATION_CANCEL,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
      <div>
   
        <div className="main-content">
          <div className="section-header">
       
          <div className="d-flex align-items-center justify-content-between headings" >
            <SectionHeader title="Resignation Form" />
              <Tooltip title="Add Resignation">
                <p onClick={iconbtn} className="pointer text-white  fs-3" >
                <PlusSquareOutlined />
              </p>
              </Tooltip>
            </div>
            <Spin spinning={loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }} 
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              /> 
            </Spin>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Resignationform;
