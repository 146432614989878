import React, { useEffect, useState, useRef } from "react";
import { Button, Select, Spin } from "antd";
import moment from "moment";
import { Form, RangePicker } from "antd";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
// import "antd/dist/antd.css";
import { API_URL } from "../../environment";
import {
  ALLLEAVES_DATA,
  LEAVE_APPLY,
  USERMAILING_LIST,
  USER_DETAIL,
} from "../../redux/Store/constants";
import { useDispatch } from "react-redux";
import { TimePicker } from "antd";
import { useNavigate } from "react-router-dom";
import SectionHeader from "../../components/global/SectionHeader";
import "antd/dist/reset.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../leaves/Leaveform.css";
import { notification } from "antd";
import { Tooltip } from "@material-ui/core";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const Leavelist = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const { Option } = Select;
  const [Text, setText] = useState([]);
  const Email = localStorage.getItem("Email");
  let history = useNavigate();
  const userid = localStorage.getItem("USERID");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const token = localStorage.getItem("token");
  const [mailinglist, setMailinglist] = useState([]);
  const [typeMail, setTypeMail] = useState();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [loading, setloading] = useState(false);
  const [dates, setDates] = useState([null, null]);

  useEffect(() => {
    userDetails();
    mailingList();
  }, []);

  const userDetails = () => {
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
        } else if (res.success === false) {
          alert("Enter valid Email or Password");
        }
      });
  };
  const mailingList = () => {
    fetch(`${API_URL}user/mailingList?isDeleted=false`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setMailinglist(res.data);
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USERMAILING_LIST,
            payload: res,
          });
          //history("/dashboard")
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  };

  const setTime = (e) => {
    setStartTime(e[0]._d);
    setEndTime(e[1]._d);
  };

  const uniqueValues = [];
  mailinglist?.map((entry) => {
    uniqueValues.push(entry);
  });
  const aldsfdn = () => {
    alert("fgfh");
  };
  function handleChange(value) {
    setTypeMail(value);
  }

  var event = new Date(startDate._d);
  let sdate = JSON.stringify(event);
  sdate = sdate.slice(1, 11);

  var event = new Date(endDate._d);
  let edate = JSON.stringify(event);
  edate = edate.slice(1, 11);
  

  const onFinish = (values) => {
    
 
    // const startnewdate = moment(values.leaveStartDate[0]).format("YYYY-MM-DD");

    // const endnewdate = moment(values.leaveStartDate[1]).format("YYYY-MM-DD");

    const newemail = values.toEmail;
    const payload = {
      subject: values.subject,
      description: values.description,
      leaveStartDate: dates[0],
      leaveEndDate: dates[1],
      toEmail: newemail.toString(),
      leaveStarttime: startTime,
      leaveEndtime: endTime,
    };
    setloading(true)
    fetch(`${API_URL}leaves/add`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        history("/leavelist");
        AllLeves();
        if (res) {
          if (res?.success == true) {
            setloading(false)
            notification.success({
              message: "Leave has been Applied",
            });
            dispatch({
              type: LEAVE_APPLY,
              payload: res,
            });
          }
       
        } else {

          notification.error({
            message: res?.message,
          });
        }
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const AllLeves = () => {
    setloading(true);
    fetch(`${API_URL}allleaves?search=${Email}&count=1000&isDeleted=false`, {
      method: "GET",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data && res.data.access_token) {
          setloading(false);
          dispatch({
            type: ALLLEAVES_DATA,
            payload: res,
          });
        } else {
        }
        
      });
  };
  const iconbtn = () => {
    history("/leavelist");
  };
  const onChange = (dates, dateStrings) => {
    setDates(dateStrings);  
  };

  return (
    <Layout>
      <div>
        <div className="main-content">
          <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p onClick={iconbtn} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Welcome to Leave Form" />
            </div>
          </div>

          <div className="profile_section_name">
            <div className="section">
              <div>
              <Spin spinning={loading}>
                <div className="container_div">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <div class="row">
                      <div class="form-group col-md-6 pointer">
                        <label for="inputEmail4">
                          To<span class="text-danger">*</span>
                        </label>
                        <Form.Item
                          name="toEmail"
                          rules={[
                            { required: true, message: "Please Select Email!" },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select one Email"
                            size="large"
                            onChange={handleChange}
                            optionLabelProp="label"
                          >
                            {mailinglist &&
                              mailinglist.map((item) => (
                                <Option key={item.email}>{item.email}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <svg
                          className="alignIcon"
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="down"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="false"
                        >
                          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                        </svg>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputAddress">
                          Type<span class="text-danger">*</span>
                        </label>
                        <Form.Item
                          name="subject"
                          rules={[
                            { required: true, message: "Please Select Type!" },
                          ]}
                        >
                          <Select
                            placeholder="Select the Type"
                            size="large"
                            onChange={handleChange}
                          >
                            <option value="Emergency Leave">
                              Emergency Leave
                            </option>
                            <option value="Medical Leave">Medical Leave</option>
                            <option value="full day Leave">
                              Full day Leave
                            </option>
                            <option value="half day Leave">
                              Half day Leave
                            </option>
                            <option value="short Leave" onClick={aldsfdn}>
                              Short Leave
                            </option>
                          </Select>
                        </Form.Item>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="inputAddress">
                            Start Date - End Date
                            <span class="text-danger">*</span>
                          </label>
                          <Form.Item rules={[
                              {
                                required: true,
                                message: "Please Select Start & End Date!",
                              },
                            ]}  name="dateRange">
                          {/* <Form.Item
                            name="leaveStartDate"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Start & End Date!",
                              },
                            ]}
                          > */}
                            {/* <DatePicker.RangePicker
                              value={[startDatee, endDatee]}
                              onChange={(dates) => {
                                setDateRangee(dates);
                              }}
                              disabledDate={(current) => current < new Date()}
                            /> */}

                            <RangePicker
                              format="YYYY-MM-DD"
                              onChange={onChange}
                              // minDate={new Date()}
                              disabledDate={(current) =>
                                current && current < moment().startOf("day")
                              }
                            />
                          </Form.Item>
                        </div>
                        {typeMail === "half day Leave" ||
                        typeMail === "short Leave" ? (
                          <div class="form-group col-md-6">
                            <label for="inputAddress">
                              Start Time - End Time
                              <span class="text-danger">*</span>
                            </label>

                            <Form.Item
                              name="leavestartandendtime"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please Select Start Time & End Time!",
                                },
                              ]}
                            >
                              <TimePicker.RangePicker
                                size="large"
                                onChange={(time) => {
                                  setStartTime(time[0].format("HH:mm:ss"));
                                  setEndTime(time[1].format("HH:mm:ss"));
                                }}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div class="form-group col-md-12">
                        <label for="inputAddress">
                          Reason<span class="text-danger">*</span>
                        </label>
                        <Form.Item
                          name="description"
                          valuePropName="data"
                          getValueFromEvent={(event, editor) => {
                            const data = editor.getData();
                            return data;
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter the Reason",
                            },
                          ]}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setText(data);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="btn_submit">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Leavelist;
