import { API_URL } from "../../environment";
import {
  ALLLEAVES_DATA,
  DAILY_TASK,
  DELETE_PARTICULARPOST,
  EDIT_POSTPARTICULAR,
  EDIT_PROFILE,
  FORGET_PASSWORD,
  KNOWLEDGE_SHARINGPOST,
  LEAVE_APPLY,
  LOGIN_USER,
  LOGOUT_USER,
  PROFILE_IMAGE,
  PUNCH_INTIME,
  RESET_PASSWORD,
  RESIGNATION_CANCEL,
  RESIGNATION_FORM,
  USER_DETAIL,
} from "../Store/constants";

const token = localStorage.getItem("token");
const userid = localStorage.getItem("USERID");
const Email = localStorage.getItem("Email");

///Login API////
export const getLogin = (data, history) => (dispatch) => {
  try {
    fetch(`${API_URL}user/signin`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // history("/dashboard")
        localStorage.setItem("USERID", res.data.id);
        localStorage.setItem("Name", res.data.fullName);
        localStorage.setItem("Email", res.data.email);

        if (res.data.access_token) {
          history("/dashboard");
          localStorage.setItem("token", res.data.access_token);
          dispatch({
            type: LOGIN_USER,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: LOGIN_USER,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///Forget API////
export const getForget = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}forgotpassword`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: FORGET_PASSWORD,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: FORGET_PASSWORD,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///Reset Pawword API ///
export const getResetPassword = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}resetPassword`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: RESET_PASSWORD,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///Log Out API //
export const logoutuser = () => (dispatch) => {
  const local = localStorage.getItem("token");
  if (local) {
    localStorage.removeItem("token");
    dispatch({
      type: LOGOUT_USER,
      payload: "Logout Successfully",
    });
    window.location.href = "/";
  } else {
    dispatch({
      type: LOGOUT_USER,
      payload: "Already Logout",
    });
  }
};

///User Detail///
export const userDetail = (data, history) => (dispatch) => {
  try {
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data && res.data.access_token) {
          dispatch({
            type: USER_DETAIL,
            payload: res,
          });
          //history("/dashboard")
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: USER_DETAIL,
      payload: {
        data: null,
        error,
      },
    });
    
  }
};

////Edit Profile/////

export const edtiProfile = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}editProfile?id=${userid}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        userDetail();
        if (res) {
          dispatch({
            type: EDIT_PROFILE,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: EDIT_PROFILE,
      payload: {
        data: null,
        error,
      },
    });
  }
};

//////Daily task Add////
export const Dailytask = (data, history) => (dispatch) => {
  try {
    fetch(`${API_URL}add/dailytask`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          // history("/dashboard")

          dispatch({
            type: DAILY_TASK,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: DAILY_TASK,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///////Image Upload Profile ////

export const profileimage = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}user/uploadProfileImage`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: PROFILE_IMAGE,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: PROFILE_IMAGE,
      payload: {
        data: null,
        error,
      },
    });
  }
};

//////Leave Aplly////
export const Leaveapply = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}leaves/add`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: LEAVE_APPLY,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: LEAVE_APPLY,
      payload: {
        data: null,
        error,
      },
    });
  }
};
/////Punch In /////
export const punchintime = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}attendance/checkin`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: PUNCH_INTIME,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: PUNCH_INTIME,
      payload: {
        data: null,
        error,
      },
    });
  }
};

//////All Leaves Data //////////
export const allleavesData = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}allleaves`, {
      method: "GET",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: ALLLEAVES_DATA,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: ALLLEAVES_DATA,
      payload: {
        data: null,
        error,
      },
    });
  }
};

//////////Resignation API //////////
export const resignationapi = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}resign/add`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: RESIGNATION_FORM,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: RESIGNATION_FORM,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///////Resignation Cancel API//////
export const resignationcancelapi = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}resign/status/cancel`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
        if (res) {
          dispatch({
            type: RESIGNATION_CANCEL,
            payload: res,
          });
        } else {
          // alert('Enter valid Email or Password');
        }
      });
  } catch (error) {
    dispatch({
      type: RESIGNATION_CANCEL,
      payload: {
        data: null,
        error,
      },
    });
  }
};

///////////Add Knowledge Sharing API ///////////
export const knowledgesharingapi = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}add/post`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: KNOWLEDGE_SHARINGPOST,
            payload: res,
          });
        } else {
          alert("Same name");
        }
      });
  } catch (error) {
    dispatch({
      type: KNOWLEDGE_SHARINGPOST,
      payload: {
        data: null,
        error,
      },
    });
  }
};

/////////DeletePostParticular/////////
export const deletePostParticular = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}post/delete`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
        if (res) {
          dispatch({
            type: DELETE_PARTICULARPOST,
            payload: res,
          });
        } else {
          alert("Same name");
        }
      });
  } catch (error) {
    dispatch({
      type: DELETE_PARTICULARPOST,
      payload: {
        data: null,
        error,
      },
    });
  }
};
///////Edit post Particular/////

export const editpostparticular = (data) => (dispatch) => {
  try {
    fetch(`${API_URL}post/update`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: EDIT_POSTPARTICULAR,
            payload: res,
          });
        } else {
          alert("Same name");
        }
      });
  } catch (error) {
    dispatch({
      type: EDIT_POSTPARTICULAR,
      payload: {
        data: null,
        error,
      },
    });
  }
};
