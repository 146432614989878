import ApiKey from "./ApiKey";

const isTranslatePage = () => {
  let value = false;
  let url = window.location.href;
  if (url.includes("translation")) value = true;
  return value;
};

const userImg = (img) => {
  let value = "/assets/img/person.jpg";
  if (img) value = ApiKey.api + "images/users/" + img;
  return value;
};

const noImg = (img) => {
  let value = "/assets/img/Image_not_available.png";
  if (img) value = ApiKey.api + "images/blogs/" + img;
  return value;
};

const getPrams = (p) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(p);
};

const isNumber = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  let max = Number(key.max ? key.max : key.value);
  if (Number(key.value) > max) key.value = max;

  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  key.value = key.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");

  return key.value;
};

const isRatio = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  let max = Number(key.max ? key.max : key.value);
  if (Number(key.value) > max) key.value = max;

  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  key.value = key.value.replace(/[^0-9.>]/g, "").replace(/(\..*?)\..*/g, "$1");

  return key.value;
};

const find = (arr, value, key = "key") => {
  let ext = arr.find((itm) => itm[key] == value);
  return ext;
};
const getError = (key, fvalue, formValidation) => {
  let ext = find(formValidation, key);
  let res = matchError(ext, fvalue);
  return res;
};

const emailRequiredFor = (role) => {
  let value = false;
  if (
    role == "Clinic Admin" ||
    role == "Counsellor" ||
    role == "Owner" ||
    role == "admin"
  )
    value = true;
  return value;
};

const validateUsername = (val) => {
  return /^(?=[a-zA-Z0-9._-]{8,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(val);
};

const dialMatch = (val) => {
  let value = false;
  value = val.match(/^(?=.*[0-9])(?=.*[+])[0-9+]{2,5}$/);
  return value;
};
const emailvalidation = (val) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
    return true;
  }
  return false;
};
const matchError = (ext, fValue) => {
  let kValue = fValue[ext.key];
  let value = { minLength: false, maxLength: false, confirmMatch: false };
  if (ext.minLength && kValue) {
    if (kValue.length < ext.minLength) value.minLength = true;
  }
  if (ext.maxLength && kValue) {
    if (kValue.length > ext.maxLength) value.maxLength = true;
  }
  if (ext.dialCode && kValue) {
    if (dialMatch(kValue)) {
      kValue.indexOf("+");
      if (kValue.indexOf("+") != 0) {
        value.dialCode = true;
      }
    } else {
      value.dialCode = true;
    }
  }

  if (ext.username && kValue) {
    if (!validateUsername(kValue)) value.username = true;
  }

  if (ext.confirmMatch && kValue) {
    if (fValue[ext.confirmMatch[0]] != fValue[ext.confirmMatch[1]])
      value.confirmMatch = true;
  }

  let invalid = false;
  let vArr = Object.keys(value);
  vArr.map((itm) => {
    if (value[itm]) invalid = true;
  });

  let res = { invalid: invalid, err: value };
  return res;
};

const urlParams = (url, key) => {
  let ext = url.split("?");
  let prm = "";
  if (ext.length == 2) prm = ext[1];
  // const paramsString = 'app=desktop&v=59jKcZoPIgA';
  let searchParams = new URLSearchParams(prm);
  return searchParams.get(key);
};
// get form error (All Fields)
const getFormError = (formValidation, fvalue) => {
  let invalid = false;
  formValidation.map((ext) => {
    if (matchError(ext, fvalue).invalid) {
      invalid = true;
    }
  });

  return invalid;
};

/* ###################### Form Methods end #########################  */

const videos = (vid) => {
  let value = "";

  if (vid) value = ApiKey.api + `images/videos/` + vid;
  return value;
};
const isgpa = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  let max = Number(key.max ? key.max : key.value);
  if (Number(key.value) > max) key.value = max;

  // let min = key.min;
  // if (min && Number(key.value)<Number(min)) key.value = min;
  //text-uppercase

  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  key.value = key.value.replace(/[^0-9./]/g, "");
  return key.value;
};

const getYoutubeId1 = (p) => {
  let value = "";
  let ext = p.split("https://youtu.be/");
  if (ext.length == 2) value = ext[1];
  if (!value) {
    ext = p.split("https://www.youtube.com/shorts/");
    if (ext.length == 2) value = ext[1];
  }
  if (!value) {
    value = urlParams(p, "v");
  }
  return value;
};
const methodModel = {
  urlParams,
  isgpa,
  getYoutubeId1,
  videos,
  userImg,
  isNumber,
  isRatio,
  find,
  getError,
  getFormError,
  getPrams,
  emailRequiredFor,
  emailvalidation,
  noImg,
  isTranslatePage,
};
export default methodModel;
