import { useNavigate, useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons"; 
import SectionHeader from "../../components/global/SectionHeader";
import { Button, notification, Spin, Tooltip } from "antd";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef, useEffect, useState } from "react";
import ApiClient from "../../api/apiClient";
import { API_URL } from "../../environment";
import moment from "moment";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const Downloadtask = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const history = useNavigate();

  const backbutton = () => {
    history(-1);
  };

  const download = (documents) => {
    window.open(`${API_URL}${documents}`);
    notification.success({
      message: "Download Successfully.",
    });
  };

  const getdata = () => {
    setloading(true);
    ApiClient.get(`project?id=${id}`).then((res) => {
      if (res.success) {
        setloading(false);
        setdata(res.data.weekly_plan);
      }
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  const columns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      field: "startDate",
      render: (data) => (
        <>{moment(data && data?.startDate).format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      field: "endDate",
      render: (data) => (
        <>{moment(data && data?.endDate).format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      field: "download",
      render: (data) => (
        <>
          <Tooltip title="Download">
            <i
              className="fa fa-download"
              style={{ fontSize: "24px", color: "blue" }}
              onClick={(e) => download(data?.documents)}
            ></i>
          </Tooltip>
        </>
      ),
    },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <Layout>
      <div>
       
        <div className="main-content">


        <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p   onClick={(e) => backbutton()} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Download Weekly Plan Tasks" />
            </div>
          </div>



          <section className="section">
         

            <Spin spinning={loading}>
              <MaterialTable
                options={{
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                }}
                columns={columns}
                data={data}
                icons={tableIcons}
              />
            </Spin>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default Downloadtask;
