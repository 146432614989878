import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { EDIT_POSTPARTICULAR } from "../../redux/Store/constants";
import { API_URL } from "../../environment";
import { notification } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FiEdit3 } from "react-icons/fi";
const token = localStorage.getItem("token");
const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};

function EditRoles(props) {
  const openref = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const openinput = () => {
    openref.current.click();
  };
  const { setIsEditModalVisible, editChapterData, showData } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [imgUrl, setImagesUrl] = useState("");
  const [images, setImages] = useState([]);
  const [img, setImg] = useState();
  const [courseValue, setCourseValue] = useState({
    name: editChapterData.name,
    description: editChapterData.description,
    image: editChapterData.image,
  });
  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  const handleInputChange = async (event) => {
    let file = event.target.files[0];

    if (file != null) {
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      let res = await fetch(
        `${API_URL}user/uploadProfileImage`,

        {
          method: "post",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let responseJson = await res.json();
      if (responseJson) {
        setImagesUrl(responseJson.data.imagePath);
        setImages(API_URL + responseJson.data.imagePath);
        setImg(API_URL + responseJson.data.imagePath);
        setCourseValue({
          ...courseValue,
          image: `${API_URL}${responseJson.data.imagePath}`,
        });
      }
    } else {
      alert("Please Select File first");
    }
  };
  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        name: editChapterData.name,
        description: editChapterData.description,
        image: editChapterData.image,
      });
    }
  }, [editChapterData]);

  const key = "updatable";

  const editChapterHandle = (chapterId) => {
    setIsEdit(true);
    const payload = {
      chapterId,
      name: courseValue.name,
      description: courseValue.description,
      image: courseValue.image,
    };

    fetch(`${API_URL}post/update?id=${editChapterData.id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token && token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          dispatch({
            type: EDIT_POSTPARTICULAR,
            payload: res,
          });
          notification.success({
            key,
            description: "Post has been Updated",
          });
        } else {
          notification.error({
            message: "Some error Occurred Try after Some Time.",
          });
        }
      });
    showData();
    setIsEditModalVisible(false);
  };
  return (
    <>
      <div>
        <Form {...formItemLayout} onFinish={editChapterHandle} form={form}>
          <div className="new_page">
            <div className="label_changes">
              <Form.Item label="Topic Name" className="modal_label">
                {" "}
              </Form.Item>
            </div>
            <div className="form_names">
              <Input
                placeholder="Enter name"
                name="name"
                max={200}
                value={courseValue.name}
                onChange={inputHandle}
                required
              />
            </div>
          </div>

          <div className="new_page">
            <div className="label_changes">
              <Form.Item
                label="Description"
                className="modal_label"
                name="description" 
              >
                {" "}
              </Form.Item>
            </div>
            <div className="form_names"> 
              <CKEditor
                data={courseValue.description}
                name="description"
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const newdata = editor.getData();
                  setCourseValue({ ...courseValue, description: newdata });
                }}
              />
            </div>
          </div>

          <div className="">
            <div className="new_page">
              <div className="label_changes d-flex justify-content-between mb-2">
                <label>Image</label>

                <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={openinput}
            >
           <FiEdit3 /> Edit 
            </Button>
               
             
                {/* <label className="imgtxt">Image :</label> */}
              </div>
              <div className=" position-relative mt-2">
                <img
                  src={courseValue.image}
                  alt="image"
                  className="imgknowledge"
                />
                <div className="form-row">
                  {/* <label className="text-white">Select File :</label> */}
                  <input
                    accept="image/png, image/gif, image/jpeg"
                    type="file"
                    className="uploadbtn"
                    name="upload_file"
                    onChange={handleInputChange}
                    ref={openref}
                  />
                 
                 
                </div>
              </div>
            </div>
          </div>

          {/* <div className="form">
            <div>
            <label className="imgtxt">Image :</label>
              </div>
        <div>
        <img src={courseValue.image} alt="image" className="imageEdit" />
            <div className="form-row">
                <label className="text-white">Select File :</label>
                <input accept="image/png, image/gif, image/jpeg" type="file" className="uploadbtn" name="upload_file"
                  onChange={handleInputChange}  ref={openref}/>
                <i onClick={openinput} className="fas fa-edit view editimg " />
            </div>
        </div>
           
          </div> */}

          <Form.Item
            wrapperCol={{
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 8,
                offset: 10,
              },
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default EditRoles;
